export default [
  {
    _id: "0",
    discogsID: 11873213,
    catno: "ML-9017",
    title: "Cerebral Hemispheres",
    artists: "Mr. Fingers",
    label: "Alleviated Records",
    year: 2018,
    cover:
      "https://i.discogs.com/jnGvGbsXzNej2MqEnvsO9_QK25pqdsjAxoKIG7LZSWU/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTExODcz/MjEzLTE1MjQxNTE2/ODctMTM1OC5qcGVn.jpeg",
    tracks: [
      {
        title: "Full Moon",
        artists: "Zachary McElwain",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "0",
        audioFeatures: {
          acousticness: 0.372,
          danceability: 0.796,
          duration_ms: 403366,
          energy: 0.55,
          instrumentalness: 0.759,
          key: 11,
          liveness: 0.0885,
          loudness: -13.184,
          mode: 0,
          speechiness: 0.0684,
          tempo: 109.985,
          time_signature: 4,
          valence: 0.643,
        },
        spotifyID: "2RHJvRwlDize7tOEiHqKpH",
      },
      {
        title: "City Streets",
        artists: "Christopher Charles Jones",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "1",
        audioFeatures: {
          acousticness: 0.013,
          danceability: 0.746,
          duration_ms: 390267,
          energy: 0.549,
          instrumentalness: 0.84,
          key: 0,
          liveness: 0.0873,
          loudness: -12.038,
          mode: 0,
          speechiness: 0.0366,
          tempo: 114.998,
          time_signature: 4,
          valence: 0.713,
        },
        spotifyID: "7dJ0pXd5hEtSggWfeonhk9",
      },
      {
        title: "Urbane Sunset",
        artists: "Ed Finney, Zachary McElwain",
        position: "A3",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "2",
        audioFeatures: {
          acousticness: 0.117,
          danceability: 0.588,
          duration_ms: 376457,
          energy: 0.601,
          instrumentalness: 0.832,
          key: 1,
          liveness: 0.14,
          loudness: -10.682,
          mode: 0,
          speechiness: 0.0978,
          tempo: 194.03,
          time_signature: 4,
          valence: 0.504,
        },
        spotifyID: "0xBttMn2FPAhIWgKP0bbx4",
      },
      {
        title: "Sands Of Aruba",
        artists: "Tiffany D. Curry, Zachary McElwain",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "3",
        audioFeatures: {
          acousticness: 0.00906,
          danceability: 0.699,
          duration_ms: 279224,
          energy: 0.578,
          instrumentalness: 0.929,
          key: 1,
          liveness: 0.117,
          loudness: -15.418,
          mode: 0,
          speechiness: 0.0333,
          tempo: 117.003,
          time_signature: 4,
          valence: 0.555,
        },
        spotifyID: "7DNpkhHwYqLpegThVQxwUB",
      },
      {
        title: "Tiger Lounge",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "4",
        audioFeatures: {
          acousticness: 0.0217,
          danceability: 0.85,
          duration_ms: 262338,
          energy: 0.467,
          instrumentalness: 0.931,
          key: 4,
          liveness: 0.134,
          loudness: -9.319,
          mode: 0,
          speechiness: 0.0442,
          tempo: 107.031,
          time_signature: 4,
          valence: 0.559,
        },
        spotifyID: "7xAiAqrAdE6wyfUvwgMF1n",
      },
      {
        title: "A Day In Portugal",
        artists: "Tiffany D. Curry",
        position: "B3",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "5",
        audioFeatures: {
          acousticness: 0.32,
          danceability: 0.704,
          duration_ms: 327407,
          energy: 0.625,
          instrumentalness: 0.798,
          key: 10,
          liveness: 0.103,
          loudness: -12.823,
          mode: 0,
          speechiness: 0.0303,
          tempo: 100.984,
          time_signature: 4,
          valence: 0.75,
        },
        spotifyID: "19JtCsFiKET01hzAmVzsir",
      },
      {
        title: "Sao Paulo",
        artists: "",
        position: "C1",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "6",
        audioFeatures: {
          acousticness: 0.137,
          danceability: 0.608,
          duration_ms: 295791,
          energy: 0.676,
          instrumentalness: 0.895,
          key: 1,
          liveness: 0.101,
          loudness: -12.645,
          mode: 1,
          speechiness: 0.0344,
          tempo: 114.002,
          time_signature: 4,
          valence: 0.569,
        },
        spotifyID: "2WPJSyelRVS7QJH2lbpCzj",
      },
      {
        title: "Crying Over You",
        artists: "Tiffany D. Curry",
        position: "C2",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "7",
        audioFeatures: {
          acousticness: 0.0462,
          danceability: 0.672,
          duration_ms: 340614,
          energy: 0.559,
          instrumentalness: 0.000466,
          key: 2,
          liveness: 0.102,
          loudness: -12.426,
          mode: 0,
          speechiness: 0.0349,
          tempo: 109.99,
          time_signature: 4,
          valence: 0.512,
        },
        spotifyID: "3j7gSFwuruPFhbnyCXZV6S",
      },
      {
        title: "Cerebral Hemispheres",
        artists: "",
        position: "C3",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "8",
        audioFeatures: {
          acousticness: 0.0155,
          danceability: 0.81,
          duration_ms: 340117,
          energy: 0.463,
          instrumentalness: 0.913,
          key: 0,
          liveness: 0.0907,
          loudness: -14.37,
          mode: 0,
          speechiness: 0.0936,
          tempo: 119.998,
          time_signature: 4,
          valence: 0.56,
        },
        spotifyID: "7m3YpisHhBG2j98LXFngHP",
      },
      {
        title: "Electron",
        artists: "",
        position: "D1",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "9",
        audioFeatures: {
          acousticness: 0.046,
          danceability: 0.594,
          duration_ms: 369139,
          energy: 0.904,
          instrumentalness: 0.768,
          key: 8,
          liveness: 0.112,
          loudness: -8.91,
          mode: 1,
          speechiness: 0.0368,
          tempo: 108,
          time_signature: 4,
          valence: 0.462,
        },
        spotifyID: "3fOva2jdaAddyxJ4SeBBy6",
      },
      {
        title: "Outer Acid",
        artists: "",
        position: "D2",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "10",
        audioFeatures: {
          acousticness: 0.388,
          danceability: 0.804,
          duration_ms: 347030,
          energy: 0.924,
          instrumentalness: 0.93,
          key: 0,
          liveness: 0.0675,
          loudness: -14.787,
          mode: 1,
          speechiness: 0.0536,
          tempo: 117.03,
          time_signature: 4,
          valence: 0.936,
        },
        spotifyID: "0Rn6vGr3VFphuNNVTemxul",
      },
      {
        title: "Inner Acid",
        artists: "",
        position: "D3",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "11",
        audioFeatures: {
          acousticness: 0.0697,
          danceability: 0.692,
          duration_ms: 329981,
          energy: 0.917,
          instrumentalness: 0.889,
          key: 6,
          liveness: 0.108,
          loudness: -11.526,
          mode: 0,
          speechiness: 0.0432,
          tempo: 120.003,
          time_signature: 4,
          valence: 0.141,
        },
        spotifyID: "2Be7mzsd6AGIw7jk0GWvdf",
      },
      {
        title: "Spy",
        artists: "",
        position: "E1",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "12",
        audioFeatures: {
          acousticness: 0.00319,
          danceability: 0.805,
          duration_ms: 291172,
          energy: 0.412,
          instrumentalness: 0.921,
          key: 7,
          liveness: 0.0909,
          loudness: -12.43,
          mode: 1,
          speechiness: 0.0531,
          tempo: 125.018,
          time_signature: 4,
          valence: 0.779,
        },
        spotifyID: "1qnyzJV7xgMf5YKf05XigJ",
      },
      {
        title: "Stratusfly",
        artists:
          "Rich Reigel, Paul St. Hilaire, Tikiman, Paul St. Hilaire, Larry Heard",
        position: "E2",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "13",
        audioFeatures: {
          acousticness: 0.00154,
          danceability: 0.651,
          duration_ms: 306805,
          energy: 0.478,
          instrumentalness: 0.78,
          key: 0,
          liveness: 0.16,
          loudness: -10.388,
          mode: 1,
          speechiness: 0.0284,
          tempo: 119.029,
          time_signature: 4,
          valence: 0.715,
        },
        spotifyID: "1ac7dZm0gvPMk5vizGCgO8",
      },
      {
        title: "Nodyahed",
        artists: "",
        position: "E3",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "14",
        audioFeatures: {
          acousticness: 0.0102,
          danceability: 0.798,
          duration_ms: 356225,
          energy: 0.811,
          instrumentalness: 0.908,
          key: 2,
          liveness: 0.0611,
          loudness: -11.215,
          mode: 1,
          speechiness: 0.0462,
          tempo: 119.014,
          time_signature: 4,
          valence: 0.593,
        },
        spotifyID: "5xMLflSFj5oFwFkyTnK6wj",
      },
      {
        title: "Qwazars",
        artists: "",
        position: "F1",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "15",
        audioFeatures: {
          acousticness: 0.338,
          danceability: 0.772,
          duration_ms: 340974,
          energy: 0.531,
          instrumentalness: 0.852,
          key: 11,
          liveness: 0.121,
          loudness: -15.692,
          mode: 0,
          speechiness: 0.0454,
          tempo: 120.015,
          time_signature: 4,
          valence: 0.103,
        },
        spotifyID: "0s6OlOVBZ9OB4FZaNyz9Ys",
      },
      {
        title: "Aether",
        artists: "",
        position: "F2",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "16",
        audioFeatures: {
          acousticness: 0.223,
          danceability: 0.735,
          duration_ms: 373761,
          energy: 0.518,
          instrumentalness: 0.858,
          key: 2,
          liveness: 0.111,
          loudness: -14.334,
          mode: 1,
          speechiness: 0.0702,
          tempo: 109.002,
          time_signature: 4,
          valence: 0.164,
        },
        spotifyID: "24wWZAHUvMPIG9h7zjq0yQ",
      },
      {
        title: "Praise To The Vibes",
        artists: "Lady Wray, Nicole Wray, Nicole Wray, Larry Heard",
        position: "F3",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Downtempo",
        playable: true,
        _id: "17",
        audioFeatures: {
          acousticness: 0.116,
          danceability: 0.811,
          duration_ms: 281542,
          energy: 0.492,
          instrumentalness: 0.0375,
          key: 8,
          liveness: 0.116,
          loudness: -11.374,
          mode: 0,
          speechiness: 0.0465,
          tempo: 115.996,
          time_signature: 4,
          valence: 0.779,
        },
        spotifyID: "0JPSabYldZenbUdXnmSgoP",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543633" } },
    spotifyID: "46sews77v3EoXe6PzYmYdD",
    user: "1",
  },
  {
    _id: "1",
    discogsID: 16133281,
    catno: "ANNV004",
    title: "Chain Reaction",
    artists: "Toki Fuko",
    label: "Annulled",
    year: 2020,
    cover:
      "https://i.discogs.com/dvH8BGX-GZnVXWuD9I-nqAfYJoi-yQr8aaz-jkkdrmA/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE2MTMz/MjgxLTE2NTk3Nzgx/MDctNDAxNC5qcGVn.jpeg",
    tracks: [
      {
        title: 'Chapter 7 "Desire"',
        artists: "Gangaji",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Minimal Techno",
        playable: true,
        _id: "100",
        audioFeatures: {
          acousticness: 0.00319,
          danceability: 0.6,
          duration_ms: 456649,
          energy: 0.76,
          instrumentalness: 0.752,
          key: 6,
          liveness: 0.109,
          loudness: -11.839,
          mode: 1,
          speechiness: 0.071,
          tempo: 144.021,
          time_signature: 4,
          valence: 0.0396,
        },
        spotifyID: "68YL6KDoIauQp7uPwc5p9C",
      },
      {
        title: "Vaal",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Minimal Techno",
        playable: true,
        _id: "101",
        audioFeatures: {
          acousticness: 0.0146,
          danceability: 0.748,
          duration_ms: 456048,
          energy: 0.591,
          instrumentalness: 0.846,
          key: 10,
          liveness: 0.284,
          loudness: -12.464,
          mode: 0,
          speechiness: 0.061,
          tempo: 127.996,
          time_signature: 4,
          valence: 0.165,
        },
        spotifyID: "40Rfoupf6lPyvjDtYiOIqY",
      },
      {
        title: "Polonium",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Minimal Techno",
        playable: true,
        _id: "102",
        audioFeatures: {
          acousticness: 0.26,
          danceability: 0.544,
          duration_ms: 429995,
          energy: 0.679,
          instrumentalness: 0.947,
          key: 10,
          liveness: 0.111,
          loudness: -14.425,
          mode: 0,
          speechiness: 0.0478,
          tempo: 127.006,
          time_signature: 4,
          valence: 0.0364,
        },
        spotifyID: "7IkKs5YvPgfvBWRIKoRlF5",
      },
      {
        title: "Scandium",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Minimal Techno",
        playable: true,
        _id: "103",
        audioFeatures: {
          acousticness: 0.00204,
          danceability: 0.852,
          duration_ms: 450374,
          energy: 0.663,
          instrumentalness: 0.856,
          key: 11,
          liveness: 0.107,
          loudness: -13.182,
          mode: 1,
          speechiness: 0.185,
          tempo: 112.005,
          time_signature: 4,
          valence: 0.525,
        },
        spotifyID: "05EOy1o8NwEGcmchjLAtX8",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543634" } },
    spotifyID: "5uOYEG95yNLbyXkBh2DInp",
    user: "1",
  },
  {
    _id: "2",
    discogsID: 32662,
    catno: "AMB LP 3922",
    title: "Selected Ambient Works 85-92",
    artists: "Aphex Twin",
    label: "Apollo",
    year: 1992,
    cover:
      "https://i.discogs.com/HlTTmex2gnWDB3C-jXmRoBXJeBSNgmsO4Vv3SpnomLk/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTMyNjYy/LTEyMjE4OTY5NTUu/anBlZw.jpeg",
    tracks: [
      {
        title: "Xtal",
        artists: "",
        position: "A1",
        duration: 291000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "200",
        audioFeatures: {
          acousticness: 0.342,
          danceability: 0.51,
          duration_ms: 293752,
          energy: 0.505,
          instrumentalness: 0.96,
          key: 9,
          liveness: 0.117,
          loudness: -13.053,
          mode: 0,
          speechiness: 0.0344,
          tempo: 114.532,
          time_signature: 4,
          valence: 0.318,
        },
        spotifyID: "7o2AeQZzfCERsRmOM86EcB",
      },
      {
        title: "Tha",
        artists: "",
        position: "A2",
        duration: 541000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "201",
        audioFeatures: {
          acousticness: 0.822,
          danceability: 0.599,
          duration_ms: 547133,
          energy: 0.406,
          instrumentalness: 0.933,
          key: 4,
          liveness: 0.112,
          loudness: -19.881,
          mode: 0,
          speechiness: 0.0368,
          tempo: 134.762,
          time_signature: 4,
          valence: 0.326,
        },
        spotifyID: "4Sxv0whUHWzHK5T8uuP66S",
      },
      {
        title: "Pulsewidth",
        artists: "",
        position: "A3",
        duration: 227000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "202",
        audioFeatures: {
          acousticness: 0.42,
          danceability: 0.765,
          duration_ms: 228053,
          energy: 0.486,
          instrumentalness: 0.876,
          key: 1,
          liveness: 0.332,
          loudness: -14.671,
          mode: 1,
          speechiness: 0.0724,
          tempo: 119.319,
          time_signature: 4,
          valence: 0.303,
        },
        spotifyID: "643gyipSU7dkmrFhJ8UAIm",
      },
      {
        title: "Ageispolis",
        artists: "",
        position: "B1",
        duration: 321000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "203",
        audioFeatures: {
          acousticness: 0.00903,
          danceability: 0.645,
          duration_ms: 323187,
          energy: 0.393,
          instrumentalness: 0.89,
          key: 6,
          liveness: 0.364,
          loudness: -17.543,
          mode: 0,
          speechiness: 0.043,
          tempo: 101.757,
          time_signature: 4,
          valence: 0.441,
        },
        spotifyID: "7KRQoq9GeWeCm0ZAXg5XMb",
      },
      {
        title: "i",
        artists: "",
        position: "B2",
        duration: 73000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "204",
        audioFeatures: {
          acousticness: 0.989,
          danceability: 0.183,
          duration_ms: 77347,
          energy: 0.0896,
          instrumentalness: 0.899,
          key: 2,
          liveness: 0.107,
          loudness: -27.54,
          mode: 1,
          speechiness: 0.0486,
          tempo: 76.923,
          time_signature: 3,
          valence: 0.0326,
        },
        spotifyID: "2FQviMkaoixlgWuGL4u1EO",
      },
      {
        title: "Green Calx",
        artists: "",
        position: "B3",
        duration: 362000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "205",
        audioFeatures: {
          acousticness: 0.0502,
          danceability: 0.594,
          duration_ms: 365160,
          energy: 0.865,
          instrumentalness: 0.417,
          key: 7,
          liveness: 0.367,
          loudness: -10.206,
          mode: 1,
          speechiness: 0.068,
          tempo: 117.34,
          time_signature: 4,
          valence: 0.344,
        },
        spotifyID: "3fFOi1PqxGjFKmWW21uQNa",
      },
      {
        title: "Heliosphan",
        artists: "",
        position: "B4",
        duration: 291000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "206",
        audioFeatures: {
          acousticness: 0.455,
          danceability: 0.345,
          duration_ms: 293800,
          energy: 0.612,
          instrumentalness: 0.956,
          key: 0,
          liveness: 0.0854,
          loudness: -14.163,
          mode: 0,
          speechiness: 0.0416,
          tempo: 130.743,
          time_signature: 4,
          valence: 0.126,
        },
        spotifyID: "09opLVMX7cfKVKlP3iKZR1",
      },
      {
        title: "We Are The Music Makers",
        artists: "",
        position: "C1",
        duration: 462000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "207",
        audioFeatures: {
          acousticness: 0.398,
          danceability: 0.723,
          duration_ms: 463520,
          energy: 0.574,
          instrumentalness: 0.942,
          key: 11,
          liveness: 0.135,
          loudness: -13.05,
          mode: 1,
          speechiness: 0.0644,
          tempo: 102.981,
          time_signature: 4,
          valence: 0.558,
        },
        spotifyID: "1sTzDqhcrpB2BC8TOMeInR",
      },
      {
        title: "Schottkey 7th Path",
        artists: "",
        position: "C2",
        duration: 307000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "208",
        audioFeatures: {
          acousticness: 0.805,
          danceability: 0.62,
          duration_ms: 308720,
          energy: 0.656,
          instrumentalness: 0.964,
          key: 11,
          liveness: 0.0775,
          loudness: -14.831,
          mode: 1,
          speechiness: 0.0348,
          tempo: 134.958,
          time_signature: 4,
          valence: 0.0704,
        },
        spotifyID: "6jTmcVh73XaKAklNSVX7jA",
      },
      {
        title: "Ptolemy",
        artists: "",
        position: "C3",
        duration: 432000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "209",
        audioFeatures: {
          acousticness: 0.000199,
          danceability: 0.765,
          duration_ms: 434307,
          energy: 0.726,
          instrumentalness: 0.935,
          key: 1,
          liveness: 0.108,
          loudness: -11.121,
          mode: 0,
          speechiness: 0.0459,
          tempo: 117.268,
          time_signature: 4,
          valence: 0.314,
        },
        spotifyID: "23HiOySFOnPUXTJWnc5tAb",
      },
      {
        title: "Hedphelym",
        artists: "",
        position: "D1",
        duration: 362000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "210",
        audioFeatures: {
          acousticness: 0.636,
          danceability: 0.461,
          duration_ms: 363920,
          energy: 0.999,
          instrumentalness: 0.961,
          key: 5,
          liveness: 0.0825,
          loudness: -12.8,
          mode: 0,
          speechiness: 0.24,
          tempo: 124.915,
          time_signature: 4,
          valence: 0.0188,
        },
        spotifyID: "0EK8vqmwSCqY5b7CemNc9I",
      },
      {
        title: "Delphium",
        artists: "",
        position: "D2",
        duration: 336000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "211",
        audioFeatures: {
          acousticness: 0.0204,
          danceability: 0.654,
          duration_ms: 329267,
          energy: 0.371,
          instrumentalness: 0.923,
          key: 10,
          liveness: 0.101,
          loudness: -15.098,
          mode: 0,
          speechiness: 0.0907,
          tempo: 118.61,
          time_signature: 4,
          valence: 0.71,
        },
        spotifyID: "54avYgPIdqFI59mW91E0Sf",
      },
      {
        title: "Actium",
        artists: "",
        position: "D3",
        duration: 455000,
        rpm: 33,
        genre: "Techno, IDM, Experimental, Ambient",
        playable: true,
        _id: "212",
        audioFeatures: {
          acousticness: 0.876,
          danceability: 0.716,
          duration_ms: 455000,
          energy: 0.31,
          instrumentalness: 0.935,
          key: 10,
          liveness: 0.131,
          loudness: -14.466,
          mode: 0,
          speechiness: 0.0469,
          tempo: 125.691,
          time_signature: 4,
          valence: 0.436,
        },
        spotifyID: "78w0l5CJ3VwtXKBpRCktwa",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543634" } },
    spotifyID: "7aNclGRxTysfh6z0d8671k",
    user: "1",
  },
  {
    _id: "3",
    discogsID: 4403452,
    catno: "AUS1345",
    title: "Nobody Else EP",
    artists: "Dusky",
    label: "Aus Music",
    year: 2013,
    cover:
      "https://i.discogs.com/ivrDaqcrqfoVaExiL3pIB5Aiqa42GggnKnwxgcYRqTI/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTQ0MDM0/NTItMTM5OTA0OTY5/Mi05NzU0LmpwZWc.jpeg",
    tracks: [
      {
        title: "Nobody Else",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Tech House",
        playable: true,
        _id: "300",
        audioFeatures: {
          acousticness: 0.00059,
          danceability: 0.805,
          duration_ms: 445417,
          energy: 0.79,
          instrumentalness: 0.888,
          key: 0,
          liveness: 0.0296,
          loudness: -7.93,
          mode: 1,
          speechiness: 0.0577,
          tempo: 122.99,
          time_signature: 4,
          valence: 0.528,
        },
        spotifyID: "2S4OOHWWPu93Ns5u48Mhs8",
      },
      {
        title: "What I Never Knew",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Tech House",
        playable: true,
        _id: "301",
        audioFeatures: {
          acousticness: 0.000216,
          danceability: 0.805,
          duration_ms: 392896,
          energy: 0.893,
          instrumentalness: 0.832,
          key: 1,
          liveness: 0.0539,
          loudness: -7.595,
          mode: 1,
          speechiness: 0.0473,
          tempo: 123.001,
          time_signature: 4,
          valence: 0.326,
        },
        spotifyID: "6bwda7xuWZNBtX9iOIYRyE",
      },
      {
        title: "Atone",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Tech House",
        playable: true,
        _id: "302",
        audioFeatures: {
          acousticness: 0.000994,
          danceability: 0.796,
          duration_ms: 395845,
          energy: 0.893,
          instrumentalness: 0.926,
          key: 11,
          liveness: 0.0828,
          loudness: -7.405,
          mode: 0,
          speechiness: 0.0593,
          tempo: 124.993,
          time_signature: 4,
          valence: 0.485,
        },
        spotifyID: "4i5naYj23ktPWlIWt61nLx",
      },
      {
        title: "Dummy",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Tech House",
        playable: true,
        _id: "303",
        audioFeatures: {
          acousticness: 0.00464,
          danceability: 0.805,
          duration_ms: 383247,
          energy: 0.879,
          instrumentalness: 0.905,
          key: 7,
          liveness: 0.0413,
          loudness: -7.375,
          mode: 1,
          speechiness: 0.0674,
          tempo: 123.02,
          time_signature: 4,
          valence: 0.655,
        },
        spotifyID: "5jW10MKG6fN0qoDBobZ4m6",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543635" } },
    user: "1",
  },
  {
    _id: "4",
    discogsID: 7117075,
    catno: "AUS1580",
    title: "Just EP",
    artists: "Bicep",
    label: "Aus Music",
    year: 2015,
    cover:
      "https://i.discogs.com/tFTffpnJEXKxO5NwyqSZegdTohzgxkZo6Z-Z3wqnwkQ/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTcxMTcw/NzUtMTQ0OTc5MTk1/OS05MzIwLmpwZWc.jpeg",
    tracks: [
      {
        title: "Just",
        artists: "Sophie Brown",
        position: "A1",
        duration: null,
        rpm: 45,
        genre: "House, Tech House",
        playable: true,
        _id: "400",
        audioFeatures: {
          acousticness: 0.000108,
          danceability: 0.793,
          duration_ms: 372555,
          energy: 0.853,
          instrumentalness: 0.664,
          key: 7,
          liveness: 0.032,
          loudness: -6.303,
          mode: 1,
          speechiness: 0.0599,
          tempo: 120.002,
          time_signature: 4,
          valence: 0.62,
        },
        spotifyID: "7MHjC4y8tMWDV1xA3CpqzX",
      },
      {
        title: "Celeste",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 45,
        genre: "House, Tech House",
        playable: true,
        _id: "401",
        audioFeatures: {
          acousticness: 0.184,
          danceability: 0.793,
          duration_ms: 363611,
          energy: 0.497,
          instrumentalness: 0.931,
          key: 9,
          liveness: 0.0815,
          loudness: -9.476,
          mode: 0,
          speechiness: 0.058,
          tempo: 125.003,
          time_signature: 4,
          valence: 0.34,
        },
        spotifyID: "53ziOQcwiQnwVLu9PQm88N",
      },
      {
        title: "Back 2 U (Tranz Dub)",
        artists: "Sophie Brown",
        position: "B1",
        duration: null,
        rpm: 45,
        genre: "House, Tech House",
        playable: true,
        _id: "402",
        audioFeatures: {
          acousticness: 0.0319,
          danceability: 0.722,
          duration_ms: 464277,
          energy: 0.843,
          instrumentalness: 0.929,
          key: 11,
          liveness: 0.0643,
          loudness: -6.832,
          mode: 1,
          speechiness: 0.0413,
          tempo: 122,
          time_signature: 4,
          valence: 0.512,
        },
        spotifyID: "6QGSgOqJFCN6MF6u08EYRO",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543635" } },
    spotifyID: "1naCJmNjiY0CBbCqJ22r25",
    user: "1",
  },
  {
    _id: "5",
    discogsID: 19048972,
    catno: "BBE389ELP",
    title: "Rude Movements Remixes",
    artists: "Sun Palace",
    label: "BBE",
    year: 2021,
    cover:
      "https://i.discogs.com/Op2GtRSv_PV4rsxx7P5w1utq7usXDUHW_Rf1mgPacH8/rs:fit/g:sm/q:90/h:572/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE5MDQ4/OTcyLTE2MjMwNjcw/OTYtMTI2MC5qcGVn.jpeg",
    tracks: [
      {
        title: "Rude Movements (Moodymann Remix Extended Version)",
        artists: "Moodymann",
        position: "A1",
        duration: 513000,
        rpm: 33,
        genre: "Funk, Jazz-Funk, Disco, House",
        playable: true,
        _id: "500",
        audioFeatures: {
          acousticness: 0.0209,
          danceability: 0.653,
          duration_ms: 512626,
          energy: 0.498,
          instrumentalness: 0.911,
          key: 1,
          liveness: 0.116,
          loudness: -13.282,
          mode: 1,
          speechiness: 0.0351,
          tempo: 107.926,
          time_signature: 4,
          valence: 0.438,
        },
        spotifyID: "42iFuhYvqoMEESS9C6AVT4",
      },
      {
        title: "Rude Movements (Frankie Feliciano Remix)",
        artists: "Frankie Feliciano",
        position: "A2",
        duration: 371000,
        rpm: 33,
        genre: "Funk, Jazz-Funk, Disco, House",
        playable: true,
        _id: "501",
        audioFeatures: {
          acousticness: 0.00252,
          danceability: 0.718,
          duration_ms: 371440,
          energy: 0.701,
          instrumentalness: 0.901,
          key: 5,
          liveness: 0.384,
          loudness: -10.246,
          mode: 0,
          speechiness: 0.0387,
          tempo: 122.016,
          time_signature: 4,
          valence: 0.562,
        },
        spotifyID: "0FLhBFiqFtTDAoc7BSHc1r",
      },
      {
        title: "Rude Movements (Kenny Dope Dancefloor Powder Remix)",
        artists: 'Kenny "Dope" Gonzalez',
        position: "B1",
        duration: 494000,
        rpm: 33,
        genre: "Funk, Jazz-Funk, Disco, House",
        playable: true,
        _id: "502",
        audioFeatures: {
          acousticness: 0.0529,
          danceability: 0.696,
          duration_ms: 240234,
          energy: 0.86,
          instrumentalness: 0.931,
          key: 8,
          liveness: 0.101,
          loudness: -10.21,
          mode: 1,
          speechiness: 0.0442,
          tempo: 120.014,
          time_signature: 4,
          valence: 0.74,
        },
        spotifyID: "0OLUiiLhVZImE9bVMJNWN3",
      },
      {
        title: "Rude Movements (Opolopo Remix Extended Version)",
        artists: "Opolopo",
        position: "C1",
        duration: 437000,
        rpm: 33,
        genre: "Funk, Jazz-Funk, Disco, House",
        playable: true,
        _id: "503",
        audioFeatures: {
          acousticness: 0.00063,
          danceability: 0.694,
          duration_ms: 437413,
          energy: 0.931,
          instrumentalness: 0.821,
          key: 1,
          liveness: 0.0425,
          loudness: -10.678,
          mode: 1,
          speechiness: 0.0441,
          tempo: 118.015,
          time_signature: 4,
          valence: 0.85,
        },
        spotifyID: "3TzDFsjxK8km4YtHBLjnU4",
      },
      {
        title: "Rude Movements (Kenny Dope O'Gutta Remix Extended Version)",
        artists: 'Kenny "Dope" Gonzalez',
        position: "C2",
        duration: 397000,
        rpm: 33,
        genre: "Funk, Jazz-Funk, Disco, House",
        playable: true,
        _id: "504",
        audioFeatures: {
          acousticness: 0.00000565,
          danceability: 0.838,
          duration_ms: 397307,
          energy: 0.693,
          instrumentalness: 0.931,
          key: 1,
          liveness: 0.133,
          loudness: -10.183,
          mode: 1,
          speechiness: 0.0696,
          tempo: 122.991,
          time_signature: 4,
          valence: 0.265,
        },
        spotifyID: "4MThklBkhIPso0vBBEdSZx",
      },
      {
        title: "Rude Movements (François K SATS Dub)",
        artists: "François Kevorkian",
        position: "D1",
        duration: 611000,
        rpm: 33,
        genre: "Funk, Jazz-Funk, Disco, House",
        playable: true,
        _id: "505",
        audioFeatures: {
          acousticness: 0.00288,
          danceability: 0.537,
          duration_ms: 239722,
          energy: 0.871,
          instrumentalness: 0.731,
          key: 10,
          liveness: 0.0906,
          loudness: -8.217,
          mode: 0,
          speechiness: 0.0358,
          tempo: 112.015,
          time_signature: 4,
          valence: 0.488,
        },
        spotifyID: "3fLp8NaYfFgfwS6nCiSYh5",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543635" } },
    user: "1",
  },
  {
    _id: "6",
    discogsID: 11753689,
    catno: "CMC288RMX",
    title: "Calling Out",
    artists: "Sophie Lloyd, Dames Brown",
    label: "Classic",
    year: 2018,
    cover:
      "https://i.discogs.com/87c3J8e1s8xeQJCtXHuYgnkJ25z-RF91hDGqUjXwc3I/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTExNzUz/Njg5LTE1NDc1ODQ0/NTUtNzgwOS5qcGVn.jpeg",
    tracks: [
      {
        title: "Calling Out",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 45,
        genre: "House",
        playable: true,
        _id: "600",
        audioFeatures: {
          acousticness: 0.239,
          danceability: 0.701,
          duration_ms: 228217,
          energy: 0.692,
          instrumentalness: 0.0854,
          key: 7,
          liveness: 0.742,
          loudness: -6.529,
          mode: 0,
          speechiness: 0.0521,
          tempo: 122.003,
          time_signature: 4,
          valence: 0.551,
        },
        spotifyID: "54BjeQ51K2tO2WzUpks6sR",
      },
      {
        title: "Calling Out (Floorplan Club Mix)",
        artists: "Floorplan",
        position: "A2",
        duration: null,
        rpm: 45,
        genre: "House",
        playable: true,
        _id: "601",
        audioFeatures: {
          acousticness: 0.000221,
          danceability: 0.706,
          duration_ms: 238504,
          energy: 0.744,
          instrumentalness: 0.222,
          key: 7,
          liveness: 0.118,
          loudness: -9.379,
          mode: 1,
          speechiness: 0.0879,
          tempo: 125.015,
          time_signature: 4,
          valence: 0.351,
        },
        spotifyID: "3FWm7LZYTWeshFBXjymUOz",
      },
      {
        title: "Calling Out (Floorplan Revival Mix)",
        artists: "Floorplan",
        position: "B1",
        duration: null,
        rpm: 45,
        genre: "House",
        playable: true,
        _id: "602",
        audioFeatures: {
          acousticness: 0.00372,
          danceability: 0.78,
          duration_ms: 483811,
          energy: 0.744,
          instrumentalness: 0.771,
          key: 7,
          liveness: 0.292,
          loudness: -9.158,
          mode: 1,
          speechiness: 0.0344,
          tempo: 125.003,
          time_signature: 4,
          valence: 0.367,
        },
        spotifyID: "0vlLc8pkEWAbAD4gDDZUvH",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543636" } },
    user: "1",
  },
  {
    _id: "7",
    discogsID: 13821161,
    catno: "DRH061",
    title: "Raw Cuts #2",
    artists: "DJ Deep",
    label: "Deeply Rooted",
    year: 2019,
    cover:
      "https://i.discogs.com/kjA8parGurlvd6gkCpjidHR6lnjdysX8HvYd_eHr-1k/rs:fit/g:sm/q:90/h:598/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEzODIx/MTYxLTE1NjE4NzY2/MjUtMjk1OS5qcGVn.jpeg",
    tracks: [
      {
        title: "Head Up",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Techno, Deep Techno",
        playable: true,
        _id: "700",
        audioFeatures: {
          acousticness: 0.0016,
          danceability: 0.809,
          duration_ms: 371000,
          energy: 0.67,
          instrumentalness: 0.916,
          key: 11,
          liveness: 0.103,
          loudness: -11.436,
          mode: 0,
          speechiness: 0.0508,
          tempo: 125.013,
          time_signature: 4,
          valence: 0.196,
        },
        spotifyID: "2CcMWu0mGuTdUcH42N6IIs",
      },
      {
        title: "Swingin' With The P",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Techno, Deep Techno",
        playable: true,
        _id: "701",
        audioFeatures: {
          acousticness: 0.000409,
          danceability: 0.797,
          duration_ms: 350000,
          energy: 0.53,
          instrumentalness: 0.925,
          key: 11,
          liveness: 0.0968,
          loudness: -13.513,
          mode: 0,
          speechiness: 0.0788,
          tempo: 131.998,
          time_signature: 4,
          valence: 0.212,
        },
        spotifyID: "6s1AlnQtkbsNqFlbDQ6zhI",
      },
      {
        title: "Porte",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Techno, Deep Techno",
        playable: true,
        _id: "702",
        audioFeatures: {
          acousticness: 0.00032,
          danceability: 0.797,
          duration_ms: 384000,
          energy: 0.977,
          instrumentalness: 0.913,
          key: 10,
          liveness: 0.107,
          loudness: -8.55,
          mode: 0,
          speechiness: 0.0456,
          tempo: 124.981,
          time_signature: 4,
          valence: 0.42,
        },
        spotifyID: "2Z52kICjXkh4CX6qDFq4uG",
      },
      {
        title: "Corridor Rodeo",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Techno, Deep Techno",
        playable: true,
        _id: "703",
        audioFeatures: {
          acousticness: 0.00000456,
          danceability: 0.798,
          duration_ms: 425000,
          energy: 0.791,
          instrumentalness: 0.923,
          key: 11,
          liveness: 0.107,
          loudness: -7.807,
          mode: 0,
          speechiness: 0.042,
          tempo: 127.991,
          time_signature: 4,
          valence: 0.127,
        },
        spotifyID: "3WPAoHsRQOvQSUoC94XUwJ",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543636" } },
    user: "1",
  },
  {
    _id: "8",
    discogsID: 8770534,
    catno: "GR026",
    title: "Deep Detroit Heat Re-edits Vol. 1",
    artists: "Terrence Parker",
    label: "Groovement",
    year: 2016,
    cover:
      "https://i.discogs.com/TUEtYdP9PGgd1OitBOj0JXjpygW357J32YizjmnYebQ/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTg3NzA1/MzQtMTQ2ODQxNTA1/NC04MjY3LmpwZWc.jpeg",
    tracks: [
      {
        title: "Set Me Free (TP's 2016 Freedom Re-edit)",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "House",
        playable: true,
        _id: "800",
        audioFeatures: {
          acousticness: 0.000712,
          danceability: 0.779,
          duration_ms: 587581,
          energy: 0.576,
          instrumentalness: 0.783,
          key: 9,
          liveness: 0.0438,
          loudness: -10.817,
          mode: 0,
          speechiness: 0.0429,
          tempo: 121.919,
          time_signature: 4,
          valence: 0.742,
        },
        spotifyID: "5xaABARA3c4WJqHY1eK11U",
      },
      {
        title: "Woman (TP's 2015 Instrumental Re-edit)",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "House",
        playable: true,
        _id: "801",
        audioFeatures: {
          acousticness: 0.024,
          danceability: 0.686,
          duration_ms: 349379,
          energy: 0.467,
          instrumentalness: 0.902,
          key: 5,
          liveness: 0.0569,
          loudness: -11.797,
          mode: 0,
          speechiness: 0.0407,
          tempo: 121.981,
          time_signature: 4,
          valence: 0.433,
        },
        spotifyID: "2VeIrQ9Lvl5l5CdSj4CNxQ",
      },
      {
        title: "When Will We Be Free (TP's 2016 Instrumental Re-edit)",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "House",
        playable: true,
        _id: "802",
        audioFeatures: {
          acousticness: 0.000231,
          danceability: 0.811,
          duration_ms: 356380,
          energy: 0.654,
          instrumentalness: 0.942,
          key: 0,
          liveness: 0.0774,
          loudness: -10.277,
          mode: 1,
          speechiness: 0.061,
          tempo: 121.047,
          time_signature: 4,
          valence: 0.69,
        },
        spotifyID: "454hC8Mxtszf6cDlGpIR9A",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543636" } },
    user: "1",
  },
  {
    _id: "9",
    discogsID: 1921107,
    catno: "HFT009",
    title: "Hyph Mngo / Wet Look",
    artists: "Joy Orbison",
    label: "Hotflush Recordings",
    year: 2009,
    cover:
      "https://i.discogs.com/SOmGXiczzCLeJ_8ylXuve9PSy0TfFucZ3cp41WvpYPs/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE5MjEx/MDctMTI1MjU4NTcw/Ny5qcGVn.jpeg",
    tracks: [
      {
        title: "Hyph Mngo",
        artists: "",
        position: "A1",
        duration: 337000,
        rpm: 45,
        genre: "UK Garage, Dubstep",
        playable: true,
        _id: "900",
        audioFeatures: {
          acousticness: 0.116,
          danceability: 0.485,
          duration_ms: 338413,
          energy: 0.552,
          instrumentalness: 0.0455,
          key: 1,
          liveness: 0.0864,
          loudness: -9.202,
          mode: 1,
          speechiness: 0.0543,
          tempo: 139.792,
          time_signature: 4,
          valence: 0.0676,
        },
        spotifyID: "2BTjb4H2FLh2e5zx3nI6Ul",
      },
      {
        title: "Wet Look",
        artists: "",
        position: "B1",
        duration: 317000,
        rpm: 45,
        genre: "UK Garage, Dubstep",
        playable: true,
        _id: "901",
        audioFeatures: {
          acousticness: 0.0263,
          danceability: 0.804,
          duration_ms: 317653,
          energy: 0.833,
          instrumentalness: 0.824,
          key: 5,
          liveness: 0.571,
          loudness: -8.058,
          mode: 0,
          speechiness: 0.377,
          tempo: 136.097,
          time_signature: 4,
          valence: 0.187,
        },
        spotifyID: "2mf31n2SsO3vbRwVcWDknX",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543636" } },
    spotifyID: "693G06k5b2GoKDHXGTzWPb",
    user: "1",
  },
  {
    _id: "10",
    discogsID: 2783852,
    catno: "HDB013",
    title: "Street Halo",
    artists: "Burial",
    label: "Hyperdub",
    year: 2011,
    cover:
      "https://i.discogs.com/Ngfp7Dgk5mjycj1uqS5wBdmpaOnyMNhCdNXL89vGt7E/rs:fit/g:sm/q:90/h:591/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI3ODM4/NTItMTMxMzkyNzk2/OC5qcGVn.jpeg",
    tracks: [
      {
        title: "Street Halo",
        artists: "",
        position: "A1",
        duration: 404000,
        rpm: 45,
        genre: "Ambient, UK Garage, House",
        playable: true,
        _id: "1000",
        audioFeatures: {
          acousticness: 0.448,
          danceability: 0.75,
          duration_ms: 404066,
          energy: 0.723,
          instrumentalness: 0.926,
          key: 10,
          liveness: 0.387,
          loudness: -9.75,
          mode: 1,
          speechiness: 0.117,
          tempo: 122.897,
          time_signature: 4,
          valence: 0.383,
        },
        spotifyID: "1Ty38oHHaDI9sG1PwAKHGA",
      },
      {
        title: "NYC",
        artists: "",
        position: "B1",
        duration: 456000,
        rpm: 45,
        genre: "Ambient, UK Garage, House",
        playable: true,
        _id: "1001",
        audioFeatures: {
          acousticness: 0.181,
          danceability: 0.79,
          duration_ms: 458693,
          energy: 0.671,
          instrumentalness: 0.82,
          key: 10,
          liveness: 0.171,
          loudness: -8.655,
          mode: 0,
          speechiness: 0.0767,
          tempo: 115.201,
          time_signature: 4,
          valence: 0.632,
        },
        spotifyID: "1ZJyJhgJzndyIkvTrPbVAO",
      },
      {
        title: "Stolen Dog",
        artists: "",
        position: "B2",
        duration: 381000,
        rpm: 45,
        genre: "Ambient, UK Garage, House",
        playable: true,
        _id: "1002",
        audioFeatures: {
          acousticness: 0.614,
          danceability: 0.642,
          duration_ms: 381093,
          energy: 0.67,
          instrumentalness: 0.924,
          key: 6,
          liveness: 0.609,
          loudness: -13.891,
          mode: 0,
          speechiness: 0.0959,
          tempo: 115.679,
          time_signature: 4,
          valence: 0.512,
        },
        spotifyID: "2CwP6A4P9tsPH3rCDKgq2D",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543636" } },
    spotifyID: "1UTUaGgazW903gGukLzLe5",
    user: "1",
  },
  {
    _id: "11",
    discogsID: 4105551,
    catno: "HDB069",
    title: "Truant",
    artists: "Burial",
    label: "Hyperdub",
    year: 2012,
    cover:
      "https://i.discogs.com/aSZjX3hOYcNeH1MqlI1UcE3gdirjW6kRJY3UwxVN7Yg/rs:fit/g:sm/q:90/h:600/w:593/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTQxMDU1/NTEtMTM1NjI4MjYx/Ni04MzE3LmpwZWc.jpeg",
    tracks: [
      {
        title: "Truant",
        artists: "",
        position: "A1",
        duration: 705000,
        rpm: 33,
        genre: "Bass Music, Experimental, Dubstep",
        playable: true,
        _id: "1100",
        audioFeatures: {
          acousticness: 0.222,
          danceability: 0.655,
          duration_ms: 705613,
          energy: 0.485,
          instrumentalness: 0.799,
          key: 8,
          liveness: 0.384,
          loudness: -16.035,
          mode: 0,
          speechiness: 0.126,
          tempo: 120.937,
          time_signature: 4,
          valence: 0.257,
        },
        spotifyID: "6WmsTMmssTE7cvFP0NDwXy",
      },
      {
        title: "Rough Sleeper",
        artists: "",
        position: "B1",
        duration: 826000,
        rpm: 33,
        genre: "Bass Music, Experimental, Dubstep",
        playable: true,
        _id: "1101",
        audioFeatures: {
          acousticness: 0.335,
          danceability: 0.479,
          duration_ms: 827453,
          energy: 0.733,
          instrumentalness: 0.928,
          key: 6,
          liveness: 0.539,
          loudness: -11.597,
          mode: 1,
          speechiness: 0.119,
          tempo: 128.042,
          time_signature: 4,
          valence: 0.261,
        },
        spotifyID: "5EQFTT3JkfqNu2DfILy0rv",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543636" } },
    spotifyID: "0EQ5D7MnhvJbtD4KZo22zP",
    user: "1",
  },
  {
    _id: "12",
    discogsID: 6756442,
    catno: "KVK900",
    title: "KVK900",
    artists: "Mr. Tophat, Art Alfie",
    label: "Karlovak",
    year: 2015,
    cover:
      "https://i.discogs.com/Q9xC-qQ8PBypTgvbboTtKVVvE59gP_iKl3z1jltguWA/rs:fit/g:sm/q:90/h:240/w:240/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTY3NTY0/NDItMTQyNTk3Njcw/MS0xNDg4LmpwZWc.jpeg",
    tracks: [
      {
        title: "House Music",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Deep House, House, Tech House, Techno, Deep House",
        playable: true,
        _id: "1200",
        audioFeatures: {
          acousticness: 0.000488,
          danceability: 0.75,
          duration_ms: 884107,
          energy: 0.675,
          instrumentalness: 0.906,
          key: 6,
          liveness: 0.075,
          loudness: -12.699,
          mode: 0,
          speechiness: 0.0439,
          tempo: 126.01,
          time_signature: 4,
          valence: 0.629,
        },
        spotifyID: "1EDlNZC45qRtgv1CzJTCcB",
      },
      {
        title: "The Glitch",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Deep House, House, Tech House, Techno, Deep House",
        playable: true,
        _id: "1201",
        audioFeatures: {
          acousticness: 0.000319,
          danceability: 0.864,
          duration_ms: 654068,
          energy: 0.418,
          instrumentalness: 0.895,
          key: 6,
          liveness: 0.107,
          loudness: -16.215,
          mode: 1,
          speechiness: 0.12,
          tempo: 123.998,
          time_signature: 4,
          valence: 0.287,
        },
        spotifyID: "5RFKuFohGDPsYVTYtCQDRI",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543637" } },
    user: "1",
  },
  {
    _id: "13",
    discogsID: 9691236,
    catno: "LT029",
    title: "It Never Ends",
    artists: "Nthng",
    label: "Lobster Theremin",
    year: 2017,
    cover:
      "https://i.discogs.com/bpoTfU6R6rtdBfVjRDtY2_aWOhzZG9enb3kQ0chrL0o/rs:fit/g:sm/q:90/h:579/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTk2OTEy/MzYtMTQ5NDc2Nzc5/My03ODI5LmpwZWc.jpeg",
    tracks: [
      {
        title: "Touches",
        artists: "",
        position: "A1",
        duration: 368000,
        rpm: 33,
        genre: "Ambient, Techno, Deep Techno, Dub Techno",
        playable: true,
        _id: "1300",
        audioFeatures: {
          acousticness: 0.993,
          danceability: 0.112,
          duration_ms: 368399,
          energy: 0.237,
          instrumentalness: 0.932,
          key: 2,
          liveness: 0.0799,
          loudness: -20.645,
          mode: 0,
          speechiness: 0.0474,
          tempo: 74.161,
          time_signature: 1,
          valence: 0.0396,
        },
        spotifyID: "0ntdhkbw1cWXSl8Dl9LsBf",
      },
      {
        title: "Galaxy",
        artists: "",
        position: "A2",
        duration: 397000,
        rpm: 33,
        genre: "Ambient, Techno, Deep Techno, Dub Techno",
        playable: true,
        _id: "1301",
        audioFeatures: {
          acousticness: 0.000494,
          danceability: 0.796,
          duration_ms: 397583,
          energy: 0.662,
          instrumentalness: 0.924,
          key: 2,
          liveness: 0.097,
          loudness: -14.875,
          mode: 1,
          speechiness: 0.0519,
          tempo: 132.012,
          time_signature: 4,
          valence: 0.239,
        },
        spotifyID: "6hhQwGRKT24Js9DVVWL8AT",
      },
      {
        title: "It Never Ends",
        artists: "",
        position: "B1",
        duration: 556000,
        rpm: 33,
        genre: "Ambient, Techno, Deep Techno, Dub Techno",
        playable: true,
        _id: "1302",
        audioFeatures: {
          acousticness: 0.102,
          danceability: 0.558,
          duration_ms: 556304,
          energy: 0.476,
          instrumentalness: 0.862,
          key: 9,
          liveness: 0.112,
          loudness: -17.407,
          mode: 0,
          speechiness: 0.034,
          tempo: 126.037,
          time_signature: 4,
          valence: 0.0374,
        },
        spotifyID: "6laqIOvuENmYclQzV2YWt1",
      },
      {
        title: "Soms",
        artists: "",
        position: "C1",
        duration: 501000,
        rpm: 33,
        genre: "Ambient, Techno, Deep Techno, Dub Techno",
        playable: true,
        _id: "1303",
        audioFeatures: {
          acousticness: 0.349,
          danceability: 0.551,
          duration_ms: 501473,
          energy: 0.286,
          instrumentalness: 0.851,
          key: 9,
          liveness: 0.116,
          loudness: -22.169,
          mode: 0,
          speechiness: 0.0562,
          tempo: 130.004,
          time_signature: 4,
          valence: 0.0392,
        },
        spotifyID: "1ZvZeNPxnckFRlE3lSwU1l",
      },
      {
        title: "Unity",
        artists: "",
        position: "C2",
        duration: 426000,
        rpm: 33,
        genre: "Ambient, Techno, Deep Techno, Dub Techno",
        playable: true,
        _id: "1304",
        audioFeatures: {
          acousticness: 0.071,
          danceability: 0.7,
          duration_ms: 426258,
          energy: 0.427,
          instrumentalness: 0.909,
          key: 6,
          liveness: 0.11,
          loudness: -14.633,
          mode: 1,
          speechiness: 0.0486,
          tempo: 128.032,
          time_signature: 4,
          valence: 0.547,
        },
        spotifyID: "4jU32KBBIjsZR3yt4ZTOW6",
      },
      {
        title: "In My Dreams",
        artists: "",
        position: "D1",
        duration: 222000,
        rpm: 33,
        genre: "Ambient, Techno, Deep Techno, Dub Techno",
        playable: true,
        _id: "1305",
        audioFeatures: {
          acousticness: 0.983,
          danceability: 0.0892,
          duration_ms: 222442,
          energy: 0.0248,
          instrumentalness: 0.938,
          key: 4,
          liveness: 0.178,
          loudness: -28.272,
          mode: 0,
          speechiness: 0.0358,
          tempo: 86.105,
          time_signature: 3,
          valence: 0.038,
        },
        spotifyID: "08XgqqU1ygQAuCnWjcB5IH",
      },
      {
        title: "Abyss",
        artists: "",
        position: "D2",
        duration: 523000,
        rpm: 33,
        genre: "Ambient, Techno, Deep Techno, Dub Techno",
        playable: true,
        _id: "1306",
        audioFeatures: {
          acousticness: 0.529,
          danceability: 0.741,
          duration_ms: 523509,
          energy: 0.393,
          instrumentalness: 0.889,
          key: 9,
          liveness: 0.103,
          loudness: -19.802,
          mode: 0,
          speechiness: 0.0428,
          tempo: 128.016,
          time_signature: 4,
          valence: 0.231,
        },
        spotifyID: "5iOlvOrn2fkwhwvJDAb7nm",
      },
      {
        title: "Eternal",
        artists: "",
        position: "E1",
        duration: 628000,
        rpm: 33,
        genre: "Ambient, Techno, Deep Techno, Dub Techno",
        playable: true,
        _id: "1307",
        audioFeatures: {
          acousticness: 0.0939,
          danceability: 0.883,
          duration_ms: 628780,
          energy: 0.332,
          instrumentalness: 0.87,
          key: 9,
          liveness: 0.0779,
          loudness: -16.105,
          mode: 0,
          speechiness: 0.119,
          tempo: 132.009,
          time_signature: 4,
          valence: 0.0396,
        },
        spotifyID: "4RSeOhrMtJmO6LiRPLlx0V",
      },
      {
        title: "Last",
        artists: "",
        position: "F1",
        duration: 567000,
        rpm: 33,
        genre: "Ambient, Techno, Deep Techno, Dub Techno",
        playable: true,
        _id: "1308",
        audioFeatures: {
          acousticness: 0.0945,
          danceability: 0.575,
          duration_ms: 567409,
          energy: 0.398,
          instrumentalness: 0.829,
          key: 2,
          liveness: 0.124,
          loudness: -21.372,
          mode: 1,
          speechiness: 0.043,
          tempo: 132.008,
          time_signature: 4,
          valence: 0.0893,
        },
        spotifyID: "0GaJzqnsTGOPQOqNEIb2ik",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543637" } },
    spotifyID: "1ZIQepW7MGhEZlNwVIMdNq",
    user: "1",
  },
  {
    _id: "14",
    discogsID: 15268886,
    catno: "LT066",
    title: "Hypnotherapy",
    artists: "Nthng",
    label: "Lobster Theremin",
    year: 2020,
    cover:
      "https://i.discogs.com/-sx88-ZwAENHuqUxQ_HHAZNYrZR7rDpt6Xo_6mJNs7w/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE1MjY4/ODg2LTE1ODg5NTU2/NTktNTU0My5qcGVn.jpeg",
    tracks: [
      {
        title: "50 Flower",
        artists: "",
        position: "A1",
        duration: 325000,
        rpm: 33,
        genre: "Ambient, Techno, Dub Techno",
        playable: true,
        _id: "1400",
        audioFeatures: {
          acousticness: 0.861,
          danceability: 0.22,
          duration_ms: 325358,
          energy: 0.11,
          instrumentalness: 0.735,
          key: 3,
          liveness: 0.105,
          loudness: -28.144,
          mode: 1,
          speechiness: 0.0416,
          tempo: 141.769,
          time_signature: 3,
          valence: 0.201,
        },
        spotifyID: "0YlQuOsGHTwg50OaY5cMEm",
      },
      {
        title: "I Just Am",
        artists: "",
        position: "A2",
        duration: 447000,
        rpm: 33,
        genre: "Ambient, Techno, Dub Techno",
        playable: true,
        _id: "1401",
        audioFeatures: {
          acousticness: 0.105,
          danceability: 0.652,
          duration_ms: 447755,
          energy: 0.521,
          instrumentalness: 0.881,
          key: 4,
          liveness: 0.323,
          loudness: -13.531,
          mode: 0,
          speechiness: 0.0492,
          tempo: 128.017,
          time_signature: 4,
          valence: 0.2,
        },
        spotifyID: "0fluPtzri0v8nlgrFRbQRt",
      },
      {
        title: "Heitt",
        artists: "",
        position: "B1",
        duration: 533000,
        rpm: 33,
        genre: "Ambient, Techno, Dub Techno",
        playable: true,
        _id: "1402",
        audioFeatures: {
          acousticness: 0.0885,
          danceability: 0.655,
          duration_ms: 533689,
          energy: 0.638,
          instrumentalness: 0.874,
          key: 2,
          liveness: 0.105,
          loudness: -12.047,
          mode: 1,
          speechiness: 0.0393,
          tempo: 138.019,
          time_signature: 4,
          valence: 0.0393,
        },
        spotifyID: "7HssHIK1azWIkl6zEMbrrq",
      },
      {
        title: "Beautiful Love",
        artists: "",
        position: "B2",
        duration: 269000,
        rpm: 33,
        genre: "Ambient, Techno, Dub Techno",
        playable: true,
        _id: "1403",
        audioFeatures: {
          acousticness: 0.773,
          danceability: 0.264,
          duration_ms: 269366,
          energy: 0.177,
          instrumentalness: 0.926,
          key: 0,
          liveness: 0.107,
          loudness: -22.402,
          mode: 0,
          speechiness: 0.0808,
          tempo: 77.504,
          time_signature: 5,
          valence: 0.0852,
        },
        spotifyID: "7fxJLzCtCaYLuoOdpvykoQ",
      },
      {
        title: "Wave Return",
        artists: "",
        position: "C1",
        duration: 478000,
        rpm: 33,
        genre: "Ambient, Techno, Dub Techno",
        playable: true,
        _id: "1404",
        audioFeatures: {
          acousticness: 0.000104,
          danceability: 0.765,
          duration_ms: 478299,
          energy: 0.947,
          instrumentalness: 0.91,
          key: 1,
          liveness: 0.0841,
          loudness: -8.529,
          mode: 1,
          speechiness: 0.0426,
          tempo: 140.013,
          time_signature: 4,
          valence: 0.477,
        },
        spotifyID: "5h2cO6kvCwOkMIL6gXFJW4",
      },
      {
        title: "Spirit Of Ecstasy",
        artists: "",
        position: "C2",
        duration: 400000,
        rpm: 33,
        genre: "Ambient, Techno, Dub Techno",
        playable: true,
        _id: "1405",
        audioFeatures: {
          acousticness: 0.687,
          danceability: 0.653,
          duration_ms: 400549,
          energy: 0.377,
          instrumentalness: 0.904,
          key: 8,
          liveness: 0.107,
          loudness: -11.783,
          mode: 1,
          speechiness: 0.0314,
          tempo: 127.022,
          time_signature: 4,
          valence: 0.142,
        },
        spotifyID: "3brTwHd63QUArh7ZJCL5PH",
      },
      {
        title: "Hypnotherapy",
        artists: "",
        position: "D1",
        duration: 498000,
        rpm: 33,
        genre: "Ambient, Techno, Dub Techno",
        playable: true,
        _id: "1406",
        audioFeatures: {
          acousticness: 0.0366,
          danceability: 0.569,
          duration_ms: 498070,
          energy: 0.366,
          instrumentalness: 0.93,
          key: 1,
          liveness: 0.0533,
          loudness: -14.481,
          mode: 1,
          speechiness: 0.0512,
          tempo: 128.056,
          time_signature: 4,
          valence: 0.0811,
        },
        spotifyID: "4ErNoOxCnGie67vmCRo8u9",
      },
      {
        title: "With You",
        artists: "",
        position: "D2",
        duration: 459000,
        rpm: 33,
        genre: "Ambient, Techno, Dub Techno",
        playable: true,
        _id: "1407",
        audioFeatures: {
          acousticness: 0.368,
          danceability: 0.392,
          duration_ms: 459031,
          energy: 0.206,
          instrumentalness: 0.926,
          key: 9,
          liveness: 0.0935,
          loudness: -18.678,
          mode: 0,
          speechiness: 0.0366,
          tempo: 129.574,
          time_signature: 4,
          valence: 0.032,
        },
        spotifyID: "71X0yWa9OmTa4BLSYCegYb",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543637" } },
    spotifyID: "70xUST6t5AMSDC1N5bx2br",
    user: "1",
  },
  {
    _id: "15",
    discogsID: 8472790,
    catno: "M.PM27",
    title: "Music / Tell You No Lie",
    artists: "Floorplan",
    label: "M-Plant",
    year: 2016,
    cover:
      "https://i.discogs.com/IfIa0OMT-wQnBmbRJMAuHgwHhLd4P2CZyZx7qRUg17g/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTg0NzI3/OTAtMTYyMTY1OTUy/OS00NDIwLmpwZWc.jpeg",
    tracks: [
      {
        title: "Music",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 45,
        genre: "House, Techno",
        playable: true,
        _id: "1500",
        audioFeatures: {
          acousticness: 0.0287,
          danceability: 0.744,
          duration_ms: 390616,
          energy: 0.543,
          instrumentalness: 0.674,
          key: 6,
          liveness: 0.0929,
          loudness: -11.438,
          mode: 0,
          speechiness: 0.038,
          tempo: 129.002,
          time_signature: 4,
          valence: 0.232,
        },
        spotifyID: "6jt1rkwVcKPBkySudFGx3d",
      },
      {
        title: "Tell You No Lie",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 45,
        genre: "House, Techno",
        playable: true,
        _id: "1501",
        audioFeatures: {
          acousticness: 0.000741,
          danceability: 0.742,
          duration_ms: 504059,
          energy: 0.898,
          instrumentalness: 0.418,
          key: 0,
          liveness: 0.252,
          loudness: -9.34,
          mode: 1,
          speechiness: 0.0363,
          tempo: 130.025,
          time_signature: 4,
          valence: 0.597,
        },
        spotifyID: "2jEBi9VVbXJ2Y7bBHxAeIK",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543637" } },
    spotifyID: "7dDhIiUAnc1PTAN2McTG81",
    user: "1",
  },
  {
    _id: "16",
    discogsID: 1487139,
    catno: "MCDE 1202",
    title: "Raw Cuts # 3 / Raw Cuts # 4",
    artists: "Motor City Drum Ensemble",
    label: "MCDE",
    year: 2008,
    cover:
      "https://i.discogs.com/-8U6wVQ5UrhLljH2Gk8KVQSbEIQ8Vb-K58vSFtFyJEc/rs:fit/g:sm/q:90/h:490/w:490/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE0ODcx/MzktMTM0MzA2NTM3/OC02ODQ2LmpwZWc.jpeg",
    tracks: [
      {
        title: "Raw Cuts # 3",
        artists: "",
        position: "A1",
        duration: 413000,
        rpm: 45,
        genre: "Deep House, Disco",
        playable: true,
        _id: "1600",
        audioFeatures: {
          acousticness: 0.0496,
          danceability: 0.793,
          duration_ms: 412595,
          energy: 0.86,
          instrumentalness: 0.0124,
          key: 7,
          liveness: 0.0789,
          loudness: -6.585,
          mode: 1,
          speechiness: 0.1,
          tempo: 116.921,
          time_signature: 4,
          valence: 0.143,
        },
        spotifyID: "7dXL7QU2UD2ij7cVnJmJd2",
      },
      {
        title: "Raw Cuts # 4",
        artists: "",
        position: "B1",
        duration: 344000,
        rpm: 45,
        genre: "Deep House, Disco",
        playable: true,
        _id: "1601",
        audioFeatures: {
          acousticness: 0.0255,
          danceability: 0.761,
          duration_ms: 345459,
          energy: 0.816,
          instrumentalness: 0.91,
          key: 10,
          liveness: 0.0763,
          loudness: -6.536,
          mode: 0,
          speechiness: 0.074,
          tempo: 123.013,
          time_signature: 4,
          valence: 0.556,
        },
        spotifyID: "5Vs5wTTZTh5S9IDbcvEm9Y",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543637" } },
    user: "1",
  },
  {
    _id: "17",
    discogsID: 7121927,
    catno: "PAMPA 024",
    title: "XTC",
    artists: "DJ Koze",
    label: "Pampa Records",
    year: 2015,
    cover:
      "https://i.discogs.com/nGdrN1GD3yC6UvnS-MFGo6iuSa2n5RFf4B_24J5CIl0/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTcxMjE5/MjctMTQzOTE1MjUw/OC0zODk5LmpwZWc.jpeg",
    tracks: [
      {
        title: "XTC",
        artists: "",
        position: "A1",
        duration: 499000,
        rpm: 45,
        genre: "House, Deep House, Nu-Disco",
        playable: true,
        _id: "1700",
        audioFeatures: {
          acousticness: 0.0229,
          danceability: 0.707,
          duration_ms: 499258,
          energy: 0.748,
          instrumentalness: 0.764,
          key: 7,
          liveness: 0.106,
          loudness: -11.086,
          mode: 1,
          speechiness: 0.048,
          tempo: 118.023,
          time_signature: 4,
          valence: 0.166,
        },
        spotifyID: "1wYg1Ud13bBAJgKocnm73h",
      },
      {
        title: "Knee On Belly",
        artists: "",
        position: "B1",
        duration: 365000,
        rpm: 45,
        genre: "House, Deep House, Nu-Disco",
        playable: true,
        _id: "1701",
        audioFeatures: {
          acousticness: 0.00179,
          danceability: 0.866,
          duration_ms: 365278,
          energy: 0.469,
          instrumentalness: 0.562,
          key: 1,
          liveness: 0.177,
          loudness: -11.179,
          mode: 1,
          speechiness: 0.319,
          tempo: 121.928,
          time_signature: 4,
          valence: 0.373,
        },
        spotifyID: "5N4xLthpUYk22JRckudsjB",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543638" } },
    spotifyID: "7awbcJCFjNCtuzgEyPNFbB",
    user: "1",
  },
  {
    _id: "18",
    discogsID: 16624581,
    catno: "PG064",
    title: "Just Wanna Dance ep",
    artists: "Mr. G",
    label: "Phoenix G.",
    year: 2020,
    cover:
      "https://i.discogs.com/0Vz890rnYY996Jq81811R0-dIHuw2qGholspWPA0HTI/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE2NjI0/NTgxLTE2MDg5MDQ0/NDUtMTQyMC5qcGVn.jpeg",
    tracks: [
      {
        title: "Just Wanna Dance",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "House",
        playable: true,
        _id: "1800",
        audioFeatures: {
          acousticness: 0.0542,
          danceability: 0.783,
          duration_ms: 395040,
          energy: 0.992,
          instrumentalness: 0.892,
          key: 10,
          liveness: 0.187,
          loudness: -7.178,
          mode: 0,
          speechiness: 0.0432,
          tempo: 126.975,
          time_signature: 4,
          valence: 0.551,
        },
        spotifyID: "23SiHUYHhoe49kEv4TrwHS",
      },
      {
        title: "Just Wanna Dance (D's Rise An Fall Of Dr. Wonkenstein)",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "House",
        playable: true,
        _id: "1801",
        audioFeatures: {
          acousticness: 0.0631,
          danceability: 0.734,
          duration_ms: 456600,
          energy: 0.946,
          instrumentalness: 0.893,
          key: 1,
          liveness: 0.275,
          loudness: -7.269,
          mode: 1,
          speechiness: 0.0636,
          tempo: 127.016,
          time_signature: 4,
          valence: 0.301,
        },
        spotifyID: "7fTRlDMa6cKXvB1nAWBQxs",
      },
      {
        title: "Ease Ya Mind (Mango Boys Dub)",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "House",
        playable: true,
        _id: "1802",
        audioFeatures: {
          acousticness: 0.00048,
          danceability: 0.712,
          duration_ms: 444891,
          energy: 0.94,
          instrumentalness: 0.36,
          key: 9,
          liveness: 0.0781,
          loudness: -7.353,
          mode: 0,
          speechiness: 0.033,
          tempo: 124.975,
          time_signature: 4,
          valence: 0.861,
        },
        spotifyID: "2roWR0P3eY6GDXGZDkTVjO",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543638" } },
    spotifyID: "3E8MMovIeHxfpNux0ynpdJ",
    user: "1",
  },
  {
    _id: "19",
    discogsID: 154227,
    catno: "PWLT 207",
    title: "Keep On Pumpin' It",
    artists: "Vision Masters, Tony King, Kylie Minogue",
    label: "PWL Records",
    year: 1991,
    cover:
      "https://i.discogs.com/upzpoLucpiO3qlsxjgwpmpmbW6MuxxMDkT-22sAUlvA/rs:fit/g:sm/q:90/h:389/w:400/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE1NDIy/Ny0xMDc3NzA0ODIz/LmpwZw.jpeg",
    tracks: [
      {
        title: "Keep On Pumpin' It (Angelic Remix)",
        artists: "Asha Elfenbein, Tony King, Vision Masters",
        position: "A1",
        duration: 445000,
        rpm: 45,
        genre: "House, Euro House",
        playable: true,
        _id: "1900",
        audioFeatures: {
          acousticness: 0.000656,
          danceability: 0.397,
          duration_ms: 442716,
          energy: 0.714,
          instrumentalness: 0.603,
          key: 7,
          liveness: 0.603,
          loudness: -10.967,
          mode: 1,
          speechiness: 0.0417,
          tempo: 125.007,
          time_signature: 4,
          valence: 0.157,
        },
        spotifyID: "2RFTmp5xXyLCc5oCc72PEB",
      },
      {
        title: "Keep On Pumpin' It (Astral Flight Mix)",
        artists: "Julian Gingell, Phil Harding",
        position: "B1",
        duration: 415000,
        rpm: 45,
        genre: "House, Euro House",
        playable: true,
        _id: "1901",
        audioFeatures: {
          acousticness: 0.00342,
          danceability: 0.695,
          duration_ms: 411584,
          energy: 0.918,
          instrumentalness: 0.805,
          key: 11,
          liveness: 0.0973,
          loudness: -10.555,
          mode: 0,
          speechiness: 0.0399,
          tempo: 125.032,
          time_signature: 4,
          valence: 0.574,
        },
        spotifyID: "6cIi81mZ1XNAxsUajrPvP6",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543638" } },
    user: "1",
  },
  {
    _id: "20",
    discogsID: 16470219,
    catno: "REKIDS168",
    title: "Mirror Man",
    artists: "Robert Hood",
    label: "REKIDS",
    year: 2020,
    cover:
      "https://i.discogs.com/NOeT2AIvxuC06-mv4P4ZHpesgH0khl2TbC1FYW_At30/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE2NDcw/MjE5LTE2MDc5NDE5/NzAtOTAwNy5qcGVn.jpeg",
    tracks: [
      {
        title: "Through A Looking Glass Darkly",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2000",
        audioFeatures: {
          acousticness: 0.921,
          danceability: 0.132,
          duration_ms: 215627,
          energy: 0.38,
          instrumentalness: 0.947,
          key: 9,
          liveness: 0.0801,
          loudness: -12.092,
          mode: 0,
          speechiness: 0.037,
          tempo: 75.898,
          time_signature: 4,
          valence: 0.0899,
        },
        spotifyID: "4zZpYM5nsYtWXjTsdpr8uu",
      },
      {
        title: "Fear Not",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2001",
        audioFeatures: {
          acousticness: 0.00000719,
          danceability: 0.682,
          duration_ms: 379252,
          energy: 0.961,
          instrumentalness: 0.827,
          key: 4,
          liveness: 0.116,
          loudness: -7.909,
          mode: 1,
          speechiness: 0.0491,
          tempo: 130.001,
          time_signature: 4,
          valence: 0.0386,
        },
        spotifyID: "5ynV3okDDK8e1PkUwhOT7K",
      },
      {
        title: "Black Mirror",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2002",
        audioFeatures: {
          acousticness: 0.501,
          danceability: 0.725,
          duration_ms: 338427,
          energy: 0.538,
          instrumentalness: 0.594,
          key: 8,
          liveness: 0.106,
          loudness: -13.905,
          mode: 1,
          speechiness: 0.0342,
          tempo: 108.013,
          time_signature: 3,
          valence: 0.372,
        },
        spotifyID: "1XigMhsDWjz3GlY3tVIFhS",
      },
      {
        title: "Falling Apart",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2003",
        audioFeatures: {
          acousticness: 0.00328,
          danceability: 0.573,
          duration_ms: 359267,
          energy: 0.779,
          instrumentalness: 0.842,
          key: 2,
          liveness: 0.0808,
          loudness: -9.609,
          mode: 1,
          speechiness: 0.0375,
          tempo: 132.005,
          time_signature: 4,
          valence: 0.055,
        },
        spotifyID: "6hnsnyC6WCUNwkvW4mhsob",
      },
      {
        title: "Run Bobby, Run",
        artists: "",
        position: "C1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2004",
        audioFeatures: {
          acousticness: 0.00468,
          danceability: 0.722,
          duration_ms: 338827,
          energy: 0.712,
          instrumentalness: 0.858,
          key: 7,
          liveness: 0.0946,
          loudness: -9.221,
          mode: 1,
          speechiness: 0.0612,
          tempo: 128.03,
          time_signature: 4,
          valence: 0.269,
        },
        spotifyID: "6zCLQrfdHSyoVjqSx7mGuq",
      },
      {
        title: "A System Of Mirrors",
        artists: "",
        position: "C2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2005",
        audioFeatures: {
          acousticness: 0.00000841,
          danceability: 0.685,
          duration_ms: 459389,
          energy: 0.697,
          instrumentalness: 0.896,
          key: 10,
          liveness: 0.069,
          loudness: -12.305,
          mode: 0,
          speechiness: 0.0373,
          tempo: 129.999,
          time_signature: 4,
          valence: 0.148,
        },
        spotifyID: "4eWJNtBuAF8RP3hmeNr6GG",
      },
      {
        title: "A Shattered Image",
        artists: "",
        position: "C3",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2006",
        audioFeatures: {
          acousticness: 0.104,
          danceability: 0.257,
          duration_ms: 56733,
          energy: 0.181,
          instrumentalness: 0.838,
          key: 7,
          liveness: 0.123,
          loudness: -17.431,
          mode: 1,
          speechiness: 0.0376,
          tempo: 82.433,
          time_signature: 4,
          valence: 0.0393,
        },
        spotifyID: "4HMlnl5B5xMl57QSjyS8Y8",
      },
      {
        title: "Face In The Water",
        artists: "",
        position: "D1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2007",
        audioFeatures: {
          acousticness: 0.397,
          danceability: 0.795,
          duration_ms: 342564,
          energy: 0.613,
          instrumentalness: 0.832,
          key: 0,
          liveness: 0.0735,
          loudness: -13.395,
          mode: 1,
          speechiness: 0.0787,
          tempo: 125.997,
          time_signature: 4,
          valence: 0.401,
        },
        spotifyID: "3VpwwjJWxPYFak2WzUwHbY",
      },
      {
        title: "Freeze",
        artists: "",
        position: "D2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2008",
        audioFeatures: {
          acousticness: 0.247,
          danceability: 0.264,
          duration_ms: 131557,
          energy: 0.343,
          instrumentalness: 0.889,
          key: 10,
          liveness: 0.108,
          loudness: -12.377,
          mode: 0,
          speechiness: 0.0384,
          tempo: 78.974,
          time_signature: 5,
          valence: 0.0384,
        },
        spotifyID: "5TEvDqNIcmQBCE3IAfmWpT",
      },
      {
        title: "Prism",
        artists: "",
        position: "D3",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2009",
        audioFeatures: {
          acousticness: 0.0812,
          danceability: 0.696,
          duration_ms: 310293,
          energy: 0.72,
          instrumentalness: 0.887,
          key: 1,
          liveness: 0.0952,
          loudness: -12.014,
          mode: 0,
          speechiness: 0.0417,
          tempo: 126.002,
          time_signature: 4,
          valence: 0.0981,
        },
        spotifyID: "6O9eUzLFBTGEmWC6zrixLm",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543638" } },
    spotifyID: "4Lt9RcisVkCyWynK67kCQo",
    user: "1",
  },
  {
    _id: "21",
    discogsID: 11929782,
    catno: "STS327LP",
    title: "Will",
    artists: "Matt Karmil",
    label: "Smalltown Supersound",
    year: 2018,
    cover:
      "https://i.discogs.com/5fIfqXd7xFxF55EQfaM-rqep_c3MSFjZAewe-QNX3Fs/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTExOTI5/NzgyLTE1MjUxMzc2/NjEtMzkxNC5qcGVn.jpeg",
    tracks: [
      {
        title: "Sharehold",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Experimental",
        playable: true,
        _id: "2100",
        audioFeatures: {
          acousticness: 0.975,
          danceability: 0.583,
          duration_ms: 271213,
          energy: 0.0556,
          instrumentalness: 0.678,
          key: 0,
          liveness: 0.109,
          loudness: -26.649,
          mode: 0,
          speechiness: 0.036,
          tempo: 113.049,
          time_signature: 4,
          valence: 0.368,
        },
        spotifyID: "4R6Dy5wMzDK8GAXU4HTbqX",
      },
      {
        title: "Sloshy",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Experimental",
        playable: true,
        _id: "2101",
        audioFeatures: {
          acousticness: 0.359,
          danceability: 0.611,
          duration_ms: 236027,
          energy: 0.435,
          instrumentalness: 0.889,
          key: 3,
          liveness: 0.123,
          loudness: -21.322,
          mode: 1,
          speechiness: 0.0522,
          tempo: 119.996,
          time_signature: 4,
          valence: 0.0687,
        },
        spotifyID: "1oil9Ik5XtpIWjGNujMQeK",
      },
      {
        title: "Morals",
        artists: "",
        position: "A3",
        duration: null,
        rpm: 33,
        genre: "Experimental",
        playable: true,
        _id: "2102",
        audioFeatures: {
          acousticness: 0.775,
          danceability: 0.558,
          duration_ms: 308733,
          energy: 0.27,
          instrumentalness: 0.876,
          key: 9,
          liveness: 0.126,
          loudness: -24.911,
          mode: 0,
          speechiness: 0.0537,
          tempo: 116.972,
          time_signature: 4,
          valence: 0.056,
        },
        spotifyID: "4EK1ZoDrEoG1TTslHWCl5I",
      },
      {
        title: "Holiday (Interlude)",
        artists: "",
        position: "A4",
        duration: null,
        rpm: 33,
        genre: "Experimental",
        playable: true,
        _id: "2103",
        audioFeatures: {
          acousticness: 0.403,
          danceability: 0.457,
          duration_ms: 21973,
          energy: 0.0343,
          instrumentalness: 0.824,
          key: 2,
          liveness: 0.113,
          loudness: -29.911,
          mode: 1,
          speechiness: 0.0449,
          tempo: 60.387,
          time_signature: 4,
          valence: 0.381,
        },
        spotifyID: "5GyKaMYZiJHlNOEIu6CWUA",
      },
      {
        title: "NAND",
        artists: "",
        position: "A5",
        duration: null,
        rpm: 33,
        genre: "Experimental",
        playable: true,
        _id: "2104",
        audioFeatures: {
          acousticness: 0.932,
          danceability: 0.281,
          duration_ms: 479600,
          energy: 0.244,
          instrumentalness: 0.0294,
          key: 0,
          liveness: 0.132,
          loudness: -23.883,
          mode: 1,
          speechiness: 0.489,
          tempo: 123.763,
          time_signature: 3,
          valence: 0.124,
        },
        spotifyID: "7pg6B2eMtjXrzmU4nbd74s",
      },
      {
        title: "Can't Find It (The House Sound)",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Experimental",
        playable: true,
        _id: "2105",
        audioFeatures: {
          acousticness: 0.839,
          danceability: 0.688,
          duration_ms: 535547,
          energy: 0.586,
          instrumentalness: 0.883,
          key: 6,
          liveness: 0.11,
          loudness: -16.857,
          mode: 0,
          speechiness: 0.0765,
          tempo: 120.016,
          time_signature: 4,
          valence: 0.0383,
        },
        spotifyID: "4CrJ5evUyISxa77VNgtBVt",
      },
      {
        title: "Gory Hole",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Experimental",
        playable: true,
        _id: "2106",
        audioFeatures: {
          acousticness: 0.0243,
          danceability: 0.271,
          duration_ms: 106573,
          energy: 0.327,
          instrumentalness: 0.954,
          key: 6,
          liveness: 0.276,
          loudness: -22.494,
          mode: 0,
          speechiness: 0.0466,
          tempo: 114.323,
          time_signature: 4,
          valence: 0.268,
        },
        spotifyID: "5cCQc1ca3U4eBRHO3cAS7N",
      },
      {
        title: "Maffé",
        artists: "",
        position: "B3",
        duration: null,
        rpm: 33,
        genre: "Experimental",
        playable: true,
        _id: "2107",
        audioFeatures: {
          acousticness: 0.924,
          danceability: 0.332,
          duration_ms: 1027640,
          energy: 0.00908,
          instrumentalness: 0.745,
          key: 6,
          liveness: 0.0944,
          loudness: -33.046,
          mode: 1,
          speechiness: 0.0427,
          tempo: 90.102,
          time_signature: 3,
          valence: 0.146,
        },
        spotifyID: "0K1fqaTwmdibClJoz9oQB8",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543638" } },
    spotifyID: "20EUCLWdf0K5NtmYiF70EV",
    user: "1",
  },
  {
    _id: "22",
    discogsID: 10949468,
    catno: "SULTASELECTS-2",
    title: "Our World (With A Boy On Its Shoulders)",
    artists: "Denis Sulta",
    label: "Sulta Selects",
    year: 2017,
    cover:
      "https://i.discogs.com/DuhoKSLaCmRZvYH0GF4bPWFODN7_oZflOALv5RH4H4w/rs:fit/g:sm/q:90/h:262/w:262/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEwOTQ5/NDY4LTE1MDcwNDUz/MzYtMzA3OS5qcGVn.jpeg",
    tracks: [
      {
        title: "Our World (With A Boy On It's Shoulders)",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "House",
        playable: true,
        _id: "2200",
        audioFeatures: {
          acousticness: 0.000298,
          danceability: 0.769,
          duration_ms: 511191,
          energy: 0.791,
          instrumentalness: 0.236,
          key: 5,
          liveness: 0.202,
          loudness: -7.552,
          mode: 0,
          speechiness: 0.345,
          tempo: 127.958,
          time_signature: 4,
          valence: 0.56,
        },
        spotifyID: "4K9edHSD48LUXe7g3nTuGW",
      },
      {
        title: "Aur World",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "House",
        playable: true,
        _id: "2201",
        audioFeatures: {
          acousticness: 0.00714,
          danceability: 0.78,
          duration_ms: 466968,
          energy: 0.757,
          instrumentalness: 0.823,
          key: 8,
          liveness: 0.0586,
          loudness: -7.762,
          mode: 1,
          speechiness: 0.109,
          tempo: 128.018,
          time_signature: 4,
          valence: 0.579,
        },
        spotifyID: "2vM3hodpPwfNOG9Aj6RLMZ",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543638" } },
    spotifyID: "2NhzzLcP5AzTqiniG9Qtqe",
    user: "1",
  },
  {
    _id: "23",
    discogsID: 7381692,
    catno: "TX 421",
    title: "Waiting On My Angel",
    artists: "Frankie Knuckles",
    label: "Trax Records",
    year: 2015,
    cover:
      "https://i.discogs.com/o5YQai5kEMNHSLku-IwXdX_57D98DGVJop1mQItKqEQ/rs:fit/g:sm/q:90/h:592/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTczODE2/OTItMTQ1OTQ0NzMz/MS03NDMzLmpwZWc.jpeg",
    tracks: [
      {
        title: "Waiting On My Angel (Radio Mix)",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 45,
        genre: "House",
        playable: true,
        _id: "2300",
        audioFeatures: {
          acousticness: 0.176,
          danceability: 0.708,
          duration_ms: 243173,
          energy: 0.91,
          instrumentalness: 0.0431,
          key: 5,
          liveness: 0.0727,
          loudness: -3.349,
          mode: 0,
          speechiness: 0.047,
          tempo: 124.982,
          time_signature: 4,
          valence: 0.697,
        },
        spotifyID: "2GGLP061KzVzhbwG4kumMV",
      },
      {
        title: "Waiting On My Angel (Club Mix)",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 45,
        genre: "House",
        playable: true,
        _id: "2301",
        audioFeatures: {
          acousticness: 0.513,
          danceability: 0.711,
          duration_ms: 445373,
          energy: 0.507,
          instrumentalness: 0.799,
          key: 5,
          liveness: 0.099,
          loudness: -13.469,
          mode: 1,
          speechiness: 0.0423,
          tempo: 124.906,
          time_signature: 4,
          valence: 0.552,
        },
        spotifyID: "0SYyYLjuZIMAccF61vfoHE",
      },
      {
        title: "Waiting On My Angel",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 45,
        genre: "House",
        playable: true,
        _id: "2302",
        audioFeatures: {
          danceability: 0.708,
          energy: 0.91,
          key: 5,
          loudness: -3.349,
          mode: 0,
          speechiness: 0.047,
          acousticness: 0.176,
          instrumentalness: 0.0431,
          liveness: 0.0727,
          valence: 0.697,
          tempo: 124.982,
          duration_ms: 243173,
          time_signature: 4,
        },
        spotifyID: "2GGLP061KzVzhbwG4kumMV",
        key: null,
        mode: null,
        timeSignatureLower: null,
        timeSignatureUpper: null,
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543639" } },
    user: "1",
  },
  {
    _id: "24",
    discogsID: 2947655,
    catno: "V 2821",
    title: "Homework",
    artists: "Daft Punk",
    label: "Virgin",
    year: 1997,
    cover:
      "https://i.discogs.com/EQhUe7hVRtUAjPvfBH9J43eNnSTzmhqo9QjQg3MPmwo/rs:fit/g:sm/q:90/h:610/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI5NDc2/NTUtMTY3MDAwMjM2/NC01MjE4LmpwZWc.jpeg",
    tracks: [
      {
        title: "Daftendirekt",
        artists: "",
        position: "A1",
        duration: 164000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2400",
        audioFeatures: {
          acousticness: 0.00695,
          danceability: 0.883,
          duration_ms: 164560,
          energy: 0.743,
          instrumentalness: 0.796,
          key: 5,
          liveness: 0.0936,
          loudness: -8.418,
          mode: 0,
          speechiness: 0.162,
          tempo: 111.019,
          time_signature: 4,
          valence: 0.336,
        },
        spotifyID: "7fYIXtdOUl8WqXAqQGyIHQ",
      },
      {
        title: "WDPK 83.7 FM",
        artists: "Daft Punk",
        position: "A2",
        duration: 28000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2401",
        audioFeatures: {
          acousticness: 0.00492,
          danceability: 0.856,
          duration_ms: 28333,
          energy: 0.569,
          instrumentalness: 0.0353,
          key: 1,
          liveness: 0.474,
          loudness: -5.916,
          mode: 1,
          speechiness: 0.206,
          tempo: 110.943,
          time_signature: 4,
          valence: 0.919,
        },
        spotifyID: "7dTWkvPOPgbGuMk4HDxNpY",
      },
      {
        title: "Revolution 909",
        artists: "Daft Punk",
        position: "A3",
        duration: 326000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2402",
        audioFeatures: {
          acousticness: 0.0265,
          danceability: 0.745,
          duration_ms: 335027,
          energy: 0.716,
          instrumentalness: 0.846,
          key: 11,
          liveness: 0.246,
          loudness: -7.918,
          mode: 1,
          speechiness: 0.136,
          tempo: 126.527,
          time_signature: 4,
          valence: 0.828,
        },
        spotifyID: "5pgZpHqfv4TSomtkfGZGrG",
      },
      {
        title: "Da Funk",
        artists: "Daft Punk",
        position: "A4",
        duration: 328000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2403",
        audioFeatures: {
          acousticness: 0.00196,
          danceability: 0.806,
          duration_ms: 328680,
          energy: 0.7,
          instrumentalness: 0.891,
          key: 0,
          liveness: 0.267,
          loudness: -5.269,
          mode: 0,
          speechiness: 0.0752,
          tempo: 111.215,
          time_signature: 4,
          valence: 0.892,
        },
        spotifyID: "0MyY4WcN7DIfbSmp5yej5z",
      },
      {
        title: "Phœnix",
        artists: "Daft Punk",
        position: "A5",
        duration: 295000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2404",
        audioFeatures: {
          acousticness: 0.0000191,
          danceability: 0.804,
          duration_ms: 297107,
          energy: 0.719,
          instrumentalness: 0.926,
          key: 7,
          liveness: 0.0847,
          loudness: -8.207,
          mode: 1,
          speechiness: 0.0835,
          tempo: 126.874,
          time_signature: 4,
          valence: 0.569,
        },
        spotifyID: "6nQy5XEEEJKu8FE1FS2Wbt",
      },
      {
        title: "Fresh",
        artists: "Daft Punk",
        position: "B6",
        duration: 243000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2405",
        audioFeatures: {
          acousticness: 0.268,
          danceability: 0.503,
          duration_ms: 244400,
          energy: 0.952,
          instrumentalness: 0.272,
          key: 1,
          liveness: 0.875,
          loudness: -9.308,
          mode: 1,
          speechiness: 0.572,
          tempo: 126.345,
          time_signature: 4,
          valence: 0.174,
        },
        spotifyID: "78H72MElkOY9cRnaudxZFY",
      },
      {
        title: "Around The World",
        artists: "Daft Punk",
        position: "B7",
        duration: 427000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2406",
        audioFeatures: {
          acousticness: 0.00356,
          danceability: 0.956,
          duration_ms: 429533,
          energy: 0.795,
          instrumentalness: 0.889,
          key: 7,
          liveness: 0.0906,
          loudness: -5.311,
          mode: 1,
          speechiness: 0.15,
          tempo: 121.294,
          time_signature: 4,
          valence: 0.841,
        },
        spotifyID: "1pKYYY0dkg23sQQXi0Q5zN",
      },
      {
        title: "Rollin' & Scratchin'",
        artists: "Daft Punk",
        position: "B8",
        duration: 446000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2407",
        audioFeatures: {
          acousticness: 0.00108,
          danceability: 0.822,
          duration_ms: 448627,
          energy: 0.791,
          instrumentalness: 0.621,
          key: 1,
          liveness: 0.0567,
          loudness: -4.87,
          mode: 1,
          speechiness: 0.0414,
          tempo: 130.059,
          time_signature: 4,
          valence: 0.21,
        },
        spotifyID: "6hgBJPTHoDJyxydmtFWHKf",
      },
      {
        title: "Teachers",
        artists: "Daft Punk",
        position: "C9",
        duration: 172000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2408",
        audioFeatures: {
          acousticness: 0.03,
          danceability: 0.98,
          duration_ms: 173493,
          energy: 0.486,
          instrumentalness: 0.823,
          key: 6,
          liveness: 0.0995,
          loudness: -7.31,
          mode: 1,
          speechiness: 0.251,
          tempo: 123.206,
          time_signature: 4,
          valence: 0.823,
        },
        spotifyID: "4ZdjCuiWBv2weLftWPJ72c",
      },
      {
        title: "High Fidelity",
        artists: "Daft Punk",
        position: "C10",
        duration: 360000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2409",
        audioFeatures: {
          acousticness: 0.000996,
          danceability: 0.799,
          duration_ms: 362467,
          energy: 0.66,
          instrumentalness: 0.446,
          key: 11,
          liveness: 0.313,
          loudness: -8.829,
          mode: 0,
          speechiness: 0.175,
          tempo: 125.909,
          time_signature: 4,
          valence: 0.391,
        },
        spotifyID: "63ZAmhkAV6Kaw955ohbEsN",
      },
      {
        title: "Rock'n Roll",
        artists: "Daft Punk",
        position: "C11",
        duration: 452000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2410",
        audioFeatures: {
          acousticness: 0.0171,
          danceability: 0.955,
          duration_ms: 453867,
          energy: 0.899,
          instrumentalness: 0.64,
          key: 1,
          liveness: 0.0642,
          loudness: -7.599,
          mode: 1,
          speechiness: 0.273,
          tempo: 134.941,
          time_signature: 4,
          valence: 0.331,
        },
        spotifyID: "1nluqUxDKnwWyScYVeQHXa",
      },
      {
        title: "Oh Yeah",
        artists: "DJ Crabbe, DJ Deelat, Daft Punk",
        position: "C12",
        duration: 120000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2411",
        audioFeatures: {
          acousticness: 0.0706,
          danceability: 0.602,
          duration_ms: 121200,
          energy: 0.309,
          instrumentalness: 0.294,
          key: 8,
          liveness: 0.0895,
          loudness: -8.396,
          mode: 1,
          speechiness: 0.352,
          tempo: 176.704,
          time_signature: 5,
          valence: 0.559,
        },
        spotifyID: "3Pm4jgrXCu0W7gTqyrlR4F",
      },
      {
        title: "Burnin'",
        artists: "Daft Punk",
        position: "D13",
        duration: 413000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2412",
        audioFeatures: {
          acousticness: 0.192,
          danceability: 0.87,
          duration_ms: 414173,
          energy: 0.875,
          instrumentalness: 0.357,
          key: 6,
          liveness: 0.0804,
          loudness: -8.501,
          mode: 0,
          speechiness: 0.205,
          tempo: 124.298,
          time_signature: 4,
          valence: 0.715,
        },
        spotifyID: "6nSOhDfZOulWp0pnEHFrnn",
      },
      {
        title: "Indo Silver Club",
        artists: "Karen Young, Daft Punk",
        position: "D14",
        duration: 272000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2413",
        audioFeatures: {
          acousticness: 0.0888,
          danceability: 0.886,
          duration_ms: 274733,
          energy: 0.847,
          instrumentalness: 0.937,
          key: 11,
          liveness: 0.087,
          loudness: -8.296,
          mode: 1,
          speechiness: 0.1,
          tempo: 126.163,
          time_signature: 4,
          valence: 0.443,
        },
        spotifyID: "4jF8GAMRCOenL17cNnBDI0",
      },
      {
        title: "Alive",
        artists: "Daft Punk",
        position: "D15",
        duration: 315000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2414",
        audioFeatures: {
          acousticness: 0.0000542,
          danceability: 0.723,
          duration_ms: 316253,
          energy: 0.716,
          instrumentalness: 0.905,
          key: 3,
          liveness: 0.072,
          loudness: -6.407,
          mode: 0,
          speechiness: 0.0437,
          tempo: 128.701,
          time_signature: 4,
          valence: 0.0748,
        },
        spotifyID: "1PH5Es89c1cENU8WVuWwbp",
      },
      {
        title: "Funk Ad",
        artists: "Daft Punk",
        position: "D16",
        duration: 50000,
        rpm: 33,
        genre: "House, Techno, Electro, Disco",
        playable: true,
        _id: "2415",
        audioFeatures: {
          acousticness: 0.00021,
          danceability: 0.724,
          duration_ms: 51400,
          energy: 0.783,
          instrumentalness: 0.49,
          key: 7,
          liveness: 0.295,
          loudness: -8.958,
          mode: 1,
          speechiness: 0.176,
          tempo: 111.833,
          time_signature: 4,
          valence: 0.3,
        },
        spotifyID: "0cjavaamK4gxP9iZYw5I2X",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543639" } },
    spotifyID: "5uRdvUR7xCnHmUW8n64n9y",
    user: "1",
  },
  {
    _id: "25",
    discogsID: 5543867,
    catno: "YT108",
    title: "Girl / Sleep Sound",
    artists: "Jamie xx",
    label: "Young Turks",
    year: 2014,
    cover:
      "https://i.discogs.com/ToRYF8OUIWxJ7I-y_xvVxeqgByX1SFCTvV0CwYO7guo/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTU1NDM4/NjctMTQwMTA5MDcw/Ny01MzI3LmpwZWc.jpeg",
    tracks: [
      {
        title: "Girl",
        artists:
          "Arthur Baker, Brian Wilson, Erlend Øye, John Robie, Marcin Öz, Rasmus Hägg, Sebastian Maschat",
        position: "A1",
        duration: null,
        rpm: 45,
        genre: "Bass Music",
        playable: true,
        _id: "2500",
        audioFeatures: {
          acousticness: 0.188,
          danceability: 0.359,
          duration_ms: 241505,
          energy: 0.602,
          instrumentalness: 0.551,
          key: 8,
          liveness: 0.728,
          loudness: -11.595,
          mode: 0,
          speechiness: 0.376,
          tempo: 101.601,
          time_signature: 4,
          valence: 0.451,
        },
        spotifyID: "7o1s0OJBJj2PvBTVNBMM3P",
      },
      {
        title: "Sleep Sound",
        artists:
          "Alicia Augello Cook, Chet Forrest, Kerry Brothers, Robert Craig Wright",
        position: "A2",
        duration: null,
        rpm: 45,
        genre: "Bass Music",
        playable: true,
        _id: "2501",
        audioFeatures: {
          acousticness: 0.352,
          danceability: 0.759,
          duration_ms: 383310,
          energy: 0.536,
          instrumentalness: 0.913,
          key: 5,
          liveness: 0.0847,
          loudness: -11.7,
          mode: 0,
          speechiness: 0.0626,
          tempo: 118.109,
          time_signature: 4,
          valence: 0.147,
        },
        spotifyID: "4aNm6woQfV9XRkCmk51GSX",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543639" } },
    user: "1",
  },
  {
    _id: "26",
    discogsID: 12294503,
    catno: "TRP021",
    title: "Oli Gumm",
    artists: "Bjarki",
    label: "трип",
    year: 2018,
    cover:
      "https://i.discogs.com/zMXm5l5Oo2w9n5f3ezjGyS42EJc61_OkEVeklioIVQQ/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEyMjk0/NTAzLTE2MDMzMDY1/OTktNjI3Ni5qcGVn.jpeg",
    tracks: [
      {
        title: "Oli Gumm 2-2",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Abstract, Ambient, Techno",
        playable: true,
        _id: "2600",
        audioFeatures: {
          acousticness: 0.0931,
          danceability: 0.627,
          duration_ms: 336898,
          energy: 0.79,
          instrumentalness: 0.912,
          key: 11,
          liveness: 0.152,
          loudness: -10.663,
          mode: 0,
          speechiness: 0.0773,
          tempo: 146.987,
          time_signature: 4,
          valence: 0.147,
        },
        spotifyID: "4lvQiCOgOE3EOsaaQEUjTC",
      },
      {
        title: "Fork2-2",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Abstract, Ambient, Techno",
        playable: true,
        _id: "2601",
        audioFeatures: {
          acousticness: 0.428,
          danceability: 0.795,
          duration_ms: 313889,
          energy: 0.215,
          instrumentalness: 0.932,
          key: 11,
          liveness: 0.112,
          loudness: -15.711,
          mode: 0,
          speechiness: 0.0333,
          tempo: 133.013,
          time_signature: 4,
          valence: 0.0377,
        },
        spotifyID: "68Gu2wDAHeSGZ7AFN0CSey",
      },
      {
        title: "7 Filakaramellur Lion Bar",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Abstract, Ambient, Techno",
        playable: true,
        _id: "2602",
        audioFeatures: {
          acousticness: 0.00256,
          danceability: 0.574,
          duration_ms: 296794,
          energy: 0.615,
          instrumentalness: 0.57,
          key: 8,
          liveness: 0.479,
          loudness: -9.809,
          mode: 1,
          speechiness: 0.0311,
          tempo: 130.997,
          time_signature: 4,
          valence: 0.136,
        },
        spotifyID: "3v229sU3XWAtrKBijOYpxx",
      },
      {
        title: "Hatann Satann",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Abstract, Ambient, Techno",
        playable: true,
        _id: "2603",
        audioFeatures: {
          acousticness: 0.0000955,
          danceability: 0.707,
          duration_ms: 280019,
          energy: 0.553,
          instrumentalness: 0.325,
          key: 1,
          liveness: 0.111,
          loudness: -10.699,
          mode: 1,
          speechiness: 0.0849,
          tempo: 144.023,
          time_signature: 4,
          valence: 0.0791,
        },
        spotifyID: "7bGuyFJsxFurzrd9W5LvZg",
      },
      {
        title: "Sleikjó",
        artists: "",
        position: "B3",
        duration: null,
        rpm: 33,
        genre: "Abstract, Ambient, Techno",
        playable: true,
        _id: "2604",
        audioFeatures: {
          acousticness: 0.0135,
          danceability: 0.729,
          duration_ms: 259808,
          energy: 0.563,
          instrumentalness: 0.894,
          key: 1,
          liveness: 0.12,
          loudness: -11.498,
          mode: 1,
          speechiness: 0.0491,
          tempo: 137.014,
          time_signature: 4,
          valence: 0.315,
        },
        spotifyID: "7AqdrVDD4KObPaWEeZE6Gv",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543639" } },
    spotifyID: "4HCjjJT3XH4UGaVKKphrI4",
    user: "1",
  },
  {
    _id: "27",
    discogsID: 13915901,
    catno: "TRP025",
    title: "Stranno Neobjatno",
    artists: "Nina Kraviz",
    label: "трип",
    year: 2019,
    cover:
      "https://i.discogs.com/CCzOwKZFripD2ND5rzAIlRpBpFL2FgcHXXa80rLkGB8/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEzOTE1/OTAxLTE2MDMzNTMx/NTItNjE5Mi5qcGVn.jpeg",
    tracks: [
      {
        title: "Dream Machine",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 45,
        genre: "Techno",
        playable: true,
        _id: "2700",
        audioFeatures: {
          acousticness: 0.0121,
          danceability: 0.869,
          duration_ms: 388471,
          energy: 0.488,
          instrumentalness: 0.909,
          key: 1,
          liveness: 0.121,
          loudness: -10.858,
          mode: 1,
          speechiness: 0.0379,
          tempo: 121.512,
          time_signature: 4,
          valence: 0.141,
        },
        spotifyID: "4cAypK013iEiqKZG5YLrJ3",
      },
      {
        title: "I Want You",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 45,
        genre: "Techno",
        playable: true,
        _id: "2701",
        audioFeatures: {
          acousticness: 0.00152,
          danceability: 0.793,
          duration_ms: 376425,
          energy: 0.76,
          instrumentalness: 0.742,
          key: 1,
          liveness: 0.0965,
          loudness: -8.949,
          mode: 1,
          speechiness: 0.0467,
          tempo: 135.009,
          time_signature: 4,
          valence: 0.779,
        },
        spotifyID: "2ZMt0T17rlrfbzs3ENJWgY",
      },
      {
        title: "Stranno Neobjatno",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 45,
        genre: "Techno",
        playable: true,
        _id: "2702",
        audioFeatures: {
          acousticness: 0.465,
          danceability: 0.688,
          duration_ms: 452864,
          energy: 0.344,
          instrumentalness: 0.921,
          key: 7,
          liveness: 0.105,
          loudness: -15.63,
          mode: 1,
          speechiness: 0.045,
          tempo: 138.969,
          time_signature: 4,
          valence: 0.547,
        },
        spotifyID: "03reLixSDUCgMDDUrQBPeP",
      },
    ],
    createdAt: { $date: { $numberLong: "1671003543640" } },
    user: "1",
  },
  {
    _id: "28",
    discogsID: 15135273,
    catno: "BM 4",
    title: "Bad Manners 4",
    artists: "Vril",
    label: "Bad Manners Records",
    year: 2020,
    cover:
      "https://i.discogs.com/EK9YQ64McVUEVSaIC8nhaaEcBe41fUnyLfI9nO4NO2U/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE1MTM1/MjczLTE1ODcxMzkz/ODctMjUwMS5qcGVn.jpeg",
    tracks: [
      {
        title: "Scalar",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2800",
        audioFeatures: {
          acousticness: 0.000479,
          danceability: 0.636,
          duration_ms: 502526,
          energy: 0.904,
          instrumentalness: 0.814,
          key: 6,
          liveness: 0.806,
          loudness: -9.402,
          mode: 0,
          speechiness: 0.0428,
          tempo: 128.007,
          time_signature: 4,
          valence: 0.101,
        },
        spotifyID: "1mB25QcfqfEtTPD3B8O67Y",
      },
      {
        title: "Biohak",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2801",
        audioFeatures: {
          acousticness: 0.00000404,
          danceability: 0.546,
          duration_ms: 418911,
          energy: 0.938,
          instrumentalness: 0.951,
          key: 1,
          liveness: 0.0794,
          loudness: -7.72,
          mode: 1,
          speechiness: 0.051,
          tempo: 128.018,
          time_signature: 4,
          valence: 0.12,
        },
        spotifyID: "6H0FoQZnKZgAeeFBt0l3SK",
      },
      {
        title: "Verkunstungstraktat",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2802",
        audioFeatures: {
          acousticness: 0.00793,
          danceability: 0.708,
          duration_ms: 361901,
          energy: 0.973,
          instrumentalness: 0.894,
          key: 4,
          liveness: 0.313,
          loudness: -8.579,
          mode: 1,
          speechiness: 0.0654,
          tempo: 129.966,
          time_signature: 4,
          valence: 0.363,
        },
        spotifyID: "0eYLX4XpBbnrWhgOOI8Lfs",
      },
      {
        title: "Nosode",
        artists: "",
        position: "C1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2803",
        audioFeatures: {
          acousticness: 0.000198,
          danceability: 0.652,
          duration_ms: 369684,
          energy: 0.871,
          instrumentalness: 0.821,
          key: 2,
          liveness: 0.0697,
          loudness: -8.16,
          mode: 0,
          speechiness: 0.0652,
          tempo: 127.018,
          time_signature: 4,
          valence: 0.104,
        },
        spotifyID: "0fVyejEyU7XKFpuPeVDzNh",
      },
      {
        title: "Kruqz",
        artists: "",
        position: "C2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2804",
        audioFeatures: {
          acousticness: 0.00111,
          danceability: 0.664,
          duration_ms: 367247,
          energy: 0.84,
          instrumentalness: 0.843,
          key: 8,
          liveness: 0.41,
          loudness: -7.512,
          mode: 1,
          speechiness: 0.0938,
          tempo: 110.997,
          time_signature: 4,
          valence: 0.113,
        },
        spotifyID: "5jrweT9mUhxfdP0yxZJRxc",
      },
      {
        title: "Psionik",
        artists: "",
        position: "D1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2805",
        audioFeatures: {
          acousticness: 0.623,
          danceability: 0.573,
          duration_ms: 499666,
          energy: 0.653,
          instrumentalness: 0.862,
          key: 10,
          liveness: 0.102,
          loudness: -13.62,
          mode: 0,
          speechiness: 0.0427,
          tempo: 123.011,
          time_signature: 4,
          valence: 0.0331,
        },
        spotifyID: "4eroadvOFB1ckVWBSU7qIm",
      },
      {
        title: "Free World Order",
        artists: "",
        position: "D2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "2806",
        audioFeatures: {
          acousticness: 0.0113,
          danceability: 0.736,
          duration_ms: 332030,
          energy: 0.896,
          instrumentalness: 0.851,
          key: 6,
          liveness: 0.404,
          loudness: -10.088,
          mode: 0,
          speechiness: 0.0561,
          tempo: 132.992,
          time_signature: 4,
          valence: 0.338,
        },
        spotifyID: "4y3rSsXESu7LR9Ir1fFKvI",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658834" } },
    spotifyID: "7KXPfxFFeyzYLqxPqvUtlE",
    user: "1",
  },
  {
    _id: "29",
    discogsID: 8509067,
    catno: "BCR050T",
    title: "50/50 E.P",
    artists: "Mr. G",
    label: "Bass Culture Records",
    year: 2016,
    cover:
      "https://i.discogs.com/VSkmG491c_zNgP5nADZSPlKwPuQh8YEI0H0ewKyGCKo/rs:fit/g:sm/q:90/h:606/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTg1MDkw/NjctMTQ2MzA4MDAw/Ny0zODczLnBuZw.jpeg",
    tracks: [
      {
        title: "Transient",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "House, Techno, Tech House",
        playable: true,
        _id: "2900",
        audioFeatures: {
          acousticness: 0.00181,
          danceability: 0.788,
          duration_ms: 547297,
          energy: 0.463,
          instrumentalness: 0.82,
          key: 11,
          liveness: 0.105,
          loudness: -14.448,
          mode: 1,
          speechiness: 0.0549,
          tempo: 125.99,
          time_signature: 4,
          valence: 0.372,
        },
        spotifyID: "4OdyI2KYWKbaS0WBOX6beN",
      },
      {
        title: "Crumple Zone",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "House, Techno, Tech House",
        playable: true,
        _id: "2901",
        audioFeatures: {
          acousticness: 0.0594,
          danceability: 0.705,
          duration_ms: 415375,
          energy: 0.397,
          instrumentalness: 0.625,
          key: 11,
          liveness: 0.114,
          loudness: -14.954,
          mode: 0,
          speechiness: 0.0453,
          tempo: 127.986,
          time_signature: 4,
          valence: 0.218,
        },
        spotifyID: "5q2gokF76M7qhQ8hy8B6cu",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658834" } },
    user: "1",
  },
  {
    _id: "30",
    discogsID: 3496059,
    catno: "CRF 003",
    title: "Unforeseen Alliances 1/3",
    artists: "Voiski, Deception Plan",
    label: "Construct Re-Form",
    year: 2012,
    cover:
      "https://i.discogs.com/dyxi6e3Ws1BM3QrmBr3yaUCBB1Rabv_ZR8Ek6sFix2I/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTM0OTYw/NTktMTY0NjI0MDk5/MC0zNjQ3LmpwZWc.jpeg",
    tracks: [
      {
        title: "Ad Infinitum",
        artists: "Voiski",
        position: "A1",
        duration: null,
        rpm: 45,
        genre: "Techno",
        playable: true,
        _id: "3000",
        audioFeatures: {
          acousticness: 0.000165,
          danceability: 0.79,
          duration_ms: 423000,
          energy: 0.666,
          instrumentalness: 0.904,
          key: 10,
          liveness: 0.0941,
          loudness: -8.292,
          mode: 0,
          speechiness: 0.0579,
          tempo: 125.007,
          time_signature: 4,
          valence: 0.038,
        },
        spotifyID: "0UuZTnvOnq5FnRCuhxQfaF",
      },
      {
        title: "Plan 0 (Maskirovka)",
        artists: "Deception Plan",
        position: "B1",
        duration: null,
        rpm: 45,
        genre: "Techno",
        playable: true,
        _id: "3001",
        audioFeatures: {
          acousticness: 0.315,
          danceability: 0.8,
          duration_ms: 414733,
          energy: 0.908,
          instrumentalness: 0.916,
          key: 6,
          liveness: 0.112,
          loudness: -11.021,
          mode: 0,
          speechiness: 0.0563,
          tempo: 125.016,
          time_signature: 4,
          valence: 0.0743,
        },
        spotifyID: "6xxkPjPzMy6lLPPbeCQ1Mp",
      },
      {
        title: "Plan 0 (False Flag)",
        artists: "Deception Plan",
        position: "B2",
        duration: null,
        rpm: 45,
        genre: "Techno",
        playable: true,
        _id: "3002",
        audioFeatures: {
          acousticness: 0.376,
          danceability: 0.895,
          duration_ms: 355213,
          energy: 0.67,
          instrumentalness: 0.865,
          key: 1,
          liveness: 0.111,
          loudness: -13.763,
          mode: 0,
          speechiness: 0.0422,
          tempo: 125.016,
          time_signature: 4,
          valence: 0.0407,
        },
        spotifyID: "0ji6f5dGATCsoYtE6JxOOG",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658834" } },
    spotifyID: "4iviUpLY2GjITKhEPuxUsM",
    user: "1",
  },
  {
    _id: "31",
    discogsID: 12654855,
    catno: "132dsr",
    title: "Anima Mundi",
    artists: "Vril",
    label: "Delsin",
    year: 2018,
    cover:
      "https://i.discogs.com/wrIRxPO-b-uJ9wUjZVO6tCUS-YmvbgE4rPOiM0phPEo/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEyNjU0/ODU1LTE1Mzk0MzM4/NzEtNjM0Mi5qcGVn.jpeg",
    tracks: [
      {
        title: "Manium",
        artists: "",
        position: "A1",
        duration: 285000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3100",
        audioFeatures: {
          acousticness: 0.101,
          danceability: 0.125,
          duration_ms: 285022,
          energy: 0.0974,
          instrumentalness: 0.809,
          key: 2,
          liveness: 0.0739,
          loudness: -23.161,
          mode: 0,
          speechiness: 0.0384,
          tempo: 97.815,
          time_signature: 1,
          valence: 0.0313,
        },
        spotifyID: "6GeSp1xUWQPgFBagPMGaAr",
      },
      {
        title: "Statera Rerum",
        artists: "",
        position: "A2",
        duration: 487000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3101",
        audioFeatures: {
          acousticness: 0.346,
          danceability: 0.508,
          duration_ms: 486974,
          energy: 0.341,
          instrumentalness: 0.876,
          key: 10,
          liveness: 0.112,
          loudness: -19.681,
          mode: 0,
          speechiness: 0.062,
          tempo: 109.952,
          time_signature: 4,
          valence: 0.13,
        },
        spotifyID: "7mWERi6gyXvxfYxrb0exlm",
      },
      {
        title: "Anima Mundi",
        artists: "",
        position: "A3",
        duration: 250000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3102",
        audioFeatures: {
          acousticness: 0.000258,
          danceability: 0.235,
          duration_ms: 250174,
          energy: 0.322,
          instrumentalness: 0.829,
          key: 9,
          liveness: 0.127,
          loudness: -20.328,
          mode: 0,
          speechiness: 0.134,
          tempo: 104.918,
          time_signature: 3,
          valence: 0.128,
        },
        spotifyID: "2Pr6KQeF2rpuubAqelNmt8",
      },
      {
        title: "Riese (Rework)",
        artists: "",
        position: "B1",
        duration: 282000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3103",
        audioFeatures: {
          acousticness: 0.00419,
          danceability: 0.182,
          duration_ms: 281455,
          energy: 0.222,
          instrumentalness: 0.847,
          key: 1,
          liveness: 0.0946,
          loudness: -15.819,
          mode: 1,
          speechiness: 0.0346,
          tempo: 100.312,
          time_signature: 3,
          valence: 0.0527,
        },
        spotifyID: "2PG9jnXW78RMNBOUHahvNS",
      },
      {
        title: "Infinitum Eternis Anime",
        artists: "",
        position: "B2",
        duration: 420000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3104",
        audioFeatures: {
          acousticness: 0.49,
          danceability: 0.592,
          duration_ms: 420254,
          energy: 0.518,
          instrumentalness: 0.925,
          key: 1,
          liveness: 0.33,
          loudness: -16.328,
          mode: 0,
          speechiness: 0.0533,
          tempo: 118.419,
          time_signature: 4,
          valence: 0.0861,
        },
        spotifyID: "5i39UY35UedtvmZWa9kYOG",
      },
      {
        title: "Haus (Rework)",
        artists: "",
        position: "C1",
        duration: 405000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3105",
        audioFeatures: {
          acousticness: 0.106,
          danceability: 0.518,
          duration_ms: 404430,
          energy: 0.469,
          instrumentalness: 0.809,
          key: 11,
          liveness: 0.37,
          loudness: -16.61,
          mode: 0,
          speechiness: 0.0459,
          tempo: 115.012,
          time_signature: 4,
          valence: 0.257,
        },
        spotifyID: "4ZeT54Dc34PNya05wj2BKB",
      },
      {
        title: "Ilojim",
        artists: "",
        position: "C2",
        duration: 479000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3106",
        audioFeatures: {
          acousticness: 0.574,
          danceability: 0.169,
          duration_ms: 479320,
          energy: 0.108,
          instrumentalness: 0.804,
          key: 8,
          liveness: 0.109,
          loudness: -27.963,
          mode: 0,
          speechiness: 0.0392,
          tempo: 113.046,
          time_signature: 4,
          valence: 0.0499,
        },
        spotifyID: "496jzWzxoNkxtARnlRv0lO",
      },
      {
        title: "In Via",
        artists: "",
        position: "D1",
        duration: 383000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3107",
        audioFeatures: {
          acousticness: 0.73,
          danceability: 0.3,
          duration_ms: 382435,
          energy: 0.352,
          instrumentalness: 0.946,
          key: 11,
          liveness: 0.13,
          loudness: -21.475,
          mode: 0,
          speechiness: 0.037,
          tempo: 120.055,
          time_signature: 3,
          valence: 0.0645,
        },
        spotifyID: "76VApg8O41NFKgSFu2wQF9",
      },
      {
        title: "Spes",
        artists: "",
        position: "D2",
        duration: 463000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3108",
        audioFeatures: {
          acousticness: 0.1,
          danceability: 0.162,
          duration_ms: 462524,
          energy: 0.627,
          instrumentalness: 0.951,
          key: 11,
          liveness: 0.119,
          loudness: -18.689,
          mode: 0,
          speechiness: 0.0471,
          tempo: 99.998,
          time_signature: 4,
          valence: 0.0316,
        },
        spotifyID: "1SqTt42PGYD4LYyDRBk5gv",
      },
      {
        title: "Sine Fine",
        artists: "",
        position: "E1",
        duration: 385000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3109",
        audioFeatures: {
          acousticness: 0.405,
          danceability: 0.542,
          duration_ms: 385044,
          energy: 0.449,
          instrumentalness: 0.805,
          key: 11,
          liveness: 0.11,
          loudness: -18.007,
          mode: 1,
          speechiness: 0.0416,
          tempo: 115.052,
          time_signature: 4,
          valence: 0.195,
        },
        spotifyID: "6xe8gfDs8dCcRPLQBNzldH",
      },
      {
        title: "Iustus Est",
        artists: "",
        position: "E2",
        duration: 342000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3110",
        audioFeatures: {
          acousticness: 0.367,
          danceability: 0.529,
          duration_ms: 341890,
          energy: 0.405,
          instrumentalness: 0.925,
          key: 2,
          liveness: 0.109,
          loudness: -20.736,
          mode: 1,
          speechiness: 0.0359,
          tempo: 104.964,
          time_signature: 4,
          valence: 0.0709,
        },
        spotifyID: "5NNPon8EDzfN5Ug6KGrBnO",
      },
      {
        title: "Eos",
        artists: "",
        position: "F1",
        duration: 377000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3111",
        audioFeatures: {
          acousticness: 0.303,
          danceability: 0.394,
          duration_ms: 377260,
          energy: 0.715,
          instrumentalness: 0.943,
          key: 2,
          liveness: 0.104,
          loudness: -17.423,
          mode: 1,
          speechiness: 0.0398,
          tempo: 193.969,
          time_signature: 4,
          valence: 0.378,
        },
        spotifyID: "3DyU33eKtKrcSQMj3ovtd8",
      },
      {
        title: "Longius Astrum",
        artists: "",
        position: "F2",
        duration: 237000,
        rpm: 33,
        genre: "Techno, Dub Techno, Ambient",
        playable: true,
        _id: "3112",
        audioFeatures: {
          acousticness: 0.477,
          danceability: 0.325,
          duration_ms: 237400,
          energy: 0.309,
          instrumentalness: 0.842,
          key: 1,
          liveness: 0.335,
          loudness: -29.152,
          mode: 1,
          speechiness: 0.0568,
          tempo: 99.613,
          time_signature: 4,
          valence: 0.154,
        },
        spotifyID: "6Y2AEmXzMvmVqIZRK5rNrs",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658834" } },
    spotifyID: "0NlcjOxjeSaeFoiCUk1pM5",
    user: "1",
  },
  {
    _id: "32",
    discogsID: 3620920,
    catno: "DOGLP02",
    title: "The Haunted House Of House",
    artists: "Session Victim",
    label: "Delusions Of Grandeur",
    year: 2012,
    cover:
      "https://i.discogs.com/DUN4RbWm7DM2DBRxnhyoTwra3dHvRk2MnRlEoCSy5qg/rs:fit/g:sm/q:90/h:607/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTM2MjA5/MjAtMTMzNzY4OTg1/OC0zNzg0LmpwZWc.jpeg",
    tracks: [
      {
        title: "The Haunted House",
        artists: "Tornado Wallace",
        position: "A1",
        duration: 316000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "3200",
        audioFeatures: {
          acousticness: 0.0458,
          danceability: 0.665,
          duration_ms: 391280,
          energy: 0.763,
          instrumentalness: 0.777,
          key: 10,
          liveness: 0.109,
          loudness: -10.144,
          mode: 0,
          speechiness: 0.0631,
          tempo: 122.033,
          time_signature: 4,
          valence: 0.634,
        },
        spotifyID: "7H0OGMGItt55qlc8wojmfp",
      },
      {
        title: "Alpine Glow",
        artists: "",
        position: "A2",
        duration: 207000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "3201",
        audioFeatures: {
          acousticness: 0.0165,
          danceability: 0.817,
          duration_ms: 205107,
          energy: 0.711,
          instrumentalness: 0.831,
          key: 10,
          liveness: 0.29,
          loudness: -9.879,
          mode: 0,
          speechiness: 0.0866,
          tempo: 108.989,
          time_signature: 4,
          valence: 0.533,
        },
        spotifyID: "3592r0pzOzCeXJ5xSB8KEz",
      },
      {
        title: "Dark Sienna",
        artists: "",
        position: "B1",
        duration: 352000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "3202",
        audioFeatures: {
          acousticness: 0.26,
          danceability: 0.51,
          duration_ms: 321347,
          energy: 0.493,
          instrumentalness: 0.788,
          key: 6,
          liveness: 0.0749,
          loudness: -11.735,
          mode: 1,
          speechiness: 0.0582,
          tempo: 110.991,
          time_signature: 4,
          valence: 0.459,
        },
        spotifyID: "5V9wY1AugzM36GDJtB3sWV",
      },
      {
        title: "Flying Visit",
        artists: "",
        position: "B2",
        duration: 249000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "3203",
        audioFeatures: {
          acousticness: 0.0168,
          danceability: 0.76,
          duration_ms: 241667,
          energy: 0.404,
          instrumentalness: 0.756,
          key: 9,
          liveness: 0.061,
          loudness: -12.904,
          mode: 1,
          speechiness: 0.044,
          tempo: 106.016,
          time_signature: 4,
          valence: 0.612,
        },
        spotifyID: "3FUIqf4X9lCSPTL8p9gETk",
      },
      {
        title: "Cow Palace",
        artists: "",
        position: "C1",
        duration: 344000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "3204",
        audioFeatures: {
          acousticness: 0.0692,
          danceability: 0.661,
          duration_ms: 346640,
          energy: 0.463,
          instrumentalness: 0.899,
          key: 11,
          liveness: 0.124,
          loudness: -15.337,
          mode: 1,
          speechiness: 0.0372,
          tempo: 118.01,
          time_signature: 4,
          valence: 0.0995,
        },
        spotifyID: "3KOJFD2qK8pvcXMstA8Ss4",
      },
      {
        title: "Bison",
        artists: "Quarion",
        position: "C2",
        duration: 279000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "3205",
        audioFeatures: {
          acousticness: 0.0938,
          danceability: 0.858,
          duration_ms: 237520,
          energy: 0.366,
          instrumentalness: 0.552,
          key: 6,
          liveness: 0.0881,
          loudness: -13.268,
          mode: 0,
          speechiness: 0.221,
          tempo: 103.421,
          time_signature: 4,
          valence: 0.828,
        },
        spotifyID: "7uLs7KnACby3efgbFL4iiw",
      },
      {
        title: "Push Comes To Shove",
        artists: "",
        position: "D1",
        duration: 367000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "3206",
        audioFeatures: {
          acousticness: 0.0000256,
          danceability: 0.787,
          duration_ms: 350053,
          energy: 0.456,
          instrumentalness: 0.892,
          key: 1,
          liveness: 0.133,
          loudness: -11.88,
          mode: 1,
          speechiness: 0.0495,
          tempo: 120.648,
          time_signature: 4,
          valence: 0.646,
        },
        spotifyID: "3yZcSxxWQWb1Hcl7zh4Pdl",
      },
      {
        title: "F.I.N.E.",
        artists: "Grand Agent",
        position: "D2",
        duration: 142000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "3207",
        audioFeatures: {
          acousticness: 0.0898,
          danceability: 0.844,
          duration_ms: 181533,
          energy: 0.419,
          instrumentalness: 0.695,
          key: 0,
          liveness: 0.0871,
          loudness: -14.034,
          mode: 1,
          speechiness: 0.136,
          tempo: 109.954,
          time_signature: 4,
          valence: 0.22,
        },
        spotifyID: "7Jj4khi5qR104K8aMwJZyn",
      },
      {
        title: "Zoinks",
        artists: "",
        position: "E1",
        duration: 403000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "3208",
        audioFeatures: {
          acousticness: 0.0957,
          danceability: 0.791,
          duration_ms: 344733,
          energy: 0.498,
          instrumentalness: 0.842,
          key: 1,
          liveness: 0.589,
          loudness: -11.115,
          mode: 0,
          speechiness: 0.0735,
          tempo: 116.995,
          time_signature: 4,
          valence: 0.802,
        },
        spotifyID: "4CP5oNhQmGCPGCMYdzoS0k",
      },
      {
        title: "Good Intentions (Album Mix)",
        artists: "",
        position: "E2",
        duration: 279000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "3209",
      },
      {
        title: "Light Scent Of Decay",
        artists: "Linnart Ebel",
        position: "F1",
        duration: 581000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "3210",
        audioFeatures: {
          acousticness: 0.746,
          danceability: 0.665,
          duration_ms: 581933,
          energy: 0.555,
          instrumentalness: 0.9,
          key: 10,
          liveness: 0.1,
          loudness: -16.663,
          mode: 0,
          speechiness: 0.045,
          tempo: 115.982,
          time_signature: 4,
          valence: 0.372,
        },
        spotifyID: "44aTQzofxWWfPnm40RAgFb",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    spotifyID: "2F0NkwRHdlAjnMIiYbjF1n",
    user: "1",
  },
  {
    _id: "33",
    discogsID: 2612822,
    catno: "FW016",
    title: "MDSR",
    artists: "Sascha Rydell, Mike Dehnert",
    label: "Fachwerk",
    year: 2010,
    cover:
      "https://i.discogs.com/U3EoWAicwi7rMlUMSPGPwh-REw2X6rCP3IfdTqP7k5k/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI2MTI4/MjItMTYwMTExOTQz/My05Nzk1LmpwZWc.jpeg",
    tracks: [
      {
        title: "SR",
        artists: "Sascha Rydell, Sascha Rydell",
        position: "",
        duration: null,
        rpm: 45,
        genre: "Techno, Dub Techno",
        playable: true,
        _id: "3300",
        audioFeatures: {
          acousticness: 0.0423,
          danceability: 0.941,
          duration_ms: 452379,
          energy: 0.296,
          instrumentalness: 0.586,
          key: 11,
          liveness: 0.11,
          loudness: -11.667,
          mode: 0,
          speechiness: 0.192,
          tempo: 125.04,
          time_signature: 4,
          valence: 0.27,
        },
        spotifyID: "5RI1ppIVQcnWu98EWU9Gqx",
      },
      {
        title: "MD",
        artists: "Mike Dehnert, Mike Dehnert",
        position: "",
        duration: null,
        rpm: 45,
        genre: "Techno, Dub Techno",
        playable: true,
        _id: "3301",
        audioFeatures: {
          acousticness: 0.000745,
          danceability: 0.826,
          duration_ms: 416119,
          energy: 0.361,
          instrumentalness: 0.892,
          key: 11,
          liveness: 0.106,
          loudness: -12.728,
          mode: 0,
          speechiness: 0.106,
          tempo: 125.008,
          time_signature: 4,
          valence: 0.109,
        },
        spotifyID: "5Bkb7TqrWYVAUq7pStwRot",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    user: "1",
  },
  {
    _id: "34",
    discogsID: 3242519,
    catno: "FW021",
    title: "Mouvoir EP",
    artists: "Sascha Rydell",
    label: "Fachwerk",
    year: 2011,
    cover:
      "https://i.discogs.com/OBYtFIolCRtWPR0zEWqDLrl9wEeaxTUQevGc2bzsiFo/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTMyNDI1/MTktMTMyMjAwMDEw/Mi5qcGVn.jpeg",
    tracks: [
      {
        title: "Puissant",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Techno, Dub Techno, Minimal",
        playable: true,
        _id: "3400",
        audioFeatures: {
          acousticness: 0.00427,
          danceability: 0.82,
          duration_ms: 324480,
          energy: 0.666,
          instrumentalness: 0.922,
          key: 6,
          liveness: 0.791,
          loudness: -8.468,
          mode: 0,
          speechiness: 0.133,
          tempo: 124.966,
          time_signature: 4,
          valence: 0.249,
        },
        spotifyID: "4v9mP3STZB4bRIlpMsHvgQ",
      },
      {
        title: "Laisser Faire",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Techno, Dub Techno, Minimal",
        playable: true,
        _id: "3401",
        audioFeatures: {
          acousticness: 0.0148,
          danceability: 0.892,
          duration_ms: 400000,
          energy: 0.665,
          instrumentalness: 0.879,
          key: 4,
          liveness: 0.259,
          loudness: -10.023,
          mode: 0,
          speechiness: 0.0879,
          tempo: 124.979,
          time_signature: 4,
          valence: 0.219,
        },
        spotifyID: "7ugP91kzQF9hWfMdx6K2QF",
      },
      {
        title: "Rappel",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Techno, Dub Techno, Minimal",
        playable: true,
        _id: "3402",
        audioFeatures: {
          acousticness: 0.0047,
          danceability: 0.75,
          duration_ms: 315000,
          energy: 0.728,
          instrumentalness: 0.898,
          key: 1,
          liveness: 0.0777,
          loudness: -12.286,
          mode: 0,
          speechiness: 0.0514,
          tempo: 124.999,
          time_signature: 4,
          valence: 0.0521,
        },
        spotifyID: "26Nix3Wilzo9xRj3Vh0roi",
      },
      {
        title: "Deja Vu",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Techno, Dub Techno, Minimal",
        playable: true,
        _id: "3403",
        audioFeatures: {
          acousticness: 0.00127,
          danceability: 0.84,
          duration_ms: 404000,
          energy: 0.575,
          instrumentalness: 0.885,
          key: 10,
          liveness: 0.099,
          loudness: -11.244,
          mode: 0,
          speechiness: 0.073,
          tempo: 123.023,
          time_signature: 4,
          valence: 0.388,
        },
        spotifyID: "2jL3ruBHKBC7QO1k2PU7d8",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    spotifyID: "13PUNXtYPHEbUxOg31pKNA",
    user: "1",
  },
  {
    _id: "35",
    discogsID: 4673697,
    catno: "AOS7700",
    title: "Thank You For Letting Me Be Myself Part 2",
    artists: "Omar-S",
    label: "FXHE Records",
    year: 2013,
    cover:
      "https://i.discogs.com/y65lSTK_jODVfMqDJ44_raIe28tTUuB-uvIPdlsko8g/rs:fit/g:sm/q:90/h:350/w:349/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTQ2NzM2/OTctMTM3MjM3MDcx/OS0yOTk5LnBuZw.jpeg",
    tracks: [
      {
        title: "Thank You For Letting Me Be Myself",
        artists: "",
        position: "E1",
        duration: 411000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3500",
        audioFeatures: {
          acousticness: 0.0000681,
          danceability: 0.811,
          duration_ms: 459964,
          energy: 0.518,
          instrumentalness: 0.787,
          key: 6,
          liveness: 0.107,
          loudness: -9.314,
          mode: 1,
          speechiness: 0.0591,
          tempo: 122.999,
          time_signature: 4,
          valence: 0.936,
        },
        spotifyID: "2qXCLKpQlWWQq1A9sFRUu8",
      },
      {
        title: "Ready My Black Asz",
        artists: "",
        position: "E2",
        duration: 284000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3501",
        audioFeatures: {
          acousticness: 0.0032,
          danceability: 0.8,
          duration_ms: 324669,
          energy: 0.915,
          instrumentalness: 0.917,
          key: 1,
          liveness: 0.155,
          loudness: -9.861,
          mode: 1,
          speechiness: 0.0642,
          tempo: 120.585,
          time_signature: 3,
          valence: 0.475,
        },
        spotifyID: "6XDdcBC0hQkRcQfYovxd4c",
      },
      {
        title: "Messier Sixty Eight ({Featuring Big Strick}He Said??)",
        artists: "Big Strick",
        position: "F1",
        duration: 188000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3502",
      },
      {
        title: "Dumpster Graves",
        artists: "",
        position: "F2",
        duration: 291000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3503",
        audioFeatures: {
          acousticness: 0.000726,
          danceability: 0.786,
          duration_ms: 292217,
          energy: 0.714,
          instrumentalness: 0.908,
          key: 9,
          liveness: 0.101,
          loudness: -10.344,
          mode: 0,
          speechiness: 0.0595,
          tempo: 119.956,
          time_signature: 4,
          valence: 0.269,
        },
        spotifyID: "1qp5TjkJT3k1wkA2lR0BSG",
      },
      {
        title: "She's Sah Hero Nik",
        artists: "",
        position: "F3",
        duration: 213000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3504",
        audioFeatures: {
          acousticness: 0.00767,
          danceability: 0.892,
          duration_ms: 212938,
          energy: 0.255,
          instrumentalness: 0.957,
          key: 0,
          liveness: 0.125,
          loudness: -11.665,
          mode: 1,
          speechiness: 0.109,
          tempo: 121.005,
          time_signature: 4,
          valence: 0.569,
        },
        spotifyID: "5ZneBe25xYNfVtGeuTOicv",
      },
      {
        title: "Broken Bammalance Horn",
        artists: "",
        position: "G1",
        duration: 455000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3505",
        audioFeatures: {
          acousticness: 0.709,
          danceability: 0.783,
          duration_ms: 454995,
          energy: 0.434,
          instrumentalness: 0.88,
          key: 4,
          liveness: 0.111,
          loudness: -17.803,
          mode: 0,
          speechiness: 0.0794,
          tempo: 122.993,
          time_signature: 4,
          valence: 0.205,
        },
        spotifyID: "47iUs9OANkLfhoYfnygXGO",
      },
      {
        title: "Force Fed The Meds!!!!!!",
        artists: "",
        position: "G2",
        duration: 242000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3506",
        audioFeatures: {
          acousticness: 0.0162,
          danceability: 0.791,
          duration_ms: 242514,
          energy: 0.768,
          instrumentalness: 0.941,
          key: 7,
          liveness: 0.111,
          loudness: -13.163,
          mode: 0,
          speechiness: 0.0719,
          tempo: 123.015,
          time_signature: 4,
          valence: 0.183,
        },
        spotifyID: "4i4PTIqLpBUVWP79RdDxbq",
      },
      {
        title: 'Its Money In The " D "',
        artists: "",
        position: "H1",
        duration: 525000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3507",
        audioFeatures: {
          acousticness: 0.00113,
          danceability: 0.804,
          duration_ms: 490173,
          energy: 0.917,
          instrumentalness: 0.926,
          key: 4,
          liveness: 0.0946,
          loudness: -8.145,
          mode: 1,
          speechiness: 0.0741,
          tempo: 123.999,
          time_signature: 4,
          valence: 0.0367,
        },
        spotifyID: "6jMAXON1WKkHDu1hilkrCT",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    user: "1",
  },
  {
    _id: "36",
    discogsID: 4673672,
    catno: "AOS7700LPAD",
    title: "Thank You For Letting Me Be Myself Part 1",
    artists: "Omar-S",
    label: "FXHE Records",
    year: 2013,
    cover:
      "https://i.discogs.com/cFV9SSvMqHo-EHqr4wjs5BsWSVn5zYdf8DqjYyVV7eo/rs:fit/g:sm/q:90/h:350/w:350/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTQ2NzM2/NzItMTM3MjM3MDQ4/NC0xNzQyLnBuZw.jpeg",
    tracks: [
      {
        title: "I Just Want",
        artists: "Luke Hess",
        position: "A1",
        duration: 340000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3600",
      },
      {
        title: "Air Of The Day",
        artists: "",
        position: "A2",
        duration: 371000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3601",
        audioFeatures: {
          acousticness: 0.00457,
          danceability: 0.548,
          duration_ms: 370538,
          energy: 0.831,
          instrumentalness: 0.917,
          key: 9,
          liveness: 0.105,
          loudness: -5.455,
          mode: 0,
          speechiness: 0.0751,
          tempo: 122.013,
          time_signature: 4,
          valence: 0.35,
        },
        spotifyID: "2DIra3lteTdthq3pjjSQQ8",
      },
      {
        title: "Be Yoself",
        artists: "",
        position: "B1",
        duration: 403000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3602",
        audioFeatures: {
          acousticness: 0.0818,
          danceability: 0.808,
          duration_ms: 415323,
          energy: 0.735,
          instrumentalness: 0.893,
          key: 10,
          liveness: 0.0809,
          loudness: -9.61,
          mode: 0,
          speechiness: 0.0568,
          tempo: 121.001,
          time_signature: 4,
          valence: 0.596,
        },
        spotifyID: "5P1DrsCblcP5ZkcwAEPBsb",
      },
      {
        title: "Rewind",
        artists: "",
        position: "B2",
        duration: 306000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3603",
        audioFeatures: {
          acousticness: 0.00124,
          danceability: 0.837,
          duration_ms: 385962,
          energy: 0.384,
          instrumentalness: 0.726,
          key: 9,
          liveness: 0.108,
          loudness: -14.497,
          mode: 0,
          speechiness: 0.0439,
          tempo: 120.002,
          time_signature: 4,
          valence: 0.176,
        },
        spotifyID: "6I3BxDyqMyLcbkCeLwrDoM",
      },
      {
        title: "The Shit Baby",
        artists: "D. Taylor",
        position: "C1",
        duration: 388000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3604",
        audioFeatures: {
          acousticness: 0.0197,
          danceability: 0.805,
          duration_ms: 413315,
          energy: 0.602,
          instrumentalness: 0.803,
          key: 9,
          liveness: 0.26,
          loudness: -10.572,
          mode: 0,
          speechiness: 0.0606,
          tempo: 125.126,
          time_signature: 4,
          valence: 0.869,
        },
        spotifyID: "77u4D7eaO0T6zVby7kybyP",
      },
      {
        title: "Hellter Shelter",
        artists: "",
        position: "C2",
        duration: 251000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3605",
        audioFeatures: {
          acousticness: 0.478,
          danceability: 0.609,
          duration_ms: 251562,
          energy: 0.726,
          instrumentalness: 0.904,
          key: 2,
          liveness: 0.229,
          loudness: -7.917,
          mode: 1,
          speechiness: 0.0399,
          tempo: 125.015,
          time_signature: 3,
          valence: 0.0522,
        },
        spotifyID: "7Jxv2jXNliVpYXsSd8GoC7",
      },
      {
        title: "Amalthea",
        artists: "",
        position: "D1",
        duration: 410000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3606",
        audioFeatures: {
          acousticness: 0.00283,
          danceability: 0.803,
          duration_ms: 441574,
          energy: 0.871,
          instrumentalness: 0.732,
          key: 0,
          liveness: 0.269,
          loudness: -5.464,
          mode: 1,
          speechiness: 0.0645,
          tempo: 121.001,
          time_signature: 4,
          valence: 0.571,
        },
        spotifyID: "3jpbqXO1oUxEZfEpS5CgjD",
      },
      {
        title: "Tardigrade’s",
        artists: "",
        position: "D2",
        duration: 262000,
        rpm: 33,
        genre: "House, Techno, Deep House",
        playable: true,
        _id: "3607",
        audioFeatures: {
          acousticness: 0.0407,
          danceability: 0.823,
          duration_ms: 261915,
          energy: 0.877,
          instrumentalness: 0.905,
          key: 10,
          liveness: 0.0956,
          loudness: -10.498,
          mode: 0,
          speechiness: 0.0621,
          tempo: 123.986,
          time_signature: 4,
          valence: 0.129,
        },
        spotifyID: "3wWsdGGuCPmB6iwTFkIPnv",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    user: "1",
  },
  {
    _id: "37",
    discogsID: 23820383,
    catno: "OYSTER42",
    title: "Alltogethernow EP",
    artists: "Fantastic Man",
    label: "Kalahari Oyster Cult",
    year: 2022,
    cover:
      "https://i.discogs.com/qHIRRHa5awmdLCn3Kq_C1mSs3bz6NL2AzVJKLaUKJiU/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTIzODIw/MzgzLTE2NTcyODE3/MzItOTcyNC5qcGVn.jpeg",
    tracks: [
      {
        title: "Alltogethernow",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "House, Dub Techno, Acid House",
        playable: true,
        _id: "3700",
        audioFeatures: {
          acousticness: 0.011,
          danceability: 0.793,
          duration_ms: 331013,
          energy: 0.835,
          instrumentalness: 0.882,
          key: 7,
          liveness: 0.136,
          loudness: -10.312,
          mode: 0,
          speechiness: 0.0432,
          tempo: 128.997,
          time_signature: 4,
          valence: 0.338,
        },
        spotifyID: "0FYmpCa4hW71g7sK5BD5sh",
      },
      {
        title: "Bioxy",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "House, Dub Techno, Acid House",
        playable: true,
        _id: "3701",
        audioFeatures: {
          acousticness: 0.00225,
          danceability: 0.793,
          duration_ms: 303080,
          energy: 0.658,
          instrumentalness: 0.857,
          key: 7,
          liveness: 0.0862,
          loudness: -10.587,
          mode: 1,
          speechiness: 0.0514,
          tempo: 128.985,
          time_signature: 4,
          valence: 0.191,
        },
        spotifyID: "0IpvkDQogHioKHPGW0mxp8",
      },
      {
        title: "Alpha",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "House, Dub Techno, Acid House",
        playable: true,
        _id: "3702",
        audioFeatures: {
          acousticness: 0.0205,
          danceability: 0.71,
          duration_ms: 467947,
          energy: 0.466,
          instrumentalness: 0.914,
          key: 10,
          liveness: 0.132,
          loudness: -15.483,
          mode: 0,
          speechiness: 0.0464,
          tempo: 129.006,
          time_signature: 4,
          valence: 0.424,
        },
        spotifyID: "4POxJMRF3h2bIbLm8PmNDl",
      },
      {
        title: "Trojan Force",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "House, Dub Techno, Acid House",
        playable: true,
        _id: "3703",
        audioFeatures: {
          acousticness: 0.000579,
          danceability: 0.765,
          duration_ms: 329853,
          energy: 0.881,
          instrumentalness: 0.879,
          key: 10,
          liveness: 0.0276,
          loudness: -11.313,
          mode: 0,
          speechiness: 0.0684,
          tempo: 128.859,
          time_signature: 4,
          valence: 0.72,
        },
        spotifyID: "1tQWdSvT29kySHjJQff0zT",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    user: "1",
  },
  {
    _id: "38",
    discogsID: 12926938,
    catno: "KW 23",
    title: "Klockworks 23",
    artists: "Stef Mendesidis",
    label: "Klockworks",
    year: 2018,
    cover:
      "https://i.discogs.com/zLYh1HBXPDp8DvNbfdmi_QdSAso80s0RlHVChriILp8/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEyOTI2/OTM4LTE1NDQ3NDQ4/NzgtNTAyNy5qcGVn.jpeg",
    tracks: [
      {
        title: "Valley Of Machines",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 45,
        genre: "Techno",
        playable: true,
        _id: "3800",
        audioFeatures: {
          acousticness: 0.0367,
          danceability: 0.789,
          duration_ms: 349682,
          energy: 0.65,
          instrumentalness: 0.89,
          key: 2,
          liveness: 0.0978,
          loudness: -7.021,
          mode: 1,
          speechiness: 0.113,
          tempo: 137,
          time_signature: 4,
          valence: 0.172,
        },
        spotifyID: "4gpZSGr6vaYTYBrsBjruzl",
      },
      {
        title: "Gendarme Classe A",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 45,
        genre: "Techno",
        playable: true,
        _id: "3801",
        audioFeatures: {
          acousticness: 0.256,
          danceability: 0.871,
          duration_ms: 316509,
          energy: 0.949,
          instrumentalness: 0.85,
          key: 1,
          liveness: 0.0753,
          loudness: -5.364,
          mode: 1,
          speechiness: 0.133,
          tempo: 138.01,
          time_signature: 4,
          valence: 0.0666,
        },
        spotifyID: "2wY64aIh6hdUKTZGMUO8VA",
      },
      {
        title: "Senzo",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 45,
        genre: "Techno",
        playable: true,
        _id: "3802",
        audioFeatures: {
          acousticness: 0.052,
          danceability: 0.752,
          duration_ms: 356091,
          energy: 0.878,
          instrumentalness: 0.918,
          key: 8,
          liveness: 0.113,
          loudness: -9.929,
          mode: 0,
          speechiness: 0.0738,
          tempo: 136.993,
          time_signature: 4,
          valence: 0.19,
        },
        spotifyID: "5AE9yi99Jiz9ko1JmJiHpd",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    spotifyID: "6y696blH4O06pUvtoT7i4x",
    user: "1",
  },
  {
    _id: "39",
    discogsID: 10519899,
    catno: "LIES096",
    title: "Smokin",
    artists: "Randomer",
    label: "L.I.E.S. Records",
    year: 2017,
    cover:
      "https://i.discogs.com/GQjIqKiJ4oAAm5ipfkm-kfDDy9JXbX3KILJtArjRWFU/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEwNTE5/ODk5LTE1MDE1MjA1/MjAtNDQzMS5qcGVn.jpeg",
    tracks: [
      {
        title: "Smokin",
        artists: "",
        position: "A1",
        duration: 340000,
        rpm: 33,
        genre: "Techno, Electro",
        playable: true,
        _id: "3900",
        audioFeatures: {
          acousticness: 0.000398,
          danceability: 0.788,
          duration_ms: 339504,
          energy: 0.955,
          instrumentalness: 0.841,
          key: 1,
          liveness: 0.196,
          loudness: -7.823,
          mode: 0,
          speechiness: 0.0647,
          tempo: 130.98,
          time_signature: 4,
          valence: 0.276,
        },
        spotifyID: "7kHdteDFUlvnuAmXarcGpY",
      },
      {
        title: "Velvet",
        artists: "",
        position: "B1",
        duration: 359000,
        rpm: 33,
        genre: "Techno, Electro",
        playable: true,
        _id: "3901",
        audioFeatures: {
          acousticness: 0.00582,
          danceability: 0.739,
          duration_ms: 359200,
          energy: 0.958,
          instrumentalness: 0.907,
          key: 6,
          liveness: 0.115,
          loudness: -4.345,
          mode: 0,
          speechiness: 0.086,
          tempo: 124.998,
          time_signature: 3,
          valence: 0.0382,
        },
        spotifyID: "7hHpn9EDdCeFmwNkMkA0DQ",
      },
      {
        title: "Rye",
        artists: "",
        position: "B2",
        duration: 309000,
        rpm: 33,
        genre: "Techno, Electro",
        playable: true,
        _id: "3902",
        audioFeatures: {
          acousticness: 0.000186,
          danceability: 0.79,
          duration_ms: 308810,
          energy: 0.963,
          instrumentalness: 0.958,
          key: 10,
          liveness: 0.102,
          loudness: -5.951,
          mode: 0,
          speechiness: 0.0611,
          tempo: 125.023,
          time_signature: 3,
          valence: 0.101,
        },
        spotifyID: "1GSqSv3g1hTyWVz0p9jN6e",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    spotifyID: "6xeerTW6mzzWcxuXUBY10m",
    user: "1",
  },
  {
    _id: "40",
    discogsID: 6196668,
    catno: "MORD012",
    title: "Second Life EP",
    artists: "UVB",
    label: "Mord",
    year: 2014,
    cover:
      "https://i.discogs.com/E7y7aG7k5Q7xoTi6nUQLMM2iio6XQtoo7DVK_KUQDCI/rs:fit/g:sm/q:90/h:599/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTYxOTY2/NjgtMTQ2NjY2NzI0/MS0yNzEyLmpwZWc.jpeg",
    tracks: [
      {
        title: "Mixtion",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4000",
        audioFeatures: {
          acousticness: 0.269,
          danceability: 0.626,
          duration_ms: 390418,
          energy: 0.947,
          instrumentalness: 0.916,
          key: 1,
          liveness: 0.156,
          loudness: -8.331,
          mode: 1,
          speechiness: 0.0443,
          tempo: 135.363,
          time_signature: 4,
          valence: 0.372,
        },
        spotifyID: "2kL9SQGKgHImOQ74QBzzUl",
      },
      {
        title: "Second Life",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4001",
        audioFeatures: {
          acousticness: 0.592,
          danceability: 0.786,
          duration_ms: 391498,
          energy: 0.796,
          instrumentalness: 0.891,
          key: 1,
          liveness: 0.111,
          loudness: -9.505,
          mode: 1,
          speechiness: 0.0765,
          tempo: 134.245,
          time_signature: 4,
          valence: 0.644,
        },
        spotifyID: "2atXNWKjIuKre37Y1lAzA7",
      },
      {
        title: "Anxiety",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4002",
        audioFeatures: {
          acousticness: 0.151,
          danceability: 0.656,
          duration_ms: 372298,
          energy: 0.914,
          instrumentalness: 0.932,
          key: 1,
          liveness: 0.0893,
          loudness: -8.341,
          mode: 1,
          speechiness: 0.0377,
          tempo: 135.641,
          time_signature: 4,
          valence: 0.408,
        },
        spotifyID: "2Su890S8Nfgjk6Kr6SPj7A",
      },
      {
        title: "Supertronic",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4003",
        audioFeatures: {
          acousticness: 0.0813,
          danceability: 0.726,
          duration_ms: 302876,
          energy: 0.99,
          instrumentalness: 0.847,
          key: 7,
          liveness: 0.12,
          loudness: -8.144,
          mode: 1,
          speechiness: 0.0671,
          tempo: 137.652,
          time_signature: 4,
          valence: 0.465,
        },
        spotifyID: "2Eto2a59yw18XkTWbOhb66",
      },
      {
        title: "Zakajèn",
        artists: "",
        position: "C1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4004",
        audioFeatures: {
          acousticness: 0.00685,
          danceability: 0.776,
          duration_ms: 305566,
          energy: 0.996,
          instrumentalness: 0.956,
          key: 10,
          liveness: 0.0705,
          loudness: -6.853,
          mode: 0,
          speechiness: 0.0441,
          tempo: 135.404,
          time_signature: 4,
          valence: 0.29,
        },
        spotifyID: "1pqjLOfBd6VrlZhxZigxve",
      },
      {
        title: "Five O",
        artists: "",
        position: "C2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4005",
        audioFeatures: {
          acousticness: 0.00228,
          danceability: 0.776,
          duration_ms: 399641,
          energy: 0.909,
          instrumentalness: 0.95,
          key: 1,
          liveness: 0.109,
          loudness: -7.918,
          mode: 1,
          speechiness: 0.0652,
          tempo: 137.646,
          time_signature: 4,
          valence: 0.345,
        },
        spotifyID: "3OjHcbUm44OSxQyJYk4zgk",
      },
      {
        title: "Psihòza",
        artists: "",
        position: "D1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4006",
        audioFeatures: {
          acousticness: 0.00125,
          danceability: 0.665,
          duration_ms: 385728,
          energy: 0.883,
          instrumentalness: 0.828,
          key: 10,
          liveness: 0.108,
          loudness: -7.026,
          mode: 0,
          speechiness: 0.0432,
          tempo: 135.386,
          time_signature: 4,
          valence: 0.344,
        },
        spotifyID: "7j2Wtbn7JJ0LgQoLOSzlde",
      },
      {
        title: "Cardiff Luv",
        artists: "",
        position: "D2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4007",
        audioFeatures: {
          acousticness: 0.244,
          danceability: 0.781,
          duration_ms: 360033,
          energy: 0.918,
          instrumentalness: 0.646,
          key: 6,
          liveness: 0.112,
          loudness: -9.247,
          mode: 1,
          speechiness: 0.0465,
          tempo: 132.093,
          time_signature: 4,
          valence: 0.734,
        },
        spotifyID: "7LLolDZz5zDgzL8RoUyPLH",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    spotifyID: "3WLmUcdkQOdPArZxzH4qIV",
    user: "1",
  },
  {
    _id: "41",
    discogsID: 1025,
    catno: "pe65234",
    title: "Silentintroduction",
    artists: "Moodymann",
    label: "Planet E",
    year: 1997,
    cover:
      "https://i.discogs.com/06tNSoQfieSC49S0FP0jYihqWTud2_kJKwaIviNsNWw/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEwMjUt/MTQwNDExOTA5NC03/OTg4LmpwZWc.jpeg",
    tracks: [
      {
        title: "Misled",
        artists: "",
        position: "A1",
        duration: 282000,
        rpm: 33,
        genre: "Deep House, House",
        playable: true,
        _id: "4100",
        audioFeatures: {
          acousticness: 0.0826,
          danceability: 0.751,
          duration_ms: 294173,
          energy: 0.853,
          instrumentalness: 0.937,
          key: 6,
          liveness: 0.403,
          loudness: -11.438,
          mode: 1,
          speechiness: 0.2,
          tempo: 118.16,
          time_signature: 4,
          valence: 0.648,
        },
        spotifyID: "5CQ2sOqsTCQ6tDITojvpCQ",
      },
      {
        title: "I Can't Kick This Feeling When It Hits",
        artists: "",
        position: "A2",
        duration: 480000,
        rpm: 33,
        genre: "Deep House, House",
        playable: true,
        _id: "4101",
        audioFeatures: {
          acousticness: 0.192,
          danceability: 0.686,
          duration_ms: 485307,
          energy: 0.634,
          instrumentalness: 0.858,
          key: 2,
          liveness: 0.152,
          loudness: -10.287,
          mode: 0,
          speechiness: 0.056,
          tempo: 119.933,
          time_signature: 4,
          valence: 0.0783,
        },
        spotifyID: "2PmT3D5xmvVzZCf11L2ifX",
      },
      {
        title: "The Third Track",
        artists: "",
        position: "B1",
        duration: 305000,
        rpm: 33,
        genre: "Deep House, House",
        playable: true,
        _id: "4102",
        audioFeatures: {
          acousticness: 0.000101,
          danceability: 0.599,
          duration_ms: 312067,
          energy: 0.803,
          instrumentalness: 0.795,
          key: 10,
          liveness: 0.698,
          loudness: -9.38,
          mode: 1,
          speechiness: 0.0449,
          tempo: 119.338,
          time_signature: 4,
          valence: 0.15,
        },
        spotifyID: "5KENkq3v4j9ttGLbmhOxJ3",
      },
      {
        title: "Oceans",
        artists: "",
        position: "B2",
        duration: 178000,
        rpm: 33,
        genre: "Deep House, House",
        playable: true,
        _id: "4103",
        audioFeatures: {
          acousticness: 0.00595,
          danceability: 0.795,
          duration_ms: 175547,
          energy: 0.605,
          instrumentalness: 0.854,
          key: 5,
          liveness: 0.115,
          loudness: -13.707,
          mode: 1,
          speechiness: 0.0588,
          tempo: 116.934,
          time_signature: 4,
          valence: 0.563,
        },
        spotifyID: "18wWMIgJqDrAkR1NElwk0G",
      },
      {
        title: "Answer Machine",
        artists: "",
        position: "B3",
        duration: 320000,
        rpm: 33,
        genre: "Deep House, House",
        playable: true,
        _id: "4104",
        audioFeatures: {
          acousticness: 0.00155,
          danceability: 0.672,
          duration_ms: 320000,
          energy: 0.888,
          instrumentalness: 0.897,
          key: 10,
          liveness: 0.665,
          loudness: -9.885,
          mode: 0,
          speechiness: 0.0775,
          tempo: 120,
          time_signature: 4,
          valence: 0.385,
        },
        spotifyID: "7t3QDBNnVJJTVTN8E6q6Ro",
      },
      {
        title: "M Traxx",
        artists: "",
        position: "C1",
        duration: 307000,
        rpm: 33,
        genre: "Deep House, House",
        playable: true,
        _id: "4105",
        audioFeatures: {
          acousticness: 0.00444,
          danceability: 0.874,
          duration_ms: 310467,
          energy: 0.568,
          instrumentalness: 0.826,
          key: 8,
          liveness: 0.707,
          loudness: -11.521,
          mode: 0,
          speechiness: 0.0894,
          tempo: 119.988,
          time_signature: 4,
          valence: 0.494,
        },
        spotifyID: "6dwR06Edc87fGq9vpSBBtJ",
      },
      {
        title: "Music People",
        artists: "",
        position: "C2",
        duration: 397000,
        rpm: 33,
        genre: "Deep House, House",
        playable: true,
        _id: "4106",
        audioFeatures: {
          acousticness: 0.0268,
          danceability: 0.576,
          duration_ms: 581160,
          energy: 0.769,
          instrumentalness: 0.0816,
          key: 0,
          liveness: 0.707,
          loudness: -12.597,
          mode: 1,
          speechiness: 0.0785,
          tempo: 119.974,
          time_signature: 4,
          valence: 0.268,
        },
        spotifyID: "3og6H9EY7uK39wRHdnmZ8h",
      },
      {
        title: "Untitled",
        artists: "",
        position: "C3",
        duration: 187000,
        rpm: 33,
        genre: "Deep House, House",
        playable: true,
        _id: "4107",
      },
      {
        title: "Sunday Morning",
        artists: "Kevin Crosby, Norma Jean Bell",
        position: "D1",
        duration: 652000,
        rpm: 33,
        genre: "Deep House, House",
        playable: true,
        _id: "4108",
        audioFeatures: {
          acousticness: 0.0981,
          danceability: 0.63,
          duration_ms: 662520,
          energy: 0.595,
          instrumentalness: 0.0837,
          key: 3,
          liveness: 0.687,
          loudness: -13.154,
          mode: 1,
          speechiness: 0.0745,
          tempo: 118.019,
          time_signature: 4,
          valence: 0.356,
        },
        spotifyID: "17E7pOkJUrPX6J9DcKTXNW",
      },
      {
        title: "In Loving Memory",
        artists: "",
        position: "D2",
        duration: 389000,
        rpm: 33,
        genre: "Deep House, House",
        playable: true,
        _id: "4109",
        audioFeatures: {
          acousticness: 0.382,
          danceability: 0.707,
          duration_ms: 387400,
          energy: 0.443,
          instrumentalness: 0.936,
          key: 11,
          liveness: 0.345,
          loudness: -12.302,
          mode: 1,
          speechiness: 0.0439,
          tempo: 117.909,
          time_signature: 4,
          valence: 0.757,
        },
        spotifyID: "595MIU6K8FXFZOWbi06EHe",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    spotifyID: "1WuyO8qwthV94n8A4crVss",
    user: "1",
  },
  {
    _id: "42",
    discogsID: 10530133,
    catno: "PLE65380-1",
    title: "God Loves Detroit",
    artists: "Terrence Parker",
    label: "Planet E",
    year: 2017,
    cover:
      "https://i.discogs.com/LOtzBQVhzqX1QMpZEviyhebWfo1EWbKIKhACu2NaQBQ/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEwNTMw/MTMzLTE0OTkzMjY4/OTMtNDIxMi5qcGVn.jpeg",
    tracks: [
      {
        title: "Bassment Beats (TP's Bassment Mix)",
        artists: "Terrence Parker, Merachka",
        position: "A1",
        duration: 429000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4200",
        audioFeatures: {
          acousticness: 0.00515,
          danceability: 0.819,
          duration_ms: 428853,
          energy: 0.693,
          instrumentalness: 0.841,
          key: 1,
          liveness: 0.0486,
          loudness: -7.694,
          mode: 1,
          speechiness: 0.0886,
          tempo: 123.992,
          time_signature: 4,
          valence: 0.592,
        },
        spotifyID: "1XPBTPQ0qEURGTNJBx9wYh",
      },
      {
        title: "Don't Waste Another Minute (TP's Classic Piano Mix)",
        artists: "Terrence Parker, Merachka",
        position: "A2",
        duration: 455000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4201",
        audioFeatures: {
          acousticness: 0.0164,
          danceability: 0.695,
          duration_ms: 454707,
          energy: 0.716,
          instrumentalness: 0.68,
          key: 5,
          liveness: 0.0879,
          loudness: -8.582,
          mode: 0,
          speechiness: 0.0378,
          tempo: 123.999,
          time_signature: 4,
          valence: 0.904,
        },
        spotifyID: "6VyGI59t9TCcD30ql1O7Qw",
      },
      {
        title: "God Loves Detroit (The Resurrection)",
        artists: "Terrence Parker",
        position: "A3",
        duration: 489000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4202",
        audioFeatures: {
          acousticness: 0.000842,
          danceability: 0.801,
          duration_ms: 489293,
          energy: 0.892,
          instrumentalness: 0.887,
          key: 7,
          liveness: 0.653,
          loudness: -7.633,
          mode: 1,
          speechiness: 0.1,
          tempo: 126.986,
          time_signature: 4,
          valence: 0.319,
        },
        spotifyID: "6VaJHnKYqyDeCQZnJ6kA35",
      },
      {
        title: "God Will Provide",
        artists: "Terrence Parker",
        position: "B1",
        duration: 413000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4203",
        audioFeatures: {
          acousticness: 0.00205,
          danceability: 0.808,
          duration_ms: 412680,
          energy: 0.779,
          instrumentalness: 0.936,
          key: 1,
          liveness: 0.0467,
          loudness: -7.1,
          mode: 0,
          speechiness: 0.0546,
          tempo: 122.005,
          time_signature: 4,
          valence: 0.991,
        },
        spotifyID: "1giYZTqlSKHv7CTIyw0A6E",
      },
      {
        title: "Just Like Musik (Main Mix)",
        artists: "Terrence Parker, Merachka",
        position: "B2",
        duration: 436000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4204",
        audioFeatures: {
          acousticness: 0.0000979,
          danceability: 0.742,
          duration_ms: 435920,
          energy: 0.864,
          instrumentalness: 0.783,
          key: 10,
          liveness: 0.0552,
          loudness: -7.227,
          mode: 0,
          speechiness: 0.0589,
          tempo: 123.999,
          time_signature: 4,
          valence: 0.783,
        },
        spotifyID: "0T3ZuWXJqNNz3bh27uRF9F",
      },
      {
        title: "Latter Rain (The Healing Rain Mix)",
        artists: "Terrence Parker, Coco Street",
        position: "B3",
        duration: 218000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4205",
        audioFeatures: {
          acousticness: 0.459,
          danceability: 0.581,
          duration_ms: 217520,
          energy: 0.431,
          instrumentalness: 0.00000114,
          key: 5,
          liveness: 0.151,
          loudness: -8.178,
          mode: 0,
          speechiness: 0.0321,
          tempo: 104.958,
          time_signature: 4,
          valence: 0.739,
        },
        spotifyID: "6U0ytVaPblPJ3GK7eQyO9F",
      },
      {
        title: "Latter Rain (TP's After The Storm Mix)",
        artists: "Terrence Parker, Coco Street",
        position: "C1",
        duration: 445000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4206",
        audioFeatures: {
          acousticness: 0.128,
          danceability: 0.792,
          duration_ms: 444773,
          energy: 0.775,
          instrumentalness: 0.00262,
          key: 0,
          liveness: 0.334,
          loudness: -6.098,
          mode: 0,
          speechiness: 0.0403,
          tempo: 124.014,
          time_signature: 4,
          valence: 0.954,
        },
        spotifyID: "6xmLVubhW5P7LZFhKxGAgj",
      },
      {
        title: "Let's Go",
        artists: "Terrence Parker",
        position: "C2",
        duration: 460000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4207",
        audioFeatures: {
          acousticness: 0.0000231,
          danceability: 0.794,
          duration_ms: 459907,
          energy: 0.708,
          instrumentalness: 0.872,
          key: 11,
          liveness: 0.0716,
          loudness: -11.949,
          mode: 0,
          speechiness: 0.0507,
          tempo: 128.009,
          time_signature: 3,
          valence: 0.255,
        },
        spotifyID: "7mSysv6NCVzb6hdleyOdJ2",
      },
      {
        title: "Lift Yo Hands Raise Em High",
        artists: "Terrence Parker, Coco Street",
        position: "C3",
        duration: 478000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4208",
        audioFeatures: {
          acousticness: 0.0383,
          danceability: 0.871,
          duration_ms: 478187,
          energy: 0.724,
          instrumentalness: 0.668,
          key: 9,
          liveness: 0.0761,
          loudness: -5.978,
          mode: 0,
          speechiness: 0.0902,
          tempo: 122.008,
          time_signature: 4,
          valence: 0.76,
        },
        spotifyID: "0jgfhdVA6q8IG3hrQGNjZ7",
      },
      {
        title: "The Sabath",
        artists: "Terrence Parker",
        position: "D1",
        duration: 439000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4209",
        audioFeatures: {
          acousticness: 0.587,
          danceability: 0.498,
          duration_ms: 439053,
          energy: 0.785,
          instrumentalness: 0.664,
          key: 1,
          liveness: 0.117,
          loudness: -6.309,
          mode: 1,
          speechiness: 0.0392,
          tempo: 123.99,
          time_signature: 4,
          valence: 0.181,
        },
        spotifyID: "3VOg4k1YDbZ9DNYTkoK0xe",
      },
      {
        title: "Transition",
        artists: "Terrence Parker",
        position: "D2",
        duration: 214000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4210",
        audioFeatures: {
          acousticness: 0.002,
          danceability: 0.916,
          duration_ms: 213973,
          energy: 0.553,
          instrumentalness: 0.854,
          key: 4,
          liveness: 0.0743,
          loudness: -4.159,
          mode: 0,
          speechiness: 0.07,
          tempo: 115.01,
          time_signature: 4,
          valence: 0.969,
        },
        spotifyID: "5KmPWRxmpVqYWUNfp7sV9p",
      },
      {
        title: "Will You Ever Come Back To Me",
        artists: "Terrence Parker",
        position: "D3",
        duration: 198000,
        rpm: 33,
        genre: "House, Deep House",
        playable: true,
        _id: "4211",
        audioFeatures: {
          acousticness: 0.885,
          danceability: 0.195,
          duration_ms: 198453,
          energy: 0.185,
          instrumentalness: 0.933,
          key: 1,
          liveness: 0.183,
          loudness: -8.94,
          mode: 0,
          speechiness: 0.0366,
          tempo: 76.632,
          time_signature: 4,
          valence: 0.14,
        },
        spotifyID: "01wpw7gKlC44cUNYjr76XL",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    spotifyID: "2e60DCnyOBdF2HzKJbILNb",
    user: "1",
  },
  {
    _id: "43",
    discogsID: 2598606,
    catno: "PH 202",
    title: "The Avalanche",
    artists: "WK7",
    label: "Power House",
    year: 2010,
    cover:
      "https://i.discogs.com/qq52A2BOgGfXOe6RYBqGs63abJWGlEG2n_b-iLVP27M/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTI1OTg2/MDYtMTUxNTE1Njg1/NS04NjY0LnBuZw.jpeg",
    tracks: [
      {
        title: "The Avalanche (Original Mix)",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 45,
        genre: "House, Techno",
        playable: true,
        _id: "4300",
        audioFeatures: {
          acousticness: 0.0000665,
          danceability: 0.628,
          duration_ms: 432720,
          energy: 0.875,
          instrumentalness: 0.936,
          key: 10,
          liveness: 0.385,
          loudness: -7.382,
          mode: 0,
          speechiness: 0.0798,
          tempo: 127.008,
          time_signature: 4,
          valence: 0.271,
        },
        spotifyID: "1HjkWhkpDNHbXCaz82MTKb",
      },
      {
        title: "Higher Power (Original Mix)",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 45,
        genre: "House, Techno",
        playable: true,
        _id: "4301",
        audioFeatures: {
          acousticness: 0.0000283,
          danceability: 0.882,
          duration_ms: 370681,
          energy: 0.795,
          instrumentalness: 0.711,
          key: 0,
          liveness: 0.122,
          loudness: -9.554,
          mode: 0,
          speechiness: 0.103,
          tempo: 127.003,
          time_signature: 4,
          valence: 0.271,
        },
        spotifyID: "7E6tb4WL8LOxSz0Ue3QcgN",
      },
      {
        title: "Higher Power (Hardcore PCK Mix)",
        artists: "Shed, PCK",
        position: "B2",
        duration: null,
        rpm: 45,
        genre: "House, Techno",
        playable: true,
        _id: "4302",
        audioFeatures: {
          acousticness: 0.000266,
          danceability: 0.779,
          duration_ms: 348418,
          energy: 0.932,
          instrumentalness: 0.867,
          key: 7,
          liveness: 0.24,
          loudness: -8.232,
          mode: 0,
          speechiness: 0.129,
          tempo: 127.037,
          time_signature: 4,
          valence: 0.203,
        },
        spotifyID: "23hKG49pqul3FdCLUGKzHJ",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658835" } },
    spotifyID: "6gPK7Gkl1N0Av9DNl6ndsX",
    user: "1",
  },
  {
    _id: "44",
    discogsID: 9656755,
    catno: "RBLP09",
    title: "Lonely Planet",
    artists: "Tornado Wallace",
    label: "Running Back",
    year: 2017,
    cover:
      "https://i.discogs.com/uOnKH1iN6dV3ErOwqYt8LAmwJzGkRAreRsH6RpeFM1Q/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTk2NTY3/NTUtMTQ5NzIxNDY3/MS0xNzQ4LmpwZWc.jpeg",
    tracks: [
      {
        title: "Lonely Planet",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Ambient, Leftfield, Downtempo",
        playable: true,
        _id: "4400",
        audioFeatures: {
          acousticness: 0.245,
          danceability: 0.296,
          duration_ms: 227593,
          energy: 0.659,
          instrumentalness: 0.706,
          key: 10,
          liveness: 0.151,
          loudness: -11.847,
          mode: 0,
          speechiness: 0.064,
          tempo: 142.606,
          time_signature: 3,
          valence: 0.343,
        },
        spotifyID: "2hVhAfYfh4LCs5IP5P8yZt",
      },
      {
        title: "Trance Encounters",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Ambient, Leftfield, Downtempo",
        playable: true,
        _id: "4401",
        audioFeatures: {
          acousticness: 0.00842,
          danceability: 0.748,
          duration_ms: 319575,
          energy: 0.553,
          instrumentalness: 0.925,
          key: 8,
          liveness: 0.131,
          loudness: -12.595,
          mode: 1,
          speechiness: 0.0505,
          tempo: 95.012,
          time_signature: 4,
          valence: 0.621,
        },
        spotifyID: "3Y3wQMvTFvXSuDnhZbhHYW",
      },
      {
        title: "Today",
        artists: "Sui Zhen",
        position: "A3",
        duration: null,
        rpm: 33,
        genre: "Ambient, Leftfield, Downtempo",
        playable: true,
        _id: "4402",
      },
      {
        title: "Warp Odyssey",
        artists: "Mitch McGregor, Nicolaas Oogjes",
        position: "A4",
        duration: null,
        rpm: 33,
        genre: "Ambient, Leftfield, Downtempo",
        playable: true,
        _id: "4403",
        audioFeatures: {
          acousticness: 0.0792,
          danceability: 0.786,
          duration_ms: 340740,
          energy: 0.657,
          instrumentalness: 0.863,
          key: 6,
          liveness: 0.654,
          loudness: -11.981,
          mode: 0,
          speechiness: 0.0489,
          tempo: 118.001,
          time_signature: 4,
          valence: 0.358,
        },
        spotifyID: "1wuGO6naKYmshql7m9EFOs",
      },
      {
        title: "Voices",
        artists: "David Hirschfelder",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Ambient, Leftfield, Downtempo",
        playable: true,
        _id: "4404",
        audioFeatures: {
          acousticness: 0.66,
          danceability: 0.607,
          duration_ms: 534274,
          energy: 0.756,
          instrumentalness: 0.921,
          key: 5,
          liveness: 0.202,
          loudness: -10.592,
          mode: 1,
          speechiness: 0.0303,
          tempo: 159.995,
          time_signature: 4,
          valence: 0.822,
        },
        spotifyID: "3NyiVtyC17fDhOwIQ1XtV9",
      },
      {
        title: "Kingdom Animalia",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Ambient, Leftfield, Downtempo",
        playable: true,
        _id: "4405",
        audioFeatures: {
          acousticness: 0.585,
          danceability: 0.8,
          duration_ms: 272660,
          energy: 0.863,
          instrumentalness: 0.911,
          key: 8,
          liveness: 0.0628,
          loudness: -8.421,
          mode: 1,
          speechiness: 0.0383,
          tempo: 110.999,
          time_signature: 4,
          valence: 0.946,
        },
        spotifyID: "654sGYvyQwr1iRlo8TMbZR",
      },
      {
        title: "Healing Feeling",
        artists: "",
        position: "B3",
        duration: null,
        rpm: 33,
        genre: "Ambient, Leftfield, Downtempo",
        playable: true,
        _id: "4406",
        audioFeatures: {
          acousticness: 0.511,
          danceability: 0.749,
          duration_ms: 227136,
          energy: 0.279,
          instrumentalness: 0.858,
          key: 5,
          liveness: 0.0692,
          loudness: -12.295,
          mode: 0,
          speechiness: 0.0387,
          tempo: 119.961,
          time_signature: 4,
          valence: 0.436,
        },
        spotifyID: "3sv6KqJNkklX5G2GitrNBc",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658836" } },
    spotifyID: "46fxnc299JVRfJLJx8Ua0S",
    user: "1",
  },
  {
    _id: "45",
    discogsID: 14368232,
    catno: "SCR014",
    title: "DJ Mentality",
    artists: "Fantastic Man",
    label: "Superconscious Records",
    year: 2019,
    cover:
      "https://i.discogs.com/rkCqJAZ8z_k8N1M7jbUw_dJxGOdEkoLs0SUsLBySAQs/rs:fit/g:sm/q:90/h:558/w:553/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE0MzY4/MjMyLTE1NzM4MzEz/MzYtNTA4MS5wbmc.jpeg",
    tracks: [
      {
        title: "DJ Mentality",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "House, Techno",
        playable: true,
        _id: "4500",
        audioFeatures: {
          acousticness: 0.00122,
          danceability: 0.785,
          duration_ms: 383626,
          energy: 0.843,
          instrumentalness: 0.877,
          key: 1,
          liveness: 0.319,
          loudness: -11.801,
          mode: 1,
          speechiness: 0.0405,
          tempo: 130.985,
          time_signature: 4,
          valence: 0.183,
        },
        spotifyID: "2tTbgHM66vxRED9OkQoEVj",
      },
      {
        title: "Out of my World",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "House, Techno",
        playable: true,
        _id: "4501",
        audioFeatures: {
          acousticness: 0.0417,
          danceability: 0.669,
          duration_ms: 425308,
          energy: 0.831,
          instrumentalness: 0.575,
          key: 6,
          liveness: 0.123,
          loudness: -13.201,
          mode: 0,
          speechiness: 0.041,
          tempo: 124.008,
          time_signature: 4,
          valence: 0.579,
        },
        spotifyID: "7zhKTNpeTSUAz2bGpBf3kf",
      },
      {
        title: "Aurora Digitalis",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "House, Techno",
        playable: true,
        _id: "4502",
        audioFeatures: {
          acousticness: 0.137,
          danceability: 0.658,
          duration_ms: 302769,
          energy: 0.791,
          instrumentalness: 0.926,
          key: 0,
          liveness: 0.176,
          loudness: -12.902,
          mode: 1,
          speechiness: 0.0541,
          tempo: 130.002,
          time_signature: 4,
          valence: 0.42,
        },
        spotifyID: "7hd0uOmNxUOV1Icg1mxMrN",
      },
      {
        title: "Sun Express (Emergency Mix)",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "House, Techno",
        playable: true,
        _id: "4503",
        audioFeatures: {
          acousticness: 0.00965,
          danceability: 0.672,
          duration_ms: 390285,
          energy: 0.681,
          instrumentalness: 0.895,
          key: 7,
          liveness: 0.0507,
          loudness: -11.968,
          mode: 1,
          speechiness: 0.0424,
          tempo: 131.011,
          time_signature: 4,
          valence: 0.144,
        },
        spotifyID: "1JHDOZwCXIKv4zMAHfyZ1T",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658836" } },
    spotifyID: "0cyYRiiaq5XgdxZSj8pSCu",
    user: "1",
  },
  {
    _id: "46",
    discogsID: 10947904,
    catno: "TESC 004",
    title: "Nutrition",
    artists: "Blawan",
    label: "Ternesc",
    year: 2017,
    cover:
      "https://i.discogs.com/P0Y_z2c6B_pMCmW_7DO4B_2-UH9C16dOk6Uztoh8jdk/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEwOTQ3/OTA0LTE1MDcwMjE5/NzEtODU4OC5qcGVn.jpeg",
    tracks: [
      {
        title: "Mayhem",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 45,
        genre: "Techno, Ambient, Acid",
        playable: true,
        _id: "4600",
        audioFeatures: {
          acousticness: 0.259,
          danceability: 0.796,
          duration_ms: 390386,
          energy: 0.774,
          instrumentalness: 0.921,
          key: 2,
          liveness: 0.107,
          loudness: -13.293,
          mode: 1,
          speechiness: 0.057,
          tempo: 127.987,
          time_signature: 4,
          valence: 0.204,
        },
        spotifyID: "3uMkitcUnMcB8b3mHfAsbl",
      },
      {
        title: "Trampelpfad",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 45,
        genre: "Techno, Ambient, Acid",
        playable: true,
        _id: "4601",
        audioFeatures: {
          acousticness: 0.0044,
          danceability: 0.818,
          duration_ms: 393856,
          energy: 0.423,
          instrumentalness: 0.869,
          key: 7,
          liveness: 0.0809,
          loudness: -12.81,
          mode: 1,
          speechiness: 0.116,
          tempo: 129.004,
          time_signature: 4,
          valence: 0.0393,
        },
        spotifyID: "3W0pZPXaVOflIr3OLXXIsp",
      },
      {
        title: "Fawner",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 45,
        genre: "Techno, Ambient, Acid",
        playable: true,
        _id: "4602",
        audioFeatures: {
          acousticness: 0.0284,
          danceability: 0.615,
          duration_ms: 384660,
          energy: 0.459,
          instrumentalness: 0.903,
          key: 1,
          liveness: 0.168,
          loudness: -16.491,
          mode: 1,
          speechiness: 0.0492,
          tempo: 120.016,
          time_signature: 4,
          valence: 0.113,
        },
        spotifyID: "7mJW2H2lf6NmjGYh5xNtJq",
      },
      {
        title: "993",
        artists: "",
        position: "C1",
        duration: null,
        rpm: 45,
        genre: "Techno, Ambient, Acid",
        playable: true,
        _id: "4603",
        audioFeatures: {
          acousticness: 0.000125,
          danceability: 0.724,
          duration_ms: 363643,
          energy: 0.675,
          instrumentalness: 0.774,
          key: 3,
          liveness: 0.116,
          loudness: -12.311,
          mode: 1,
          speechiness: 0.074,
          tempo: 129.968,
          time_signature: 3,
          valence: 0.326,
        },
        spotifyID: "4yDFhjGlhRcS5Ay8mBYuk5",
      },
      {
        title: "Atlas",
        artists: "",
        position: "D1",
        duration: null,
        rpm: 45,
        genre: "Techno, Ambient, Acid",
        playable: true,
        _id: "4604",
        audioFeatures: {
          acousticness: 0.589,
          danceability: 0.68,
          duration_ms: 429232,
          energy: 0.721,
          instrumentalness: 0.882,
          key: 1,
          liveness: 0.289,
          loudness: -11.602,
          mode: 1,
          speechiness: 0.059,
          tempo: 130.003,
          time_signature: 4,
          valence: 0.065,
        },
        spotifyID: "5vhJ0AbFPgDa94BKRFleCZ",
      },
      {
        title: "Calcium Red",
        artists: "",
        position: "D2",
        duration: null,
        rpm: 45,
        genre: "Techno, Ambient, Acid",
        playable: true,
        _id: "4605",
        audioFeatures: {
          acousticness: 0.00873,
          danceability: 0.784,
          duration_ms: 395386,
          energy: 0.778,
          instrumentalness: 0.86,
          key: 11,
          liveness: 0.194,
          loudness: -11.315,
          mode: 0,
          speechiness: 0.0967,
          tempo: 127.978,
          time_signature: 3,
          valence: 0.103,
        },
        spotifyID: "6W3ZedJWncZXcNqacwGpY3",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658836" } },
    spotifyID: "1kygrKiYg0XvxPJKFWzfIL",
    user: "1",
  },
  {
    _id: "47",
    discogsID: 2242434,
    catno: "WAX 30003",
    title: "No. 30003",
    artists: "Wax",
    label: "Wax",
    year: 2010,
    cover:
      "https://i.discogs.com/YzzORiAAuPRtb4aJ0WXIgKPrTOZO3Wp9rO96pxUjoEc/rs:fit/g:sm/q:90/h:448/w:448/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTIyNDI0/MzQtMTI3MjAyNTE2/MS5qcGVn.jpeg",
    tracks: [
      {
        title: "Untitled",
        artists: "",
        position: "A1",
        duration: 420000,
        rpm: 45,
        genre: "Techno",
        playable: true,
        _id: "4700",
        audioFeatures: {
          acousticness: 0.00616,
          danceability: 0.794,
          duration_ms: 419961,
          energy: 0.75,
          instrumentalness: 0.941,
          key: 7,
          liveness: 0.0588,
          loudness: -9.671,
          mode: 1,
          speechiness: 0.0525,
          tempo: 127.974,
          time_signature: 4,
          valence: 0.337,
        },
        spotifyID: "7oaQ1AHKkOH3XBbp1lQTgP",
      },
      {
        title: "Untitled",
        artists: "",
        position: "B1",
        duration: 418000,
        rpm: 45,
        genre: "Techno",
        playable: true,
        _id: "4701",
        audioFeatures: {
          acousticness: 0.0000977,
          danceability: 0.841,
          duration_ms: 422165,
          energy: 0.472,
          instrumentalness: 0.94,
          key: 7,
          liveness: 0.0751,
          loudness: -11.834,
          mode: 0,
          speechiness: 0.0982,
          tempo: 129.041,
          time_signature: 4,
          valence: 0.054,
        },
        spotifyID: "3hAINzhsOobU9Cmw0YHy5c",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658836" } },
    user: "1",
  },
  {
    _id: "48",
    discogsID: 13717689,
    catno: "TRP024",
    title: "Am I Who I Am",
    artists: "PTU",
    label: "трип",
    year: 2019,
    cover:
      "https://i.discogs.com/vtZGc3pRShO-3HMICQ2eOS_FqupFNbpAaKm9vv23Tks/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTEzNzE3/Njg5LTE2MDMzMjg5/MDktNjAzNS5qcGVn.jpeg",
    tracks: [
      {
        title: "Doc 22",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4800",
        audioFeatures: {
          acousticness: 0.303,
          danceability: 0.757,
          duration_ms: 219926,
          energy: 0.659,
          instrumentalness: 0.0000129,
          key: 1,
          liveness: 0.202,
          loudness: -8.735,
          mode: 1,
          speechiness: 0.264,
          tempo: 140.042,
          time_signature: 4,
          valence: 0.265,
        },
        spotifyID: "5oKbcQWoqOQrpaIeBFzRlO",
      },
      {
        title: "The Pursuit Of A Shadow",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4801",
        audioFeatures: {
          acousticness: 0.161,
          danceability: 0.716,
          duration_ms: 241338,
          energy: 0.629,
          instrumentalness: 0.854,
          key: 1,
          liveness: 0.606,
          loudness: -14.582,
          mode: 1,
          speechiness: 0.0711,
          tempo: 136.016,
          time_signature: 4,
          valence: 0.445,
        },
        spotifyID: "3tc0UhSxh1YOl4N3bZZjQO",
      },
      {
        title: "Castor & Pollux",
        artists: "",
        position: "A3",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4802",
        audioFeatures: {
          acousticness: 0.137,
          danceability: 0.798,
          duration_ms: 197742,
          energy: 0.671,
          instrumentalness: 0.909,
          key: 1,
          liveness: 0.216,
          loudness: -10.614,
          mode: 1,
          speechiness: 0.112,
          tempo: 134.959,
          time_signature: 4,
          valence: 0.183,
        },
        spotifyID: "3L7APYuPTfiB1sz1erYY6F",
      },
      {
        title: "After Cities",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4803",
        audioFeatures: {
          acousticness: 0.355,
          danceability: 0.527,
          duration_ms: 335165,
          energy: 0.917,
          instrumentalness: 0.853,
          key: 1,
          liveness: 0.183,
          loudness: -7.656,
          mode: 0,
          speechiness: 0.0991,
          tempo: 140.035,
          time_signature: 4,
          valence: 0.049,
        },
        spotifyID: "0QiQPKiNaA9sckfWWJ74AA",
      },
      {
        title: "Former Me",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4804",
        audioFeatures: {
          acousticness: 0.697,
          danceability: 0.395,
          duration_ms: 196598,
          energy: 0.876,
          instrumentalness: 0.0442,
          key: 7,
          liveness: 0.45,
          loudness: -9.445,
          mode: 1,
          speechiness: 0.607,
          tempo: 71.736,
          time_signature: 4,
          valence: 0.305,
        },
        spotifyID: "3gec0ozLT58nKpbGcxgGri",
      },
      {
        title: "How Does It Feel",
        artists: "",
        position: "B3",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4805",
        audioFeatures: {
          acousticness: 0.295,
          danceability: 0.475,
          duration_ms: 204215,
          energy: 0.612,
          instrumentalness: 0.32,
          key: 2,
          liveness: 0.111,
          loudness: -9.361,
          mode: 1,
          speechiness: 0.192,
          tempo: 140.076,
          time_signature: 4,
          valence: 0.288,
        },
        spotifyID: "4mPaKNxU0ReMHJZBBNMPFE",
      },
      {
        title: "Over",
        artists: "",
        position: "C1",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4806",
        audioFeatures: {
          acousticness: 0.0159,
          danceability: 0.672,
          duration_ms: 203493,
          energy: 0.93,
          instrumentalness: 0.84,
          key: 5,
          liveness: 0.106,
          loudness: -8.009,
          mode: 0,
          speechiness: 0.0709,
          tempo: 140.012,
          time_signature: 4,
          valence: 0.05,
        },
        spotifyID: "1ygKlqTeDGO7KrfGbFyl2m",
      },
      {
        title: "Copper Mines, New Machines & The Future Conspiracy",
        artists: "",
        position: "C2",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4807",
        audioFeatures: {
          acousticness: 0.11,
          danceability: 0.743,
          duration_ms: 193395,
          energy: 0.901,
          instrumentalness: 0.876,
          key: 1,
          liveness: 0.137,
          loudness: -8.455,
          mode: 1,
          speechiness: 0.0397,
          tempo: 138.984,
          time_signature: 4,
          valence: 0.274,
        },
        spotifyID: "6eneDlBdy1T6Ew2h6MCJht",
      },
      {
        title: "Which Word Is The Coldest",
        artists: "",
        position: "C3",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4808",
        audioFeatures: {
          acousticness: 0.0239,
          danceability: 0.94,
          duration_ms: 219151,
          energy: 0.762,
          instrumentalness: 0.847,
          key: 11,
          liveness: 0.336,
          loudness: -9.365,
          mode: 1,
          speechiness: 0.387,
          tempo: 138.061,
          time_signature: 3,
          valence: 0.466,
        },
        spotifyID: "3Fnk0hiZ23yCAonhW9VET7",
      },
      {
        title: "Sirocco",
        artists: "",
        position: "D1",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4809",
        audioFeatures: {
          acousticness: 0.003,
          danceability: 0.692,
          duration_ms: 242296,
          energy: 0.801,
          instrumentalness: 0.882,
          key: 10,
          liveness: 0.0976,
          loudness: -10.771,
          mode: 0,
          speechiness: 0.0424,
          tempo: 140,
          time_signature: 4,
          valence: 0.0642,
        },
        spotifyID: "3Q8siXgtUFqabbwM5XbRb4",
      },
      {
        title: "Skyscript",
        artists: "",
        position: "D2",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4810",
        audioFeatures: {
          acousticness: 0.36,
          danceability: 0.797,
          duration_ms: 222922,
          energy: 0.707,
          instrumentalness: 0.813,
          key: 0,
          liveness: 0.0793,
          loudness: -9.493,
          mode: 0,
          speechiness: 0.523,
          tempo: 139.993,
          time_signature: 4,
          valence: 0.158,
        },
        spotifyID: "6kOWvDZmgsF35W6Ftlg42O",
      },
      {
        title: "I Heard You Breathe",
        artists: "",
        position: "D3",
        duration: null,
        rpm: 33,
        genre: "Techno, Experimental",
        playable: true,
        _id: "4811",
        audioFeatures: {
          acousticness: 0.747,
          danceability: 0.218,
          duration_ms: 206228,
          energy: 0.298,
          instrumentalness: 0.875,
          key: 10,
          liveness: 0.0723,
          loudness: -23.209,
          mode: 0,
          speechiness: 0.0459,
          tempo: 133.227,
          time_signature: 3,
          valence: 0.0325,
        },
        spotifyID: "3uztXKGBTv7nY0jSmbDwnT",
      },
    ],
    createdAt: { $date: { $numberLong: "1671010658836" } },
    spotifyID: "3ySEuYyAPlfIpxQ2Osf1ix",
    user: "1",
  },
  {
    _id: "49",
    discogsID: 6518378,
    catno: "codeislaw006",
    title: "Mute",
    artists: "Under Black Helmet",
    label: "Code Is Law",
    year: 2015,
    cover:
      "https://i.discogs.com/fTOH449ZmVhQLcI4OgJlHzMXIt79UodUVK7cH_Hmee4/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTY1MTgz/NzgtMTQyMTA3ODUw/OC0zNTAwLmpwZWc.jpeg",
    tracks: [
      {
        title: "Have You Ever Had A Dream",
        artists: "",
        position: "A1",
        duration: 364000,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4900",
        audioFeatures: {
          acousticness: 0.0057,
          danceability: 0.682,
          duration_ms: 364511,
          energy: 0.93,
          instrumentalness: 0.841,
          key: 11,
          liveness: 0.092,
          loudness: -6.832,
          mode: 0,
          speechiness: 0.112,
          tempo: 133.008,
          time_signature: 4,
          valence: 0.0473,
        },
        spotifyID: "2Hm8x3AC3VWpzXPSnufCuw",
      },
      {
        title: "Mute",
        artists: "",
        position: "A2",
        duration: 356000,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4901",
        audioFeatures: {
          acousticness: 0.492,
          danceability: 0.631,
          duration_ms: 356308,
          energy: 0.758,
          instrumentalness: 0.912,
          key: 6,
          liveness: 0.109,
          loudness: -10.057,
          mode: 0,
          speechiness: 0.0597,
          tempo: 130.004,
          time_signature: 4,
          valence: 0.158,
        },
        spotifyID: "5wRXGPnXX1Jm16uQoEEp8p",
      },
      {
        title: "Sabotage",
        artists: "",
        position: "B1",
        duration: 308000,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4902",
        audioFeatures: {
          acousticness: 0.079,
          danceability: 0.7,
          duration_ms: 308571,
          energy: 0.727,
          instrumentalness: 0.892,
          key: 1,
          liveness: 0.112,
          loudness: -13.232,
          mode: 0,
          speechiness: 0.0627,
          tempo: 125.988,
          time_signature: 4,
          valence: 0.481,
        },
        spotifyID: "2dbNZGTZgJmhDzjZVCYo8j",
      },
      {
        title: "Lost Signal",
        artists: "",
        position: "B2",
        duration: 406000,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "4903",
        audioFeatures: {
          acousticness: 0.745,
          danceability: 0.687,
          duration_ms: 406098,
          energy: 0.57,
          instrumentalness: 0.904,
          key: 7,
          liveness: 0.0957,
          loudness: -11.031,
          mode: 1,
          speechiness: 0.0608,
          tempo: 122.998,
          time_signature: 4,
          valence: 0.43,
        },
        spotifyID: "6Axbc43A8OGOfHRICmAmiK",
      },
    ],
    createdAt: { $date: { $numberLong: "1671018028016" } },
    spotifyID: "7qySB5FdmEmrJiqjbiLZUB",
    user: "1",
  },
  {
    _id: "50",
    discogsID: 8658293,
    catno: "EVA002",
    title: "Excuse The Disorder",
    artists: "Dustin Zahn, Emmanuel",
    label: "Enemy Records",
    year: 2016,
    cover:
      "https://i.discogs.com/9EofX-lJ4DR-GPPv2Yxy_0LI54HK18M-mVYxfNs3mGg/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTg2NTgy/OTMtMTQ2NjA2NzY2/MC01Nzc3LmpwZWc.jpeg",
    tracks: [
      {
        title: "Masa",
        artists: "Emmanuel",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5000",
        audioFeatures: {
          acousticness: 0.012,
          danceability: 0.784,
          duration_ms: 313678,
          energy: 0.928,
          instrumentalness: 0.815,
          key: 1,
          liveness: 0.131,
          loudness: -7.075,
          mode: 1,
          speechiness: 0.0575,
          tempo: 128.945,
          time_signature: 4,
          valence: 0.232,
        },
        spotifyID: "1c3aysgz2EQqg8L58YIHmk",
      },
      {
        title: "Saver",
        artists: "Emmanuel",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5001",
        audioFeatures: {
          acousticness: 0.0295,
          danceability: 0.794,
          duration_ms: 299951,
          energy: 0.869,
          instrumentalness: 0.664,
          key: 10,
          liveness: 0.0923,
          loudness: -10.296,
          mode: 0,
          speechiness: 0.0392,
          tempo: 128.01,
          time_signature: 4,
          valence: 0.243,
        },
        spotifyID: "5C8haxYYmq5lNj0qkIxdOC",
      },
      {
        title: "Sunday Night Fever",
        artists: "Dustin Zahn",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5002",
        audioFeatures: {
          acousticness: 0.0928,
          danceability: 0.695,
          duration_ms: 357164,
          energy: 0.836,
          instrumentalness: 0.714,
          key: 4,
          liveness: 0.105,
          loudness: -9.271,
          mode: 0,
          speechiness: 0.053,
          tempo: 130,
          time_signature: 4,
          valence: 0.327,
        },
        spotifyID: "4tZDMuqnfaBo5KnarGiJC1",
      },
      {
        title: "Push It",
        artists: "Dustin Zahn",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5003",
        audioFeatures: {
          acousticness: 0.162,
          danceability: 0.758,
          duration_ms: 298310,
          energy: 0.979,
          instrumentalness: 0.959,
          key: 6,
          liveness: 0.127,
          loudness: -11.75,
          mode: 1,
          speechiness: 0.0427,
          tempo: 131.996,
          time_signature: 4,
          valence: 0.413,
        },
        spotifyID: "3PQUnM1yGgsalOVuLNzmXg",
      },
    ],
    createdAt: { $date: { $numberLong: "1671018028016" } },
    spotifyID: "0i5RcdUkyWgxmxRjWCWji7",
    user: "1",
  },
  {
    _id: "51",
    discogsID: 9909080,
    catno: "KANN 29",
    title: "Dedicated To Someone Both Of Us Know",
    artists: "Cmd Q",
    label: "Kann Records",
    year: 2017,
    cover:
      "https://i.discogs.com/zp6Ejl4HQnSgnDEomkNz94u6Drv8S8jclNxiv-MskSY/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTk5MDkw/ODAtMTQ4ODM2NTA4/OC01MjcwLmpwZWc.jpeg",
    tracks: [
      {
        title: "161161",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5100",
        audioFeatures: {
          acousticness: 0.389,
          danceability: 0.854,
          duration_ms: 546849,
          energy: 0.491,
          instrumentalness: 0.923,
          key: 10,
          liveness: 0.0875,
          loudness: -16.41,
          mode: 1,
          speechiness: 0.0503,
          tempo: 124.006,
          time_signature: 4,
          valence: 0.344,
        },
        spotifyID: "333z4zkbO6sf9eCtxMbkR7",
      },
      {
        title: "B0t",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5101",
        audioFeatures: {
          acousticness: 0.124,
          danceability: 0.701,
          duration_ms: 324118,
          energy: 0.531,
          instrumentalness: 0.913,
          key: 5,
          liveness: 0.115,
          loudness: -14.781,
          mode: 0,
          speechiness: 0.0445,
          tempo: 126.987,
          time_signature: 4,
          valence: 0.406,
        },
        spotifyID: "4XBJlg4AM4xWbianYFQcKm",
      },
      {
        title: "Dedicated To Someone Both Of Us Know",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5102",
        audioFeatures: {
          acousticness: 0.00129,
          danceability: 0.783,
          duration_ms: 413344,
          energy: 0.902,
          instrumentalness: 0.922,
          key: 1,
          liveness: 0.0729,
          loudness: -10.795,
          mode: 1,
          speechiness: 0.059,
          tempo: 132.043,
          time_signature: 4,
          valence: 0.728,
        },
        spotifyID: "7Hy9b848PxSOk2wZM3S2dp",
      },
      {
        title: "Untitled",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5103",
        audioFeatures: {
          acousticness: 0.0301,
          danceability: 0.742,
          duration_ms: 382406,
          energy: 0.709,
          instrumentalness: 0.946,
          key: 8,
          liveness: 0.109,
          loudness: -10.608,
          mode: 1,
          speechiness: 0.0513,
          tempo: 127.01,
          time_signature: 3,
          valence: 0.0484,
        },
        spotifyID: "0R4J9gAokaD3dr4O45tz68",
      },
    ],
    createdAt: { $date: { $numberLong: "1671018028016" } },
    user: "1",
  },
  {
    _id: "52",
    discogsID: 9429875,
    catno: "kann-28",
    title: "Intimacy",
    artists: "Things From The Basement",
    label: "Kann Records",
    year: 2016,
    cover:
      "https://i.discogs.com/mlDKKRO8x7NYcspoQ-WmU3ACoBkLcB-CrnDr_P4aHac/rs:fit/g:sm/q:90/h:601/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTk0Mjk4/NzUtMTQ4MDQzNDQ5/Mi00OTc2LmpwZWc.jpeg",
    tracks: [
      {
        title: "No One Belongs Here More Than You",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Ambient",
        playable: true,
        _id: "5200",
        audioFeatures: {
          acousticness: 0.989,
          danceability: 0.221,
          duration_ms: 682005,
          energy: 0.195,
          instrumentalness: 0.914,
          key: 8,
          liveness: 0.0927,
          loudness: -18.004,
          mode: 0,
          speechiness: 0.0365,
          tempo: 62.903,
          time_signature: 4,
          valence: 0.0306,
        },
        spotifyID: "22HECSyGzX6zVJCUw9OqHl",
      },
      {
        title: "View From The Window",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Ambient",
        playable: true,
        _id: "5201",
        audioFeatures: {
          acousticness: 0.542,
          danceability: 0.331,
          duration_ms: 370398,
          energy: 0.207,
          instrumentalness: 0.949,
          key: 4,
          liveness: 0.108,
          loudness: -23.613,
          mode: 0,
          speechiness: 0.0425,
          tempo: 120.003,
          time_signature: 4,
          valence: 0.274,
        },
        spotifyID: "58AQtrLv6ppvKKkrY7MwX7",
      },
      {
        title: "Intimacy",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Ambient",
        playable: true,
        _id: "5202",
        audioFeatures: {
          acousticness: 0.942,
          danceability: 0.306,
          duration_ms: 344488,
          energy: 0.134,
          instrumentalness: 0.798,
          key: 4,
          liveness: 0.111,
          loudness: -21.025,
          mode: 1,
          speechiness: 0.055,
          tempo: 72.012,
          time_signature: 4,
          valence: 0.255,
        },
        spotifyID: "3481jM9juBrEOYD5sr8Ajr",
      },
      {
        title: "No One Belongs Here More Than You (Sevensol Remix)",
        artists: "Sevensol",
        position: "C1",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Ambient",
        playable: true,
        _id: "5203",
        audioFeatures: {
          acousticness: 0.364,
          danceability: 0.751,
          duration_ms: 403422,
          energy: 0.87,
          instrumentalness: 0.918,
          key: 6,
          liveness: 0.0973,
          loudness: -10.171,
          mode: 0,
          speechiness: 0.0468,
          tempo: 125.004,
          time_signature: 4,
          valence: 0.0728,
        },
        spotifyID: "6Dncnf2ryWlonK2pahJJvK",
      },
      {
        title: "No One Belongs Here More Than You (Polo Remix)",
        artists: "Polo",
        position: "C2",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Ambient",
        playable: true,
        _id: "5204",
        audioFeatures: {
          acousticness: 0.0146,
          danceability: 0.793,
          duration_ms: 330205,
          energy: 0.688,
          instrumentalness: 0.93,
          key: 6,
          liveness: 0.107,
          loudness: -12.972,
          mode: 0,
          speechiness: 0.0581,
          tempo: 127.997,
          time_signature: 4,
          valence: 0.0457,
        },
        spotifyID: "291Bn36KIOhDRgyI7xCMLX",
      },
      {
        title: "No One Belongs Here More Than You (Map.ache Remix)",
        artists: "map.ache",
        position: "D1",
        duration: null,
        rpm: 33,
        genre: "House, Deep House, Ambient",
        playable: true,
        _id: "5205",
        audioFeatures: {
          acousticness: 0.151,
          danceability: 0.808,
          duration_ms: 522446,
          energy: 0.294,
          instrumentalness: 0.866,
          key: 8,
          liveness: 0.102,
          loudness: -12.598,
          mode: 0,
          speechiness: 0.107,
          tempo: 124.986,
          time_signature: 4,
          valence: 0.52,
        },
        spotifyID: "2SDDjOp1SHVTxFuyR7n3ZM",
      },
    ],
    createdAt: { $date: { $numberLong: "1671018028016" } },
    spotifyID: "6dR1c5l46MA8NX2DVxRyBT",
    user: "1",
  },
  {
    _id: "53",
    discogsID: 7656307,
    catno: "MONNOM BLACK 007",
    title: "Shades Of Black",
    artists: "Dax J",
    label: "Monnom Black",
    year: 2015,
    cover:
      "https://i.discogs.com/gFMqQl-nGbFklmE-a2JwnInzoCsWj1BjpxWhF51yiY0/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTc2NTYz/MDctMTQ0NjMwMzc5/OS01NjM1LmpwZWc.jpeg",
    tracks: [
      {
        title: "Orlok's Symphony",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Techno, Drum n Bass, Acid",
        playable: true,
        _id: "5300",
        audioFeatures: {
          acousticness: 0.18,
          danceability: 0.406,
          duration_ms: 150507,
          energy: 0.193,
          instrumentalness: 0.927,
          key: 10,
          liveness: 0.111,
          loudness: -15.181,
          mode: 0,
          speechiness: 0.034,
          tempo: 134.073,
          time_signature: 3,
          valence: 0.0343,
        },
        spotifyID: "1d0PntcQouEapSUMJxD0mO",
      },
      {
        title: "Beyond The Planets",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Techno, Drum n Bass, Acid",
        playable: true,
        _id: "5301",
        audioFeatures: {
          acousticness: 0.0011,
          danceability: 0.764,
          duration_ms: 360795,
          energy: 0.862,
          instrumentalness: 0.907,
          key: 4,
          liveness: 0.112,
          loudness: -8.882,
          mode: 0,
          speechiness: 0.0502,
          tempo: 129.999,
          time_signature: 4,
          valence: 0.0739,
        },
        spotifyID: "5RrlVLuBHJCIhH0gRJTBAu",
      },
      {
        title: "Renegades Of Conflict",
        artists: "",
        position: "A3",
        duration: null,
        rpm: 33,
        genre: "Techno, Drum n Bass, Acid",
        playable: true,
        _id: "5302",
        audioFeatures: {
          acousticness: 0.0000706,
          danceability: 0.475,
          duration_ms: 356684,
          energy: 0.931,
          instrumentalness: 0.916,
          key: 1,
          liveness: 0.14,
          loudness: -7.568,
          mode: 1,
          speechiness: 0.0499,
          tempo: 132.003,
          time_signature: 4,
          valence: 0.47,
        },
        spotifyID: "1VGC5Y7A5n0wcjhwTlYxiI",
      },
      {
        title: "Devine Right",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Techno, Drum n Bass, Acid",
        playable: true,
        _id: "5303",
        audioFeatures: {
          acousticness: 0.0735,
          danceability: 0.593,
          duration_ms: 348784,
          energy: 0.872,
          instrumentalness: 0.938,
          key: 1,
          liveness: 0.115,
          loudness: -8.965,
          mode: 0,
          speechiness: 0.0492,
          tempo: 135.016,
          time_signature: 4,
          valence: 0.149,
        },
        spotifyID: "2fH7BUgSFwXEH9oB2R051B",
      },
      {
        title: "In The Shadows",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Techno, Drum n Bass, Acid",
        playable: true,
        _id: "5304",
        audioFeatures: {
          acousticness: 0.0000179,
          danceability: 0.456,
          duration_ms: 216090,
          energy: 0.851,
          instrumentalness: 0.922,
          key: 11,
          liveness: 0.174,
          loudness: -8.711,
          mode: 0,
          speechiness: 0.0345,
          tempo: 144.989,
          time_signature: 4,
          valence: 0.731,
        },
        spotifyID: "349gWGq7HVjfeEjVYO0duA",
      },
      {
        title: "Protect The Prophecy",
        artists: "",
        position: "C1",
        duration: null,
        rpm: 33,
        genre: "Techno, Drum n Bass, Acid",
        playable: true,
        _id: "5305",
        audioFeatures: {
          acousticness: 0.000172,
          danceability: 0.664,
          duration_ms: 352846,
          energy: 0.803,
          instrumentalness: 0.749,
          key: 7,
          liveness: 0.111,
          loudness: -9.731,
          mode: 1,
          speechiness: 0.0513,
          tempo: 132.99,
          time_signature: 4,
          valence: 0.0472,
        },
        spotifyID: "2s4tv8kqPdS0rgnGRQ6Gta",
      },
      {
        title: "Requiem Souls",
        artists: "",
        position: "C2",
        duration: null,
        rpm: 33,
        genre: "Techno, Drum n Bass, Acid",
        playable: true,
        _id: "5306",
        audioFeatures: {
          acousticness: 0.0021,
          danceability: 0.612,
          duration_ms: 352609,
          energy: 0.912,
          instrumentalness: 0.732,
          key: 11,
          liveness: 0.13,
          loudness: -8.813,
          mode: 0,
          speechiness: 0.0611,
          tempo: 132.986,
          time_signature: 4,
          valence: 0.523,
        },
        spotifyID: "2l56lTmr5TZyKUcZumXO2u",
      },
      {
        title: "Black Pegasus",
        artists: "",
        position: "D1",
        duration: null,
        rpm: 33,
        genre: "Techno, Drum n Bass, Acid",
        playable: true,
        _id: "5307",
        audioFeatures: {
          acousticness: 0.0000444,
          danceability: 0.605,
          duration_ms: 378947,
          energy: 0.798,
          instrumentalness: 0.899,
          key: 7,
          liveness: 0.107,
          loudness: -8.031,
          mode: 1,
          speechiness: 0.0639,
          tempo: 133.01,
          time_signature: 4,
          valence: 0.0556,
        },
        spotifyID: "5Ni2HHw8OtfgzJTu0WNuvo",
      },
      {
        title: "Sempa",
        artists: "",
        position: "D2",
        duration: null,
        rpm: 33,
        genre: "Techno, Drum n Bass, Acid",
        playable: true,
        _id: "5308",
        audioFeatures: {
          acousticness: 0.438,
          danceability: 0.544,
          duration_ms: 330226,
          energy: 0.756,
          instrumentalness: 0.871,
          key: 10,
          liveness: 0.0881,
          loudness: -11.67,
          mode: 0,
          speechiness: 0.0416,
          tempo: 133.992,
          time_signature: 4,
          valence: 0.328,
        },
        spotifyID: "5C3MrTrJ2u9TzeocPQMJ8B",
      },
      {
        title: "Afterlife",
        artists: "",
        position: "D3",
        duration: null,
        rpm: 33,
        genre: "Techno, Drum n Bass, Acid",
        playable: true,
        _id: "5309",
        audioFeatures: {
          acousticness: 0.0119,
          danceability: 0.264,
          duration_ms: 144841,
          energy: 0.442,
          instrumentalness: 0.891,
          key: 5,
          liveness: 0.114,
          loudness: -11.935,
          mode: 0,
          speechiness: 0.0355,
          tempo: 135.228,
          time_signature: 4,
          valence: 0.0541,
        },
        spotifyID: "32hLyIp6eVLLXrkJyq4pDa",
      },
    ],
    createdAt: { $date: { $numberLong: "1671018028016" } },
    user: "1",
  },
  {
    _id: "54",
    discogsID: 8197986,
    catno: "MONNOM BLACK 009",
    title: "The Invisible Man EP",
    artists: "Dax J",
    label: "Monnom Black",
    year: 2016,
    cover:
      "https://i.discogs.com/1YVpmM6S5T0AtHrFxsCiDvlZyikwJwbaP-MqyeX7ZoU/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTgxOTc5/ODYtMTUzMzk2MjIy/Mi0xNjE5LmpwZWc.jpeg",
    tracks: [
      {
        title: "The Wonk",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5400",
        audioFeatures: {
          acousticness: 0.00000682,
          danceability: 0.614,
          duration_ms: 358625,
          energy: 0.812,
          instrumentalness: 0.93,
          key: 10,
          liveness: 0.0722,
          loudness: -7.753,
          mode: 0,
          speechiness: 0.0344,
          tempo: 132.006,
          time_signature: 4,
          valence: 0.163,
        },
        spotifyID: "0fcYcuUKXFzrbQD5MymeWN",
      },
      {
        title: "The Invisible Man",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5401",
        audioFeatures: {
          acousticness: 0.000239,
          danceability: 0.703,
          duration_ms: 351971,
          energy: 0.801,
          instrumentalness: 0.898,
          key: 0,
          liveness: 0.0956,
          loudness: -7.259,
          mode: 0,
          speechiness: 0.0523,
          tempo: 132.996,
          time_signature: 4,
          valence: 0.463,
        },
        spotifyID: "5QeX1c8ktsRdkT69qDqwS4",
      },
      {
        title: "Wir Leben Für Die Nacht",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5402",
        audioFeatures: {
          acousticness: 0.0000313,
          danceability: 0.578,
          duration_ms: 362527,
          energy: 0.954,
          instrumentalness: 0.854,
          key: 10,
          liveness: 0.128,
          loudness: -7.93,
          mode: 0,
          speechiness: 0.0332,
          tempo: 132.997,
          time_signature: 4,
          valence: 0.107,
        },
        spotifyID: "407RsRqXuajlQNQg8Y0dHn",
      },
      {
        title: "The Bounce",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5403",
        audioFeatures: {
          acousticness: 0.00302,
          danceability: 0.631,
          duration_ms: 357473,
          energy: 0.825,
          instrumentalness: 0.918,
          key: 1,
          liveness: 0.106,
          loudness: -8.651,
          mode: 1,
          speechiness: 0.0373,
          tempo: 131.023,
          time_signature: 4,
          valence: 0.383,
        },
        spotifyID: "0fiqdK2ro9z3073kaEQFx0",
      },
      {
        title: "Surrender",
        artists: "Cat Yen",
        position: "B3",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5404",
        audioFeatures: {
          acousticness: 0.25,
          danceability: 0.229,
          duration_ms: 144137,
          energy: 0.579,
          instrumentalness: 0.318,
          key: 10,
          liveness: 0.102,
          loudness: -9.289,
          mode: 1,
          speechiness: 0.0396,
          tempo: 79.351,
          time_signature: 4,
          valence: 0.284,
        },
        spotifyID: "0y1ENQaAaqgMexp1AFWdxR",
      },
    ],
    createdAt: { $date: { $numberLong: "1671018028016" } },
    spotifyID: "7piXNqpjD4iffreADe46uG",
    user: "1",
  },
  {
    _id: "55",
    discogsID: 6021043,
    catno: "MORD010",
    title: "Penny & Pound EP",
    artists: "Ansome",
    label: "Mord",
    year: 2014,
    cover:
      "https://i.discogs.com/4fQv7QW5EVdv_qNaIVnTVyJsjMTE3NpeEK8wF175_8U/rs:fit/g:sm/q:90/h:591/w:591/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTYwMjEw/NDMtMTQ3OTQ2OTk2/MC01NzE2LmpwZWc.jpeg",
    tracks: [
      {
        title: "Penny & Pound",
        artists: "",
        position: "A1",
        duration: 264000,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5500",
        audioFeatures: {
          acousticness: 0.0113,
          danceability: 0.678,
          duration_ms: 264666,
          energy: 0.956,
          instrumentalness: 0.876,
          key: 5,
          liveness: 0.112,
          loudness: -6.746,
          mode: 1,
          speechiness: 0.0563,
          tempo: 128.006,
          time_signature: 4,
          valence: 0.188,
        },
        spotifyID: "5CBhw3JjAUhW0hXm2T0YbX",
      },
      {
        title: "Penny & Pound (Paul Birken Remix)",
        artists: "Paul Birken",
        position: "A2",
        duration: 400000,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5501",
        audioFeatures: {
          acousticness: 0.000262,
          danceability: 0.704,
          duration_ms: 400133,
          energy: 0.958,
          instrumentalness: 0.786,
          key: 7,
          liveness: 0.13,
          loudness: -7.184,
          mode: 1,
          speechiness: 0.0506,
          tempo: 134.989,
          time_signature: 4,
          valence: 0.249,
        },
        spotifyID: "0Zk6BTJK6ml2kLZoc4s3NL",
      },
      {
        title: "Mans Head",
        artists: "",
        position: "B1",
        duration: 316000,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5502",
        audioFeatures: {
          acousticness: 0.0399,
          danceability: 0.71,
          duration_ms: 316414,
          energy: 0.905,
          instrumentalness: 0.782,
          key: 9,
          liveness: 0.109,
          loudness: -11.194,
          mode: 1,
          speechiness: 0.0661,
          tempo: 129.02,
          time_signature: 4,
          valence: 0.188,
        },
        spotifyID: "25Vrtz68whap6U3muxEcMt",
      },
      {
        title: "Clodgy",
        artists: "",
        position: "B2",
        duration: 347000,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5503",
        audioFeatures: {
          acousticness: 0.0388,
          danceability: 0.762,
          duration_ms: 347016,
          energy: 0.907,
          instrumentalness: 0.862,
          key: 8,
          liveness: 0.107,
          loudness: -8.08,
          mode: 1,
          speechiness: 0.189,
          tempo: 127.992,
          time_signature: 4,
          valence: 0.233,
        },
        spotifyID: "75OziaJaYXX0muA33CSD1h",
      },
    ],
    createdAt: { $date: { $numberLong: "1671018028016" } },
    spotifyID: "1oybDMAhYl2pDYpaCMdnnX",
    user: "1",
  },
  {
    _id: "56",
    discogsID: 11926857,
    catno: "RSPX07",
    title: "Take The Throne",
    artists: "Dustin Zahn",
    label: "REKIDS",
    year: 2018,
    cover:
      "https://i.discogs.com/S_anSvmjE6wT7Pvi_elGKsJ58UNnyObdoK6rOsMMpzM/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTExOTI2/ODU3LTE1MjU3Mjc4/NDAtNjgxNy5qcGVn.jpeg",
    tracks: [
      {
        title: "Take The Throne",
        artists: "",
        position: "A1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5600",
        audioFeatures: {
          acousticness: 0.393,
          danceability: 0.751,
          duration_ms: 270122,
          energy: 0.918,
          instrumentalness: 0.994,
          key: 4,
          liveness: 0.115,
          loudness: -6.358,
          mode: 1,
          speechiness: 0.172,
          tempo: 130.013,
          time_signature: 4,
          valence: 0.0729,
        },
        spotifyID: "4HOMho4maZRIpD77qM2vKO",
      },
      {
        title: "Decorum",
        artists: "",
        position: "A2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5601",
        audioFeatures: {
          acousticness: 0.311,
          danceability: 0.748,
          duration_ms: 301395,
          energy: 0.883,
          instrumentalness: 0.971,
          key: 11,
          liveness: 0.112,
          loudness: -8.098,
          mode: 1,
          speechiness: 0.0494,
          tempo: 128.996,
          time_signature: 4,
          valence: 0.0442,
        },
        spotifyID: "3MaEqMeTrOHKP9LT1EuOyn",
      },
      {
        title: "Nameless Midnight",
        artists: "",
        position: "B1",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5602",
        audioFeatures: {
          acousticness: 0.0042,
          danceability: 0.781,
          duration_ms: 288000,
          energy: 0.881,
          instrumentalness: 0.94,
          key: 1,
          liveness: 0.111,
          loudness: -11.301,
          mode: 1,
          speechiness: 0.148,
          tempo: 135.013,
          time_signature: 4,
          valence: 0.244,
        },
        spotifyID: "5z9G3Y1Iv3Qgi7CypTOK3P",
      },
      {
        title: "Subtle Flex",
        artists: "",
        position: "B2",
        duration: null,
        rpm: 33,
        genre: "Techno",
        playable: true,
        _id: "5603",
        audioFeatures: {
          acousticness: 0.0661,
          danceability: 0.767,
          duration_ms: 317538,
          energy: 0.963,
          instrumentalness: 0.941,
          key: 11,
          liveness: 0.111,
          loudness: -7.309,
          mode: 1,
          speechiness: 0.0999,
          tempo: 129.99,
          time_signature: 4,
          valence: 0.326,
        },
        spotifyID: "63aWyqIsQD7izRkU9olAP1",
      },
    ],
    createdAt: { $date: { $numberLong: "1671018028016" } },
    user: "1",
  },
  {
    _id: "57",
    discogsID: 5841952,
    catno: "Royal 23",
    title: "Butterflies",
    artists: "Leon Vynehall",
    label: "Royal Oak",
    year: 2014,
    cover:
      "https://i.discogs.com/4KR9NFPDOwKHjMXPCY7rKseOuCZeEfGVGQtQVSoeYSw/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTU4NDE5/NTItMTQxMjI0Mjk4/NC0zNDY2LmpwZWc.jpeg",
    tracks: [
      {
        title: "Butterflies",
        artists: "",
        position: "A1",
        duration: 449000,
        rpm: 45,
        genre: "House, Deep House",
        playable: true,
        _id: "5700",
        audioFeatures: {
          acousticness: 0.00151,
          danceability: 0.693,
          duration_ms: 449266,
          energy: 0.916,
          instrumentalness: 0.762,
          key: 10,
          liveness: 0.0503,
          loudness: -10.356,
          mode: 0,
          speechiness: 0.0818,
          tempo: 120.002,
          time_signature: 4,
          valence: 0.562,
        },
        spotifyID: "5vWy90WX1eyfFUxnKcKSY9",
      },
      {
        title: "This Is The Place",
        artists: "",
        position: "B1",
        duration: 358000,
        rpm: 45,
        genre: "House, Deep House",
        playable: true,
        _id: "5701",
        audioFeatures: {
          acousticness: 0.0241,
          danceability: 0.796,
          duration_ms: 358174,
          energy: 0.642,
          instrumentalness: 0.907,
          key: 1,
          liveness: 0.078,
          loudness: -10.908,
          mode: 1,
          speechiness: 0.172,
          tempo: 124.008,
          time_signature: 4,
          valence: 0.576,
        },
        spotifyID: "6QBmMllJJix2S0yCi7x5b0",
      },
    ],
    createdAt: { $date: { $numberLong: "1671018028016" } },
    user: "1",
  },
]
