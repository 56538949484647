<!-- svg from https://fonts.google.com/icons -->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M4 20v-8h4v8Zm6 0V4h4v16Zm6 0V9h4v11Z" />
  </svg>
</template>

<script setup lang="ts"></script>
