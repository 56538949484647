<!-- svg from https://fonts.google.com/icons -->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path
      d="m14.8 11.975-1.425-1.425L16.95 6H8.825l-2-2H19q.625 0 .9.55t-.1 1.05ZM19.775 22.6 14 16.825V19q0 .425-.287.712Q13.425 20 13 20h-2q-.425 0-.712-.288Q10 19.425 10 19v-6.175l-8.6-8.6L2.8 2.8l18.4 18.4Zm-6.4-12.05Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>
