<!-- svg from https://fonts.google.com/icons -->
<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 301.532 301.532"
    fill="currentColor"
  >
    <g>
      <g>
        <g>
          <path
            d="M80.214,185.236c-4.98-4.978-13.052-4.979-18.031-0.001c-4.979,4.979-4.979,13.053-0.001,18.031l36.061,36.062
				c2.491,2.49,5.753,3.735,9.016,3.735c3.262,0,6.526-1.245,9.015-3.734c4.979-4.979,4.979-13.052,0.001-18.031L80.214,185.236z"
          />
          <path
            d="M297.775,63.839l-12.01-12.011c-0.003-0.003-0.006-0.006-0.008-0.009l-36.062-36.063
				c-0.003-0.003-0.008-0.007-0.013-0.011l-12.009-12.01c-4.979-4.978-13.052-4.979-18.031-0.001
				c-4.979,4.979-4.979,13.053,0,18.031l3.006,3.006l-18.032,18.031l-3.006-3.006c-4.978-4.979-13.052-4.979-18.031,0
				s-4.979,13.052,0,18.031l3.006,3.006l-34.257,34.257c-2.985-0.425-6.001-0.638-9.024-0.638c-17.024,0-33.026,6.627-45.059,18.66
				c-3.046,3.046-5.758,6.367-8.113,9.931c-24.211-0.239-46.945,9.064-64.012,26.131c-34.797,34.8-34.797,91.422,0,126.218
				c16.856,16.855,39.267,26.139,63.108,26.141c0.003,0,0.005,0,0.008,0c23.835,0,46.245-9.284,63.102-26.141
				c17.118-17.118,26.402-40.074,26.137-64.014c3.562-2.354,6.882-5.066,9.926-8.111c14.225-14.226,20.803-34.392,18.018-54.081
				l34.259-34.259l3.005,3.005c2.49,2.49,5.752,3.734,9.015,3.734c3.263,0,6.526-1.244,9.016-3.734
				c4.979-4.979,4.979-13.052,0-18.031l-3.005-3.005l18.031-18.03l3.005,3.005c2.49,2.49,5.752,3.735,9.016,3.735
				c3.262,0,6.526-1.245,9.015-3.734C302.755,76.891,302.755,68.818,297.775,63.839z M170.367,185.236
				c-3.084,3.085-6.637,5.598-10.56,7.471c-4.874,2.326-7.753,7.471-7.187,12.843c2.031,19.257-4.644,38.14-18.313,51.809
				c-12.041,12.042-28.046,18.673-45.071,18.672c-0.001,0-0.004,0-0.006,0c-17.031-0.001-33.04-6.633-45.079-18.673
				c-24.856-24.855-24.856-65.299,0-90.155c12.038-12.038,28.046-18.666,45.075-18.666c2.241,0,4.507,0.119,6.735,0.354
				c5.372,0.568,10.516-2.313,12.843-7.187c1.873-3.924,4.388-7.477,7.472-10.562c7.217-7.216,16.816-11.191,27.028-11.191
				c2.306,0,4.602,0.222,6.864,0.632l30.761,30.762C183.142,163.613,179.246,176.356,170.367,185.236z M192.594,126.948
				l-18.031-18.032l30.052-30.051l18.031,18.031L192.594,126.948z M240.678,78.865l-18.031-18.031l18.031-18.031l18.031,18.031
				L240.678,78.865z"
          />
          <circle cx="131.298" cy="170.213" r="17" />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script setup lang="ts"></script>
